import React, { CSSProperties, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { Table } from 'controls/table';
import { BannerDto } from 'services/data-contracts';
import { BannerHeaders } from '../settings/definition';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PaginationComponent from 'components/commons/PaginationComponent';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

const ListPage = observer(() => {
  const navigate = useNavigate();
  const { bannerStore } = useStore();
  const [searchParams] = useSearchParams();
  const pageNo = useRef<number>(0);
  const bannerHeaders: BannerHeaders = new BannerHeaders();

  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    navigate(`/banner?page=${pageNumber - 1}`);
  };

  const init = async () => {
    pageNo.current = searchParams.get('page') === null ? 0 : Number(searchParams.get('page'));
    if (Number.isNaN(pageNo.current)) {
      pageNo.current = 0;
    }
    await bannerStore.getAllBanners({ page: pageNo.current });
  };

  useEffect(() => {
    bannerStore.getBannerRollingSpeed();
    bannerStore.getActiveBanners();
  }, []);

  useEffect(() => {
    init();
  }, [searchParams]);

  return (
    <>
      <HeaderTitleStyle title="번영회 배너" />
      <ContainerStyle>
        <TextStyle>
          앱반영
          <div>
            <BtnStyle color="#999999" backgroundColor="white" border>
              롤링속도 {bannerStore.rollingSpeed}sec
            </BtnStyle>
            <BtnStyle>앱반영</BtnStyle>
          </div>
        </TextStyle>
        <Table<BannerDto>
          items={bannerStore.activeBanner}
          headers={bannerHeaders.getAppBannerHeaders()}
          tableStyle={tableStyle}
        />
        <TextStyle>
          전체목록
          <div>
            <BtnStyle
              onClick={() => {
                navigate('/banner/create');
              }}
            >
              등록하기
            </BtnStyle>
            <BtnStyle>삭제하기</BtnStyle>
          </div>
        </TextStyle>
        <Table<BannerDto>
          items={bannerStore.allBanner}
          headers={bannerHeaders.getAllBannerHeaders()}
          tableStyle={tableStyle}
        />
        {bannerStore.pagination?.totalPages > 0 && (
          <PaginationComponent
            pagination={bannerStore.pagination}
            handleClickPageNumber={handleClickPageNumber}
          />
        )}
      </ContainerStyle>
    </>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  fontSize: 14,
  marginBottom: 20,
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

const TextStyle = styled.div`
  color: #144da8;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  > div {
    display: flex;
  }
`;

const BtnStyle = styled.div<{ color?: string; backgroundColor?: string; border?: boolean }>`
  cursor: pointer;
  border-radius: 25px;
  padding: 10px;
  margin: 0 5px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.color || 'white'};
  background-color: ${(props) => props.backgroundColor || '#5d687a'};
  display: flex;
  align-items: center;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.color};
    `};
  > div {
    margin-left: 10px;
    color: black;
    font-weight: 600;
  }
`;

export default ListPage;
