import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { Input } from 'components/commons';
import { useStore } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import BlueBtn from 'features/mainFeatures/auth/components/BlueBtn';

const AdminLoginPage = observer(() => {
  const { t } = useTranslation();
  const { adminStore } = useStore();

  const [loginData, setLoginData] = useState<any>();
  const [userNameErrorMessage, setUserNameErrorMessage] = useState<string>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');

  const handleClickIdLogin = async () => {
    await adminStore.login(loginData.email, loginData.password);
    if (adminStore.result === true) {
      window.location.href = '/';
    } else {
      toast(adminStore.loginError.data.errorMessage, { type: 'error' });
    }
  };

  const handleChangeLoginInfo = (key: 'email' | 'phoneNumber' | 'password', inputValue: string) => {
    setLoginData((prevState: any) => ({ ...prevState, [key]: inputValue }));
  };

  const checkValidationUsername = () => {
    if (typeof loginData?.email === 'undefined') {
      return;
    }
    const userNameExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const validationCheck = userNameExp.test(loginData?.email);
    if (validationCheck === true) {
      setUserNameErrorMessage('');
    } else {
      setUserNameErrorMessage(t('notFormatEmail'));
    }
  };

  const checkValidationPassword = () => {
    if (typeof loginData?.password === 'undefined') {
      return;
    }
    const passwordExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    const validationCheck = passwordExp.test(loginData?.password);
    if (validationCheck === true) {
      setPasswordErrorMessage('');
    } else {
      setPasswordErrorMessage(t('mustLeastCharacterEnNumSpecial'));
    }
  };

  useEffect(() => {
    localStorage.removeItem('me');
  }, []);

  useEffect(() => {
    checkValidationUsername();
    checkValidationPassword();
  }, [loginData?.email, loginData?.password]);

  return (
    <>
      <LoginContainer>
        <img src="/images/logo.svg" alt="" width={180} />
        <InputContainerStyle>
          <Input
            // required
            minLength={4}
            maxLength={25}
            customValidation={{
              expression: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: userNameErrorMessage,
            }}
            id="idInput"
            placeholder={t('email')}
            type="text"
            style={inputStyle}
            containerStyle={containerStyle}
            boxStyle={{ height: 85 }}
            messageLocation="bottom"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClickIdLogin();
              }
            }}
            onChangeValue={(value: string) => {
              handleChangeLoginInfo('email', value);
              if (value === '') {
                setUserNameErrorMessage('이메일을 입력해주세요');
              } else {
                setUserNameErrorMessage('');
              }
            }}
          />
          <Input
            // required
            minLength={4}
            maxLength={25}
            customValidation={{
              expression: /^([A-Za-z]|[0-9]|_)+$/,
              message: passwordErrorMessage,
            }}
            id="passwordInput"
            placeholder={t('password')}
            type="password"
            style={inputStyle}
            containerStyle={containerStyle}
            boxStyle={{ height: 85 }}
            messageLocation="bottom"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClickIdLogin();
              }
            }}
            onChangeValue={(value: string) => {
              handleChangeLoginInfo('password', value);
              if (value === '') {
                setPasswordErrorMessage(t('enterYourPassword'));
              }
            }}
          />
        </InputContainerStyle>
        <LoginBtnStyle onClick={handleClickIdLogin}>{t('logIn')}</LoginBtnStyle>
      </LoginContainer>
    </>
  );
});

const flexCenter = css`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const LoginContainer = styled.div`
  max-width: 500px;
  margin: auto;
  height: calc(100vh - 80px);
  ${flexCenter};
  flex-direction: column;
`;

const InputContainerStyle = styled.div`
  margin-top: 40px;
  width: calc(100% - 40px);
  > div > div {
    border-radius: 25px !important;
  }
`;

const LoginBtnStyle = styled(BlueBtn)<{ validState?: any }>`
  height: 50px;
  margin: 0;
  width: 110px;
  border-radius: 25px;
  background-color: #144da8;
`;

const containerStyle: React.CSSProperties = {
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
  borderRadius: 10,
  width: '100%',
  height: 50,
  overflow: 'hidden',
};

const inputStyle: React.CSSProperties = {
  fontSize: '16px',
  WebkitBoxShadow: '0 0 0 1000px white inset',
  boxShadow: '0 0 0 1000px white inset',
  height: '35px',
};
export default AdminLoginPage;
