import React, { CSSProperties, useState } from 'react';
import { DetailPage } from '../pages';
import { COLUMN_TYPES } from 'controls/table/ColumnTypes';

const thStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  color: '#8B8F9A',
  fontWeight: 500,
  textAlign: 'center',
};

const tdStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  textAlign: 'center',
  maxWidth: 400,
};

const textStyle: CSSProperties = {
  color: '#144da8',
  cursor: 'pointer',
  textDecoration: 'underline',
  textUnderlinePosition: 'under',
};

const StatusConvertor = (props: any) => {
  return (
    <>
      {props.item.isStatus ? (
        <>
          <div style={{ color: '#144da8', fontWeight: 700 }}>ON</div>
        </>
      ) : (
        <>
          <div style={{ color: '#dadada', fontWeight: 700 }}>OFF</div>
        </>
      )}
    </>
  );
};

const TypeConvertor = (props: any) => {
  return (
    <>
      {props.item.bannerType === 'ADVERTISEMENT' && <div>광고</div>}
      {props.item.bannerType === 'EVENT' && <div>이벤트</div>}
      {props.item.bannerType === 'PROMOTION' && <div>프로모션</div>}
      {props.item.bannerType === 'NEWS' && <div>뉴스</div>}
      {props.item.bannerType === 'NOTICE ' && <div>공지</div>}
    </>
  );
};

const DetailContainer = (props: any) => {
  return (
    <>
      <DetailPage id={props.item.id} />
    </>
  );
};

class BannerHeaders {
  getAppBannerHeaders() {
    return [
      {
        title: '이름',
        key: 'name',
        style: { ...thStyle, width: 100 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '종류',
        key: '',
        type: COLUMN_TYPES.CUSTOM,
        template: <TypeConvertor />,
        style: { ...thStyle, width: 60 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '순서',
        type: COLUMN_TYPES.LINK,
        options: { linkUrl: '/', linkStyle: { color: '#144da8', textUnderlinePosition: 'under' } },
        key: '',
        style: { ...thStyle, width: 50 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '시작일',
        key: 'startDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '종료일',
        key: 'endDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      { title: '업로드', key: '', style: { ...thStyle }, contentStyle: { ...tdStyle } },
      {
        title: '상태',
        type: COLUMN_TYPES.CUSTOM,
        template: <StatusConvertor />,
        style: { ...thStyle, width: 50 },
        contentStyle: { ...tdStyle },
      },
    ];
  }

  getAllBannerHeaders() {
    return [
      {
        title: '이름',
        key: 'name',
        style: { ...thStyle, width: 100 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '종류',
        key: '',
        type: COLUMN_TYPES.CUSTOM,
        template: <TypeConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '상세정보',
        label: '보기',
        type: COLUMN_TYPES.CUSTOM,
        template: <DetailContainer />,
        style: { ...thStyle, width: 70 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '시작일',
        key: 'startDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '종료일',
        key: 'endDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '업로드',
        key: 'file.fileName',
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '상태',
        type: COLUMN_TYPES.CUSTOM,
        template: <StatusConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '등록일',
        key: 'createDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '수정일',
        key: 'modifyDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
    ];
  }
}

export { BannerHeaders };
