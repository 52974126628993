import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

type Props = {
  title: string;
  style?: CSSProperties;
};

const HeaderTitleStyle = observer(({ title, style }: Props) => {
  return (
    <>
      <TitleStyle style={style}>{title}</TitleStyle>
    </>
  );
});

const TitleStyle = styled.div<{ style?: any }>`
  color: #535b6f;
  font-size: 30px;
  margin: 40px 60px;
  font-weight: 500;
  ${(props) => props.style}
`;

export default HeaderTitleStyle;
