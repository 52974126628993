import { makeAutoObservable } from 'mobx';
import { ParameterStoreInterface } from 'types/ParameterTypes';
import _ from 'lodash';

class ParameterStore {
  constructor() {
    makeAutoObservable(this);
  }

  parameters: ParameterStoreInterface = {};

  autoPush = true;

  /**
   * @description 파라미터 변경 또는 추가 (1개)
   * @param key
   * @param value
   */
  addParam = (key: any, value: any) => {
    delete this.parameters[key];
    this.parameters = { ...this.parameters, [key]: value };
  };

  /**
   * @description 파라미터 변경 또는 추가 (여러 개)
   * @param params
   */
  addParams = (params: { key: any; value: any }[]) => {
    params.forEach((item: { key: any; value: any }) => {
      this.parameters[item.key] = item.value;
    });
    this.parameters = { ...this.parameters };
  };

  /**
   * @description 객체로 받은
   * @param params
   */
  updateParams = (params: any) => {
    const arr: any[] = [];
    for (let key in params) {
      arr.push({ key: key, value: params[key] });
      this.parameters = { ...this.parameters, [key]: params[key] };
    }
  };

  /**
   * @description 파라미터 삭제({})
   * @param key
   */
  removeParam = (key: any) => {
    let params = { ...this.parameters };
    delete params[key];

    this.parameters = params;
  };

  /**
   * @description 파마리터 삭제([])
   * @param params
   */
  removeParams = (params: string[]) => {
    let parameters = { ...this.parameters };
    params.forEach((item: string) => {
      delete parameters[item];
    });

    this.parameters = { ...parameters };
  };

  setParams = (params: any) => {
    this.parameters = params;
  };

  init = () => {
    this.parameters = {};
  };

  unMount = () => {
    this.parameters = {};
  };
}

export default ParameterStore;
