import React, { CSSProperties, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { QnaDto } from 'adminServices/data-contracts';
import { Row } from 'components/commons/layouts';
import { Input } from 'components/commons';
import { toast } from 'react-toastify';
import { Modal } from '@mui/material';
import styled from 'styled-components';
import moment from 'moment';
import Editor from 'components/commons/webEditor/Editor';
import CloseIcon from '@mui/icons-material/Close';

const QnaAnswerPage = observer(() => {
  const { qnaStore } = useStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const [model, setModel] = useState<QnaDto>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalImg, setModalImg] = useState<string>('');

  const handleClickAnswer = async () => {
    if (typeof id !== 'undefined' && typeof model?.answer !== 'undefined') {
      await qnaStore.updateQna(Number(id), { ...model, answer: model.answer });
      if (qnaStore.updateStatus === true) {
        toast('답변 등록이 완료되었습니다.', { type: 'success' });
        setTimeout(() => {
          navigate('/qna');
        }, 300);
      }
    }
  };

  const init = async () => {
    if (typeof id !== 'undefined') {
      await qnaStore.getQna(Number(id));
      setModel(qnaStore.qnaDetail);
    }
  };

  useEffect(() => {
    init();
  }, [id]);

  return (
    <>
      <Modal
        open={openModal}
        disableRestoreFocus={false}
        disableEnforceFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContentStyle>
          <div>
            <CloseIcon
              onClick={() => {
                setOpenModal(false);
              }}
              style={closeBtnStyle}
            />
            <ImgStyle src={modalImg} maxWidth="800px" maxHeight="800px" />
          </div>
        </ModalContentStyle>
      </Modal>
      <ContainerStyle>
        <>
          <InputContainerStyle>
            <Input
              readOnly
              style={inputStyle}
              autoFocus
              containerStyle={inputContainerStyle}
              id="title"
              label="제목"
              defaultValue={model?.title}
              onChangeValue={(value: string) => {
                setModel({ ...model, title: value });
              }}
            />
            <Input
              readOnly
              style={inputStyle}
              label="카테고리"
              defaultValue={model?.categoryType}
              containerStyle={inputContainerStyle}
            />
          </InputContainerStyle>
          <InputContainerStyle>
            <Input
              readOnly
              style={inputStyle}
              label="등록일"
              defaultValue={moment(model?.answerCreateDate).format('YYYY.MM.DD HH:mm')}
              containerStyle={inputContainerStyle}
            />
            <Input
              readOnly
              style={inputStyle}
              label="첨부사진"
              containerStyle={inputContainerStyle}
            />
          </InputContainerStyle>
          <TitleStyle>문의내용</TitleStyle>
          <div>
            {model?.files?.length !== 0 &&
              model?.files?.map((item) => (
                <ImgStyle
                  src={item?.publicUrl}
                  onClick={() => {
                    setOpenModal(true);
                    if (typeof item?.publicUrl !== 'undefined') {
                      setModalImg(item.publicUrl);
                    }
                  }}
                />
              ))}
          </div>
          <TextAreaStyle readOnly defaultValue={model?.question} />
          <TitleStyle>답변</TitleStyle>
          <Editor
            style={{ height: 500 }}
            defaultValue={model?.answer}
            onEditorChange={(value: string) => {
              setModel({ ...model, answer: value });
            }}
          />
        </>
        <Row style={{ position: 'relative', bottom: -50 }}>
          <BtnStyle
            onClick={() => {
              navigate('/qna');
            }}
          >
            취소
          </BtnStyle>
          <BtnStyle backgroundColor="#144da8" onClick={handleClickAnswer}>
            등록
          </BtnStyle>
        </Row>
      </ContainerStyle>
    </>
  );
});

const inputStyle: CSSProperties = {
  height: 40,
  width: '100%',
  fontSize: 14,
  cursor: 'pointer',
};

const grayBorderStyle = {
  border: '1px solid #dadada',
  borderRadius: 5,
};

const inputContainerStyle: CSSProperties = {
  ...grayBorderStyle,
  height: 'auto',
  margin: '0 10px',
};

const closeBtnStyle: CSSProperties = {
  cursor: 'pointer',
  fill: '#ffffff',
};

const ModalContentStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
  }
`;

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  width: 90%;
`;

const ImgStyle = styled.img.attrs({ alt: '' })<{ maxWidth?: string; maxHeight?: string }>`
  max-width: ${(props) => props.maxWidth || '200px'};
  max-height: ${(props) => props.maxHeight || '200px'};
  padding: 10px;
  cursor: pointer;
`;

const InputContainerStyle = styled.div`
  display: flex;
`;

const BtnStyle = styled.div<{ backgroundColor?: string }>`
  cursor: pointer;
  background: ${(props) => props.backgroundColor || '#4B4B4B'};
  border-radius: 25px;
  padding: 10px 30px;
  color: white;
  margin: 0 20px;
`;

const TitleStyle = styled.div`
  font-size: 14px;
  margin: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  > div {
    width: 90%;
    height: 1px;
    background-color: #dadada;
  }
  &::after {
    content: '';
    width: 90%;
    height: 1px;
    background-color: #dadada;
    margin-left: 10px;
  }
`;

const TextAreaStyle = styled.textarea`
  padding: 10px;
  height: 200px;
  margin: 0 10px;
  resize: none;
  ${grayBorderStyle};
  border-color: #f3f3f3;
  background-color: #f3f3f3;
`;

export default QnaAnswerPage;
