import React, { useEffect, CSSProperties, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { PostDto } from 'services/data-contracts';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Table } from 'controls/table';
import { PostHeaders } from 'features/communityManagement/settings/definitions';
import PaginationComponent from 'components/commons/PaginationComponent';
import styled from 'styled-components';

const PostList = observer(() => {
  const { communityid } = useParams();
  const pageNo = useRef<number>(0);
  const postHeaders: PostHeaders = new PostHeaders(Number(communityid));

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { communityStore } = useStore();

  const init = async () => {
    pageNo.current = searchParams.get('page') === null ? 0 : Number(searchParams.get('page'));
    if (Number.isNaN(pageNo.current)) {
      pageNo.current = 0;
    }
    if (typeof communityid !== 'undefined') {
      await communityStore.getAllPosts(Number(communityid));
      await communityStore.getPosts(Number(communityid), {
        page: pageNo.current,
      });
    }
  };

  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    navigate(`/communities/${communityid}/posts?page=${pageNumber - 1}`);
  };

  useEffect(() => {
    init();

    return () => {
      communityStore.clearPosts();
    };
  }, [searchParams, communityid]);

  return (
    <>
      <>
        <TextStyle>게시글 {communityStore.allPosts?.length}건, ★상단고정</TextStyle>
        <Table<PostDto>
          headers={postHeaders?.getPostHeaders()}
          items={communityStore.posts}
          tableStyle={tableStyle}
        />
      </>
      {communityStore.postPagination?.totalPages > 0 && (
        <PaginationComponent
          pagination={communityStore.postPagination}
          handleClickPageNumber={handleClickPageNumber}
        />
      )}
    </>
  );
});

const tableStyle: CSSProperties = {
  textAlign: 'center',
  border: '1px solid #ededed',
  borderRadius: 5,
  borderCollapse: 'collapse',
  backgroundColor: 'white',
  marginBottom: 20,
};

const TextStyle = styled.div`
  color: #666666;
  padding: 10px 0;
`;

export default PostList;
