import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FormControl, MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from './Chart';

type Props = {
  type: 'year' | 'month';
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

ChartJS.defaults.set('plugins.datalabels', {
  display: true,
  color: 'black',
  anchor: 'end',
  borderWidth: 2,
});

const ChartContainer = observer(({ type }: Props) => {
  const { userStatisticsStore } = useStore();

  const [labels, setLabels] = useState<Array<string>>([]);
  const [selectMonth, setSelectMonth] = useState<any>('');
  const [selectMonthYear, setSelectMonthYear] = useState<any>('');
  const [selectYear, setSelectYear] = useState<any>('');
  const [monthKey, setMonthKey] = useState<any>();
  const [monthValue, setMonthValue] = useState<any>();
  const [yearKey, setYearKey] = useState<any>();
  const [yearValue, setYearValue] = useState<any>();
  const [option, setOption] = useState<any>();

  const backgroundColor = useRef<any>();
  const borderColor = useRef<any>();
  const yearSelect = useRef<Array<number>>([]);

  const today = new Date();

  let year = today.getFullYear();
  let month = today.getMonth() + 1;

  const monthSelectOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const handleChangeSelect = (selectType: 'year' | 'month', value: any) => {
    if (type === 'month') {
      if (selectType === 'month') {
        if (value.length === 1) {
          setSelectMonth('0' + value);
        } else {
          setSelectMonth(parseInt(value));
        }
      } else {
        setSelectMonthYear(value);
      }
    } else {
      setSelectYear(value);
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: '가입자',
        data: type === 'month' ? monthValue : yearValue,
        backgroundColor: backgroundColor.current,
        borderColor: borderColor.current,
      },
    ],
  };

  const getYearCount = async () => {
    await userStatisticsStore.getUserYear({ year: selectYear });
  };

  const getMonthCount = async () => {
    await userStatisticsStore.getUserMonthly({ yearMonth: selectMonthYear + '-' + selectMonth });
    if (userStatisticsStore.monthlyError.status === 400) {
      toast(userStatisticsStore.monthlyError.data.errorMessage, { type: 'error' });
    }
  };

  const getYearSelectOption = () => {
    for (let i = 2021; i < year + 1; i++) {
      if (typeof yearSelect.current !== 'undefined') {
        yearSelect?.current.push(i);
      }
    }
  };

  useEffect(() => {
    getYearSelectOption();
  }, [year]);

  useEffect(() => {
    setOption({
      responsive: true,
      scales: {
        y: {
          min: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        labels: labels,
        tooltip: {
          enabled: false,
        },
      },
    });
  }, [labels, selectMonth, selectYear]);

  useEffect(() => {
    if (type === 'month') {
      const monthLabel = monthKey?.map((item: any) => item.slice(-2));
      setLabels(monthLabel?.map((item: any) => item.replace(/(^0+)/, '') + '일'));
    } else {
      const yearLabel = yearKey?.map((item: any) => item.slice(-2));
      setLabels(yearLabel?.map((item: any) => item.replace(/(^0+)/, '') + '월'));
    }
  }, [monthKey, yearKey]);

  useEffect(() => {
    if (
      typeof userStatisticsStore.yearUser !== 'undefined' &&
      userStatisticsStore.yearUser !== null
    ) {
      setYearKey(Object.keys(userStatisticsStore.yearUser));
      setYearValue(Object.values(userStatisticsStore.yearUser));
    }
  }, [selectYear, userStatisticsStore.yearUser]);

  useEffect(() => {
    if (
      typeof userStatisticsStore.monthlyUser !== 'undefined' &&
      userStatisticsStore.monthlyUser !== null
    ) {
      setMonthKey(Object.keys(userStatisticsStore.monthlyUser));
      setMonthValue(Object.values(userStatisticsStore.monthlyUser));
    }
  }, [selectMonth, userStatisticsStore.monthlyUser]);

  useEffect(() => {
    setSelectMonthYear(year);
    setSelectMonth(month);
    setSelectYear(year);
    if (type === 'month') {
      backgroundColor.current = 'rgba(255, 99, 132, 0.5)';
      borderColor.current = 'rgb(255, 99, 132)';
    } else {
      backgroundColor.current = 'rgba(75, 192, 192, 0.5)';
      borderColor.current = 'rgb(75, 192, 192)';
    }
  }, [type]);

  useEffect(() => {
    getMonthCount();
  }, [selectMonth, selectMonthYear]);

  useEffect(() => {
    getYearCount();
  }, [selectYear]);

  return (
    <>
      <ChartContainerStyle>
        <ChartHeaderStyle>
          <div>{type === 'year' ? <>년 가입자수</> : <>월 가입자수</>}</div>
          <SelectContainerStyle>
            <FormControl>
              <InputLableStyle></InputLableStyle>
              <Select
                style={{ ...selectStyle }}
                sx={{
                  height: 40,
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  handleChangeSelect('year', e.target.value);
                }}
                defaultValue={year}
              >
                {yearSelect.current.map((item) => (
                  <MenuItem value={item}>{item}년</MenuItem>
                ))}
              </Select>
            </FormControl>
            {type === 'month' && (
              <>
                <FormControl>
                  <InputLableStyle></InputLableStyle>
                  <Select
                    defaultValue={month}
                    style={{ ...selectStyle, marginLeft: 5 }}
                    sx={{
                      height: 40,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e: any) => {
                      handleChangeSelect('month', e.target.value);
                    }}
                  >
                    {monthSelectOptions.map((item) => (
                      <MenuItem value={item}>{item}월</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </SelectContainerStyle>
        </ChartHeaderStyle>
        <Chart type={type} chartData={data} chartOption={option} />
      </ChartContainerStyle>
    </>
  );
});

const selectStyle: CSSProperties = {
  width: 100,
};

const ChartContainerStyle = styled.div`
  width: 50%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  margin: 0 10px;
  padding: 20px;
  &:first-child {
    margin-left: 0;
  }
`;

const ChartHeaderStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InputLableStyle = styled.div`
  > label {
    top: -8px;
  }
`;

const SelectContainerStyle = styled.div`
  display: flex;
`;

export default ChartContainer;
