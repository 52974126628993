/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IdDto,
  PropertyCategoryDto,
  PropertyDto,
  RegionCountDto,
  ReportDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Property<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 매물 상세 조회
   *
   * @tags property
   * @name GetProperty
   * @summary 매물 상세 조회
   * @request GET:/v1/market/properties/{propertyId}
   * @secure
   */
  getProperty = (propertyId: number, params: RequestParams = {}) =>
    this.request<PropertyDto, any>({
      path: `/v1/market/properties/${propertyId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 매물 수정
   *
   * @tags property
   * @name UpdateProperty
   * @summary 매물 수정
   * @request PUT:/v1/market/properties/{propertyId}
   * @secure
   */
  updateProperty = (propertyId: number, data: PropertyDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/properties/${propertyId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 매물 삭제
   *
   * @tags property
   * @name DeleteProperty
   * @summary 매물 삭제
   * @request DELETE:/v1/market/properties/{propertyId}
   * @secure
   */
  deleteProperty = (propertyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/properties/${propertyId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 매물 리스트 조회
   *
   * @tags property
   * @name GetProperties
   * @summary 매물 리스트 조회
   * @request GET:/v1/market/properties
   * @secure
   */
  getProperties = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      isMine?: boolean;
      latitude?: number;
      longitude?: number;
      status?: ('ON_SALE' | 'SOLD')[];
      regionCode?: string;
      radius?: number;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PropertyDto[], any>({
      path: `/v1/market/properties`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 매물 등록
   *
   * @tags property
   * @name AddProperty
   * @summary 매물 등록
   * @request POST:/v1/market/properties
   * @secure
   */
  addProperty = (data: PropertyDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/properties`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 매물 거래완료
   *
   * @tags property
   * @name SoldProperty
   * @summary 매물 거래완료
   * @request POST:/v1/market/properties/{propertyId}/sold
   * @secure
   */
  soldProperty = (propertyId: number, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/properties/${propertyId}/sold`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 매물 신고
   *
   * @tags property
   * @name ReportProperty
   * @summary 매물 신고
   * @request POST:/v1/market/properties/{propertyId}/report
   * @secure
   */
  reportProperty = (propertyId: number, data: ReportDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/properties/${propertyId}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 관심 매물 등록
   *
   * @tags property
   * @name AddInterestProperty
   * @summary 관심 매물 등록
   * @request POST:/v1/market/properties/{propertyId}/interest
   * @secure
   */
  addInterestProperty = (propertyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/properties/${propertyId}/interest`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 관심 매물 해제
   *
   * @tags property
   * @name DeleteInterestProperty
   * @summary 관심 매물 해제
   * @request DELETE:/v1/market/properties/{propertyId}/interest
   * @secure
   */
  deleteInterestProperty = (propertyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/properties/${propertyId}/interest`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 매물 종류 리스트 조회
   *
   * @tags property
   * @name GetPropertyCategories
   * @summary 매물 종류 리스트 조회
   * @request GET:/v1/market/property-categories
   * @secure
   */
  getPropertyCategories = (params: RequestParams = {}) =>
    this.request<PropertyCategoryDto[], any>({
      path: `/v1/market/property-categories`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 관심 매물 리스트 조회
   *
   * @tags property
   * @name GetInterestProperties
   * @summary 관심 매물 리스트 조회
   * @request GET:/v1/market/properties/interested
   * @secure
   */
  getInterestProperties = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PropertyDto[], any>({
      path: `/v1/market/properties/interested`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 지역기반 매물 검색
   *
   * @tags property
   * @name GetPropertiesByMap
   * @summary 지역기반 매물 검색
   * @request GET:/v1/market/map/properties
   * @secure
   */
  getPropertiesByMap = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      isMine?: boolean;
      latitude?: number;
      longitude?: number;
      status?: ('ON_SALE' | 'SOLD')[];
      regionCode?: string;
      radius?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<RegionCountDto[], any>({
      path: `/v1/market/map/properties`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
