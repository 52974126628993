import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PostHeaders } from 'features/communityManagement/settings/definitions';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { PostDto } from 'adminServices/data-contracts';
import { Modal } from '@mui/material';
import styled from 'styled-components';

type Props = {
  // handleSelectPostId: (id: string) => void;
  // handleModal: (open: boolean) => void;
  id: number;
};

const PostDetailContainer = observer(({ id }: Props) => {
  const navigate = useNavigate();
  const { communityPostStore } = useStore();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [item, setItem] = useState<PostDto>();

  const getPost = async () => {
    // await communityPostStore.getItem(id);
    // setItem(communityPostStore.post);
  };

  return (
    <>
      <Modal open={openModal}>
        <ModalContainerStyle>
          <HeaderStyle>게시글 정보</HeaderStyle>
          <div>
            제목
            <div>{item?.title}</div>
          </div>
          <div>
            작성자
            <div>{item?.creator?.name}</div>
          </div>
          <div>
            내용
            <div>{item?.contents}</div>
          </div>
          <BtnContainerStyle>
            <BtnStyle
              backgroundColor="#4B4B4B"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              닫기
            </BtnStyle>
            <BtnStyle
              onClick={() => {
                if (typeof communityPostStore?.post?.id !== 'undefined') {
                  // handleClickSelect((communityPostStore?.post?.id).toString());
                }
                setOpenModal(false);
              }}
            >
              선택
            </BtnStyle>
          </BtnContainerStyle>
        </ModalContainerStyle>
      </Modal>
      <TextStyle
        onClick={() => {
          setOpenModal(true);
        }}
      >
        보기
      </TextStyle>
    </>
  );
});

const HeaderStyle = styled.div`
  background-color: #144da8;
  padding: 15px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > img {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
`;

const BtnContainerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BtnStyle = styled.div<{ backgroundColor?: string }>`
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#144da8'};
  padding: 10px 15px;
  width: 60px;
  margin: 20px 10px;
  border-radius: 25px;
  color: white;
  text-align: center;
`;

const ModalContainerStyle = styled.div`
  background-color: white;
  outline: none;
  max-width: 1000px;
  margin: 10%;
  margin: 10% auto;
`;

const TextStyle = styled.div`
  color: #144da8;
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
  text-align: center;
`;

export default PostDetailContainer;
