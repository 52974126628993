/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommunityBoardDto,
  CommunityDto,
  CommunityMemberDto,
  IdDto,
  PostDto,
  ValidationDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Community<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 커뮤니티 리스트 조회
   *
   * @tags community
   * @name GetCommunities
   * @summary 커뮤니티 리스트 조회
   * @request GET:/v1/communities
   * @secure
   */
  getCommunities = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      regionCode?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<CommunityDto[], any>({
      path: `/v1/communities`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 생성
   *
   * @tags community
   * @name CreateCommunity
   * @summary 커뮤니티 생성
   * @request POST:/v1/communities
   * @secure
   */
  createCommunity = (data: CommunityDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/communities`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 게시판 리스트 조회
   *
   * @tags community
   * @name GetCommunityBoards
   * @summary 커뮤니티 게시판 리스트 조회
   * @request GET:/v1/communities/{communityId}/boards
   * @secure
   */
  getCommunityBoards = (communityId: number, params: RequestParams = {}) =>
    this.request<CommunityBoardDto[], any>({
      path: `/v1/communities/${communityId}/boards`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 조회
   *
   * @tags community
   * @name GetCommunity
   * @summary 커뮤니티 조회
   * @request GET:/v1/communities/{communityId}
   * @secure
   */
  getCommunity = (communityId: number, query?: { key?: string }, params: RequestParams = {}) =>
    this.request<CommunityDto, any>({
      path: `/v1/communities/${communityId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 게시글 리스트 조회
   *
   * @tags community
   * @name GetCommunityPosts
   * @summary 커뮤니티 게시글 리스트 조회
   * @request GET:/v1/communities/{communityId}/posts
   * @secure
   */
  getCommunityPosts = (
    communityId: number,
    query?: {
      'board.id'?: number;
      searchKeyword?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/communities/${communityId}/posts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 멤버 리스트 조회
   *
   * @tags community
   * @name GetCommunityMembers
   * @summary 커뮤니티 멤버 리스트 조회
   * @request GET:/v1/communities/{communityId}/members
   * @secure
   */
  getCommunityMembers = (
    communityId: number,
    query?: {
      'user.name'?: string;
      isAdmin?: boolean;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<CommunityMemberDto[], any>({
      path: `/v1/communities/${communityId}/members`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 이름 중복체크
   *
   * @tags community
   * @name CheckDuplicateCommunityName
   * @summary 커뮤니티 이름 중복체크
   * @request GET:/v1/check-duplicate-community-name
   * @secure
   */
  checkDuplicateCommunityName = (query: { name: string }, params: RequestParams = {}) =>
    this.request<ValidationDto, any>({
      path: `/v1/check-duplicate-community-name`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
