import React, { CSSProperties, useEffect, useState } from 'react';
import { LinkDto, LinkRequestDto, LinkUpdateDto } from 'adminServices/data-contracts';
import { observer } from 'mobx-react';
import { Input } from 'components/commons';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';
import CategoryListContainer from './CategoryListContainer';

type Props = {
  handleModal: (open: boolean) => void;
  id?: number;
  type: 'modify' | 'create';
};

type CategoryName = {
  main?: string;
  sub?: string;
};

interface UpdateType {
  link: LinkUpdateDto;
  file?: File;
}

const LinkModifyContainer = observer(({ handleModal, id, type }: Props) => {
  const { linkStore } = useStore();
  const [url, setUrl] = useState<string>('');
  const [img, setImg] = useState<File>();
  const [imgUrl, setImgUrl] = useState<any>();
  const [validUrlState, setValidUrlState] = useState<boolean>(false);
  const [link, setLink] = useState<LinkDto>();
  const [model, setModel] = useState<LinkRequestDto>();
  const [modifyLink, setModifyLink] = useState<LinkUpdateDto>();
  const [deleteImg, setDeleteImg] = useState<boolean>(false);
  const [category, setCategory] = useState<CategoryName>();
  const [openCategory, setOpenCategory] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [urlErrorMessage, setUrlErrorMessage] = useState<string>('');

  const handleClickModify = async () => {
    if (typeof modifyLink !== 'undefined' && typeof id !== 'undefined') {
      let updateData: UpdateType = {
        link: modifyLink,
      };
      if (typeof img !== 'undefined') {
        updateData.file = img;
      } else if (deleteImg) {
        if (typeof link?.thumbnailId !== 'undefined') {
          updateData.link = { ...modifyLink, thumbnailId: undefined };
        } else {
          updateData.link = { ...modifyLink };
        }
      } else {
        if (typeof link?.thumbnailId !== 'undefined') {
          updateData.link = { ...modifyLink, thumbnailId: link?.thumbnailId };
        } else {
          updateData.link = { ...modifyLink };
        }
      }
      await linkStore.updateLink(id, { file: updateData?.file, linkUpdateDto: updateData.link });
    }
    if (linkStore.updateLinkResult?.status === 200) {
      toast('수정이 완료되었습니다.', { type: 'success' });
      setTimeout(() => {
        window.location.href = '/link';
      }, 900);
    } else {
      toast(linkStore.updateLinkResult?.data?.errorMessage, { type: 'error' });
    }
  };

  const handleClickCreate = async () => {
    if (typeof model !== 'undefined') {
      if (typeof img !== 'undefined') {
        await linkStore.createLink({ file: img, linkRequestDto: model });
      } else {
        await linkStore.createLink({ linkRequestDto: model });
      }
    } else {
      toast('링크 정보를 입력해주세요.', { type: 'error' });
    }
    if (linkStore.createLinkResult?.status === 200) {
      toast('정상적으로 등록되었습니다.', { type: 'success' });
      setTimeout(() => {
        window.location.href = '/link';
      }, 900);
    } else {
      toast(linkStore.createLinkResult?.data?.errorMessage, { type: 'error' });
    }
  };

  const handleDelete = async () => {
    if (typeof id !== 'undefined') {
      await linkStore.deleteLink(id);
    }
    if (linkStore.deleteLinkResult.status === 200) {
      toast('삭제가 완료되었습니다.', { type: 'success' });
      setTimeout(() => {
        window.location.href = '/link';
      }, 900);
    } else {
      toast(linkStore?.deleteLinkResult?.data?.errorMessage, { type: 'error' });
    }
  };

  const checkvalidUrlState = () => {
    if (typeof url === 'undefined') {
      return;
    }
    const urlExp = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi;
    setValidUrlState(urlExp.test(url));
  };

  const handleCategory = (categoryId: any) => {
    if (type === 'create') {
      setModel({ ...model, ...categoryId });
    } else {
      setModifyLink({ ...modifyLink, ...categoryId });
    }
  };

  const handleCategoryName = (categoryName: any) => {
    setCategory({ main: categoryName?.main, sub: categoryName?.sub });
  };

  const handleCategoryModal = (open: boolean) => {
    setOpenCategory(open);
  };

  const getLinkById = async () => {
    if (typeof id !== 'undefined') {
      await linkStore.getLinkById(id);
      setModifyLink({
        linkUrl: linkStore.linkInfo.linkUrl,
        title: linkStore.linkInfo.title,
        description: linkStore.linkInfo.description,
      });
      setLink(linkStore.linkInfo);
      if (typeof linkStore.linkInfo?.linkUrl !== 'undefined') {
        setUrl(linkStore.linkInfo.linkUrl);
      }
      setCategory({
        main: linkStore.linkInfo?.mainCategory,
        sub: linkStore.linkInfo?.subCategory,
      });
    }
  };

  const handleGetUrl = async (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise(() => {
      reader.onload = () => {
        setImgUrl(reader.result);
      };
    });
  };

  const checkModifyLink = () => {
    if (
      validUrlState &&
      modifyLink?.linkUrl !== '' &&
      modifyLink?.title !== '' &&
      modifyLink?.description !== ''
    ) {
      handleClickModify();
    } else {
      if (modifyLink?.title === '') {
        toast('제목을 입력해주세요.', { type: 'error' });
      } else if (modifyLink?.description === '') {
        toast('링크설명을 입력해주세요.', { type: 'error' });
      } else if (modifyLink?.linkUrl === '') {
        toast('URL을 입력해주세요.', { type: 'error' });
      } else if (!validUrlState) {
        toast('URL 형식을 확인해주세요.', { type: 'error' });
      }
    }
  };

  useEffect(() => {
    checkvalidUrlState();
    if (validUrlState === true) {
      setUrlErrorMessage('');
    } else if ((type === 'modify' && linkStore.linkInfo?.linkUrl === url) || url === '') {
      setUrlErrorMessage('');
    } else {
      setUrlErrorMessage('주소를 확인해주세요.');
    }
  }, [validUrlState, url]);

  useEffect(() => {
    if (type === 'modify') {
      getLinkById();
    }
  }, [type]);

  useEffect(() => {
    setModel({
      title: '',
      description: '',
      linkUrl: '',
    });
  }, []);

  return (
    <HeightStyle>
      <Modal open={openDeleteModal}>
        <DeleteModalCotnainerStyle>
          <ModalTitleStyle>삭제하시겠습니까?</ModalTitleStyle>
          <div style={{ color: '#666666' }}>삭제 후에는 복원할 수 없습니다.</div>
          <div>
            <ModalBtnStyle
              backgroundColor="#F6F6F6"
              color="black"
              onClick={() => {
                setOpenDeleteModal(false);
              }}
            >
              취소
            </ModalBtnStyle>
            <ModalBtnStyle onClick={handleDelete}>삭제</ModalBtnStyle>
          </div>
        </DeleteModalCotnainerStyle>
      </Modal>
      <Modal open={openCategory}>
        <ModalContainerStyle>
          <CategoryListContainer
            handleCategoryModal={handleCategoryModal}
            handleCategory={handleCategory}
            handleCategoryName={handleCategoryName}
            type={type}
            categoryItem={{
              main: linkStore.linkInfo?.mainCategory,
              sub: linkStore.linkInfo?.subCategory,
            }}
          />
        </ModalContainerStyle>
      </Modal>
      <ContainerStyle>
        <RowStyle>
          <InputContainerStyle>
            <div>
              <SpanStyle>*</SpanStyle> 카테고리
            </div>
            <Input
              readOnly
              style={{ ...inputStyle, width: '100%', cursor: 'pointer' }}
              value={
                typeof category !== 'undefined'
                  ? category.main + ' > ' + category.sub
                  : '카테고리 선택'
              }
              onClick={() => {
                setOpenCategory(true);
              }}
            />
          </InputContainerStyle>
        </RowStyle>
        <RowStyle>
          <InputContainerStyle>
            <div>
              <SpanStyle>*</SpanStyle> 제목
            </div>
            <Input
              maxLength={20}
              style={{ ...inputStyle }}
              defaultValue={link?.title}
              placeholder="제목을 입력해주세요"
              onChangeValue={(value: string) => {
                if (typeof model !== 'undefined' && type === 'create') {
                  setModel({ ...model, title: value });
                } else if (type === 'modify') {
                  setModifyLink({ ...modifyLink, title: value });
                }
              }}
            />
          </InputContainerStyle>
        </RowStyle>
        <RowStyle>
          <InputContainerStyle>
            <div>
              <SpanStyle>*</SpanStyle> 링크설명
            </div>
            <textarea
              maxLength={50}
              style={{ ...textAreaStyle }}
              defaultValue={link?.description}
              placeholder="링크설명"
              onChange={(e: any) => {
                if (typeof model !== 'undefined' && type === 'create') {
                  setModel({ ...model, description: e.target.value });
                } else if (type === 'modify') {
                  setModifyLink({ ...modifyLink, description: e.target.value });
                }
              }}
            />
          </InputContainerStyle>
        </RowStyle>
        <RowStyle>
          <InputContainerStyle>
            <div>
              <SpanStyle>*</SpanStyle> URL
            </div>
            <Input
              maxLength={500}
              style={{ ...inputStyle }}
              defaultValue={link?.linkUrl}
              customValidation={{
                expression:
                  /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/,
                message: urlErrorMessage,
              }}
              placeholder="https://"
              onChangeValue={(value: string) => {
                setUrl(value);
                if (typeof model !== 'undefined' && type === 'create') {
                  setModel({ ...model, linkUrl: value });
                } else if (type === 'modify') {
                  setModifyLink({ ...modifyLink, linkUrl: value });
                }
              }}
            />
          </InputContainerStyle>
        </RowStyle>
        <RowStyle style={{ marginTop: 15 }}>
          <UploadInputContainerStyle>
            <div>
              <div>썸네일</div>
              <ThumbnailContainerStyle>
                {!deleteImg && (
                  <>
                    {typeof link?.publicUrl !== 'undefined' && typeof img === 'undefined' ? (
                      <img src={link?.publicUrl} />
                    ) : (
                      <>{typeof img !== 'undefined' && <img src={imgUrl} />}</>
                    )}
                  </>
                )}
                <div>
                  <UploadInputStyle>
                    <Input
                      style={{ ...inputStyle, width: '100%' }}
                      readOnly
                      value={
                        deleteImg
                          ? ''
                          : typeof img !== 'undefined'
                          ? img?.name
                          : typeof link?.fileName !== 'undefined'
                          ? link?.fileName
                          : ''
                      }
                    />
                    {!deleteImg && (
                      <>
                        {typeof link?.publicUrl !== 'undefined' || typeof img !== 'undefined' ? (
                          <img
                            src="/images/close.svg"
                            onClick={() => {
                              setImg(undefined);
                              setDeleteImg(true);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </UploadInputStyle>
                  <div style={{ fontSize: 12, marginTop: 10 }}>
                    파일 사이즈는 최대 200 X 200 입니다.
                  </div>
                </div>
              </ThumbnailContainerStyle>
            </div>
            <InputStyle
              type="file"
              onChange={(e) => {
                if (e.target.files !== null) {
                  setImg(e.target.files[0]);
                  handleGetUrl(e.target.files[0]);
                  setDeleteImg(false);
                }
              }}
            />
            {deleteImg ? (
              <>
                <LabelStyle>찾기</LabelStyle>
              </>
            ) : (
              <>
                {typeof link?.publicUrl !== 'undefined' || typeof img !== 'undefined' ? (
                  <LabelStyle
                    onClick={() => {
                      setDeleteImg(false);
                    }}
                  >
                    수정
                  </LabelStyle>
                ) : (
                  <LabelStyle
                    onClick={() => {
                      setDeleteImg(false);
                    }}
                  >
                    찾기
                  </LabelStyle>
                )}
              </>
            )}
          </UploadInputContainerStyle>
        </RowStyle>
        <BtnContainerStyle modifyMode={type === 'modify'}>
          {type === 'modify' && (
            <BtnStyle
              backgroundColor="#DBDBDB"
              color="black"
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              삭제
            </BtnStyle>
          )}
          <div>
            <BtnStyle
              backgroundColor="#4B4B4B"
              onClick={() => {
                handleModal(false);
                setModel(undefined);
              }}
            >
              취소
            </BtnStyle>
            {type === 'modify' && <BtnStyle onClick={checkModifyLink}>수정</BtnStyle>}
            {type === 'create' && <BtnStyle onClick={handleClickCreate}>등록</BtnStyle>}
          </div>
        </BtnContainerStyle>
      </ContainerStyle>
    </HeightStyle>
  );
});

const inputStyle: CSSProperties = {
  height: 40,
  fontSize: 14,
  background: 'white',
  border: '1px solid #eeeeee',
};

const textAreaStyle: CSSProperties = {
  width: '100%',
  height: 50,
  resize: 'none',
  fontSize: 14,
  background: 'white',
  border: '1px solid #eeeeee',
  borderRadius: 5,
};

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

const HeightStyle = styled.div`
  height: 100%;
  margin: auto;
`;

const ContainerStyle = styled.div`
  ${flexColumn}
  width: 60%;
  margin: auto;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  max-height: 600px;
  max-width: 800px;
`;

const ModalContainerStyle = styled.div`
  margin: 15% auto 0;
  max-width: 900px;
`;

const RowStyle = styled.div<{ flexDirection?: string; height?: string }>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  height: ${(props) => props.height || '100px'};
`;

const InputContainerStyle = styled.div`
  margin: 20px;
  width: 100%;
  > div:first-child {
    margin-bottom: 5px;
  }
  > div:last-child > div:last-child {
    font-size: 14px;
  }
`;

const UploadInputContainerStyle = styled(InputContainerStyle)`
  display: flex;
  align-items: center;
  padding-top: 20px;
  > div:first-child {
    display: flex;
    flex-direction: column;
    width: 90%;
    > div {
      margin-bottom: 10px;
    }
  }
`;

const BtnContainerStyle = styled.div<{ modifyMode?: boolean }>`
  display: flex;
  margin-top: 10px;
  ${(props) =>
    props.modifyMode
      ? css`
          justify-content: space-between;
        `
      : css`
          justify-content: center;
        `}
  > div:last-child {
    display: flex;
  }
`;

const BtnStyle = styled.div<{ backgroundColor?: string; color?: string }>`
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#144da8'};
  color: ${(props) => props.color || 'white'};
  max-width: 50px;
  text-align: center;
  border-radius: 20px;
  padding: 10px 25px;
  margin: 20px 10px;
`;

const DeleteModalCotnainerStyle = styled.div`
  width: 300px;
  background: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25% auto;
  outline: none;
  > div:last-child {
    display: flex;
    width: 100%;
    margin-top: 30px;
    > div:first-child {
      border-bottom-left-radius: 15px;
    }
    > div:last-child {
      border-bottom-right-radius: 15px;
    }
  }
`;

const ModalTitleStyle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 30px 0 0;
`;

const ModalBtnStyle = styled(BtnStyle)`
  width: 50%;
  border-radius: 0;
  margin: 0;
  max-width: unset;
  padding: 15px 20px;
`;

const InputStyle = styled.input.attrs({ type: 'file', id: 'file' })`
  display: none;
`;

const LabelStyle = styled.label.attrs({ htmlFor: 'file' })`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 10px 20px;
  height: 30px;
  margin-bottom: 10px;
  margin-left: 10px;
  min-width: 50px;
  cursor: pointer;
`;

const UploadInputStyle = styled.div`
  > img {
    position: absolute;
    right: 165px;
    bottom: 148px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    filter: brightness(0);
  }
`;

const ThumbnailContainerStyle = styled.div`
  display: flex;
  width: 100%;
  > img {
    margin-right: 10px;
    width: 70px;
    height: 70px;
  }
  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const SpanStyle = styled.span`
  color: #cc2121;
`;

export default LinkModifyContainer;
