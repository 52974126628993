import ServiceStoreBase from 'stores/ServiceStoreBase';

class ModelHelper<T> {
  private model: T;

  private store: ServiceStoreBase;

  constructor(model: T | undefined, store: ServiceStoreBase) {
    if (typeof model !== 'undefined') {
      this.model = model;
    }
    this.store = store;
  }

  async get(id: number) {
    await this.store?.getItem(id);
  }

  async create() {
    if (typeof this.model !== 'undefined') {
      const result = await this.store?.createItem(this.model);
      return result;
    }
    return false;
  }

  async update(id?: number) {
    if (typeof this.model !== 'undefined' && typeof id !== 'undefined') {
      const result = await this.store?.updateItem(id, this.model);
      return result;
    }
    return false;
  }

  async delete(id: number) {
    if (typeof this.model !== 'undefined') {
      const result = await this.store?.deleteItem(id);
      return result;
    }
    return false;
  }
}

export default ModelHelper;
