import React, { CSSProperties, useEffect, useState } from 'react';
import { Editor as EditorOrigin } from '@tinymce/tinymce-react';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';

const Editor = ({
  style,
  defaultValue,
  onEditorChange,
}: {
  style: CSSProperties;
  defaultValue?: string;
  onEditorChange: (value: string) => void;
}) => {
  const styles = { ...style };
  const { fileStore } = useStore();
  const [initialValue, setInitialValue] = useState<string>();
  const uploadImageHandler = async (blobInfo: any) => {
    const blobImage = blobInfo.blobUri();

    const response = await fetch(blobImage);
    const blob = await response.blob();
    const data: any = await fileStore.uploadImage(blob);
    return new Promise((resolve, reject) => {
      resolve(data[0].publicUrl);
    });
  };

  useEffect(() => {
    if (typeof initialValue === 'undefined') {
      setInitialValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ContainerStyle>
      <EditorOrigin
        apiKey="3peds4p96cowrdbb0w4xbzuv85eheuvgdy6oronqi157wt1t"
        // value={linkModel?.page?.content}
        initialValue={initialValue}
        init={{
          height: '100%',
          menubar: true,
          image_dimensions: true,
          paste_data_images: true,
          // valid_styles: '+body[style]',
          extended_valid_elements: 'style,link[href|rel]',
          custom_elements: 'style,link,~link',
          // selector: 'textarea#file-picker',
          file_picker_types: 'file image media',
          plugins: 'image code paste table link',
          content_style: 'img{max-width:100%}',
          images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp',
          // keep_styles: true,
          toolbar:
            'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          // automatic_uploads: true,
          images_upload_handler: uploadImageHandler as any,

          // file_browser_callback_types: 'image',
          // file_picker_callback: handleUploadImage,
        }}
        onEditorChange={(content: string) => {
          // editorValue.current = content;
          onEditorChange(content);
          // updateModel(setLinkModel, 'page', { ...linkModel?.page, content });
        }}
      />
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  height: 600px;
`;
export default React.memo(Editor);
