import { COLUMN_TYPES } from 'controls/table/ColumnTypes';
import React, { CSSProperties, useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from 'stores/StoreHelper';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineOutlined from '@mui/icons-material/StarOutlineOutlined';
import PostDetailContainer from 'features/bannerManagement/containers/PostDetailContainer';

const thStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  color: '#8B8F9A',
  fontWeight: 500,
  textAlign: 'center',
};

const tdStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  maxWidth: 400,
};

const textStyle: CSSProperties = {
  color: '#144da8',
  textDecoration: 'underline',
  textUnderlinePosition: 'under',
  textAlign: 'center',
  cursor: 'pointer',
};

const starStyle: CSSProperties = { cursor: 'pointer', fill: '#144da8' };

const PinPost = (props: any) => {
  const { communityPostStore } = useStore();

  const handleClick = async () => {
    await communityPostStore.updatePostPin(props.item.id);
    if (communityPostStore.pinResponse.status === 200) {
      if (props.item.isPin === false) {
        toast('게시글 상단고정이 완료되었습니다.', { type: 'success' });
      } else {
        toast('게시글 상단고정을 해제합니다.', { type: 'info' });
      }
    }
  };

  return (
    <div
      onClick={() => {
        handleClick();
      }}
    >
      {props.item?.isPin ? (
        <StarIcon style={starStyle} />
      ) : (
        <StarOutlineOutlined style={starStyle} />
      )}
    </div>
  );
};

const PostModal = (props: any) => {
  return (
    <>
      <PostDetailContainer id={props.item.id} />
    </>
  );
};

class PostHeaders {
  communityId: number | undefined = undefined;

  constructor(communityId?: number) {
    this.communityId = communityId;
  }

  getPostHeaders() {
    return [
      {
        title: '번호',
        key: 'id',
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '제목',
        key: 'title',
        type: COLUMN_TYPES.LINK,
        options: {
          linkUrl: `/communities/${this.communityId}/posts/{0}/form`,
          linkStyle: { color: 'black', textUnderlinePosition: 'under' },
        },
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '작성유형',
        type: COLUMN_TYPES.CUSTOM,
        template: <div>게시글</div>,
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '카테고리',
        key: 'board.name',
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '작성일',
        type: COLUMN_TYPES.DATETIME,
        key: 'createDate',
        style: thStyle,
        contentStyle: tdStyle,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
      },
      {
        title: '수정일',
        type: COLUMN_TYPES.DATETIME,
        key: 'modifyDate',
        style: thStyle,
        contentStyle: tdStyle,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
      },
      {
        title: '',
        key: 'id',
        type: COLUMN_TYPES.CUSTOM,
        template: <PinPost />,
        style: thStyle,
        contentStyle: tdStyle,
      },
    ];
  }

  getCommunityHeaders() {
    return [
      {
        title: '선택',
        type: COLUMN_TYPES.CHECKBOX,
        style: { ...thStyle, width: 50 },
        contentStyle: tdStyle,
      },
      {
        title: '번호',
        type: COLUMN_TYPES.AUTOID,
        style: { ...thStyle, width: 50 },
        contentStyle: tdStyle,
      },
      {
        title: '커뮤니티명',
        key: 'name',
        type: COLUMN_TYPES.LINK,
        options: {
          linkUrl: `/community/{0}/modify`,
          linkStyle: { color: 'black', textUnderlinePosition: 'under' },
        },
        style: { ...thStyle },
        contentStyle: { ...tdStyle, textAlign: 'left' },
      },
      {
        title: 'Id',
        key: 'id',
        style: { ...thStyle },
        contentStyle: tdStyle,
      },
    ];
  }

  getViewHeaders() {
    return [
      {
        title: '',
        mergeRow: [
          {
            title: '비공개',
            key: 'isPrivate',
            type: COLUMN_TYPES.CHECKBOX,
          },
        ],
      },
      {
        title: '생성일',
        type: COLUMN_TYPES.DATETIME,
        key: 'createDate',
        editable: false,
      },
    ];
  }

  getPostManageHeaders() {
    return [
      {
        title: '선택',
        type: COLUMN_TYPES.CHECKBOX,
        style: { ...thStyle, width: 50 },
        contentStyle: tdStyle,
      },
      {
        title: '번호',
        type: COLUMN_TYPES.AUTOID,
        style: { ...thStyle, width: 50 },
        contentStyle: tdStyle,
      },
      {
        title: '커뮤니티명',
        key: 'name',
        style: { ...thStyle },
        contentStyle: { ...tdStyle, textAlign: 'left' },
      },
      {
        title: '게시물관리',
        label: '상세',
        type: COLUMN_TYPES.LINK,
        options: {
          linkUrl: `/communities/{0}/posts`,
          linkStyle: { color: '#144da8', textUnderlinePosition: 'under' },
        },
        style: { ...thStyle, textAlign: 'center', width: 100 },
        contentStyle: { ...tdStyle, textAlign: 'center' },
      },
      {
        title: 'Id',
        key: 'id',
        style: { ...thStyle, width: 50 },
        contentStyle: tdStyle,
      },
    ];
  }

  getBannerPostHeaders() {
    return [
      {
        title: '번호',
        key: 'id',
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '제목',
        key: 'title',
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '내용',
        type: COLUMN_TYPES.CUSTOM,
        template: <PostModal />,
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '번호',
        key: 'creator.name',
        style: thStyle,
        contentStyle: tdStyle,
      },
      {
        title: '카테고리',
        key: 'board.name',
        style: thStyle,
        contentStyle: tdStyle,
      },
    ];
  }
}

export { PostHeaders };
