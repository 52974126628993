import React, { CSSProperties, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { Table } from 'controls/table';
import { SettlementHeaders } from '../settings/definition';
import { SettlementDto } from 'services/data-contracts';
import styled from 'styled-components';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

const ListPage = observer(() => {
  const { orderStore } = useStore();
  const settlementHeader: SettlementHeaders = new SettlementHeaders();

  const init = () => {
    orderStore.getSettlements();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <HeaderTitleStyle title="정산내역" />
      <ContainerStyle>
        <HeaderStyle>
          <TextStyle>송금실패건수 - 건, 송금실패금액 - 원</TextStyle>
          <div>
            <TextStyle>등록일별조회</TextStyle>
            <BtnStyle />
            <BtnLabelStyle>송금실패</BtnLabelStyle>
          </div>
        </HeaderStyle>
        <Table<SettlementDto>
          headers={settlementHeader.getSettlementHeaders()}
          items={orderStore.settlements}
          tableStyle={tableStyle}
        />
      </ContainerStyle>
    </>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  fontSize: 14,
  marginBottom: 20,
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  > div {
    display: flex;
    align-items: center;
  }
`;

const TextStyle = styled.div`
  color: #868686;
  font-size: 14px;
`;

const BtnStyle = styled.input.attrs({ type: 'checkbox' })`
  border-color: #dadada;
  margin-left: 10px;
`;

const BtnLabelStyle = styled.label`
  margin-right: 10px;
  color: black;
  font-size: 14px;
`;

export default ListPage;
