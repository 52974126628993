type MenuProps = {
  title: string;
  id: string;
  navigate: string;
  subMenu?: Array<MenuProps>;
  adminOnly?: boolean;
};

const GnbMenu: Array<MenuProps> = [
  // {
  //   title: '대시보드',
  //   id: 'dashBoard',
  //   navigate: '/dashBoard',
  // },
  {
    title: '회원통합관리',
    id: 'member',
    adminOnly: true,
    subMenu: [
      { title: '일반', id: 'normal', navigate: '/member/normal' },
      { title: '관리자', id: 'manager', navigate: '/member/admin' },
      { title: '톡', id: 'talk', navigate: '/member/talk' },
      { title: '부동산', id: 'property', navigate: '/member/property' },
    ],
    navigate: '/member',
  },
  {
    title: '상품관리',
    id: 'items',
    adminOnly: true,
    subMenu: [
      { title: '장터', id: 'normal', navigate: '/items/market' },
      { title: '부동산', id: 'manager', navigate: '/items/property' },
    ],
    navigate: '/items/market',
  },
  {
    title: '링크',
    id: 'link',
    adminOnly: true,
    subMenu: [
      { title: '목록', id: 'list', navigate: '/link' },
      // { title: '카테고리', id: 'category', navigate: '/link/category' },
    ],
    navigate: '/link',
  },
  {
    // 컨텐츠 navigate 수정
    title: '컨텐츠',
    id: 'contents',
    subMenu: [
      { title: '게시물 관리', id: 'posts', navigate: '/communities/posts' },
      { title: '장터', id: 'market', navigate: '/communities/market' },
      { title: '물물교환', id: 'barter', navigate: '/communities/barter' },
      { title: '톡', id: 'talk', navigate: '/communities/talk' },
      { title: '부동산', id: 'property', navigate: '/communities/property' },
    ],
    navigate: '/communities/posts',
  },
  {
    title: '번영회',
    id: 'community',
    subMenu: [{ title: '번영회 관리', id: 'community', navigate: '/community' }],
    navigate: '/community',
  },
  {
    title: '배너관리',
    id: 'banner',
    subMenu: [
      { title: '번영회', id: 'community', navigate: '/banner' },
      { title: '장터', id: 'market', navigate: '/banner/market' },
      { title: '톡', id: 'talk', navigate: '/banner/talk' },
      { title: '부동산', id: 'property', navigate: '/banner/property' },
    ],
    navigate: '/banner',
  },
  {
    title: '신고',
    id: 'report',
    subMenu: [{ title: '일반', id: 'report', navigate: '/report' }],
    navigate: '/report',
  },
  {
    title: '탈퇴',
    id: 'secession',
    subMenu: [{ title: '일반', id: 'secession', navigate: '/secession' }],
    navigate: '/secession',
  },
  {
    title: '고객센터',
    id: 'service',
    subMenu: [
      { title: '자주묻는글', id: 'FAQ', navigate: '/service/faq' },
      { title: '문의글', id: 'qna', navigate: '/service/qna' },
    ],
    navigate: '/service',
  },
  {
    title: '거래내역',
    id: 'history',
    adminOnly: true,
    subMenu: [
      { title: '일반', id: 'normal', navigate: '/settlement' },
      { title: '정산', id: 'settlement', navigate: '/settlement/list' },
    ],
    navigate: '/settlement',
  },
];

export { MenuProps, GnbMenu };
