/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-expressions */
import { makeAutoObservable, makeObservable, observable, action, runInAction } from 'mobx';
import { isMobile } from 'react-device-detect';
import { ConfirmOptions, DrawerOptions, ProgressOptions, ModalOptions } from 'types/CommonTypes';

class UIStore {
  reload = false;

  options: any = undefined;

  confirm: Confirm = new Confirm();

  modal = new Modal();

  drawer: Drawer = new Drawer();

  progress: Progress = new Progress();

  constructor() {
    makeAutoObservable(this);
  }

  menuSwiperChanged: boolean;

  /** @deprecated */
  setPortalComponent(options: any) {
    this.options = options;
    // this.portalShow = true;
  }
}

class Confirm {
  /**
   * @Confirm
   */
  confirmShow = false;

  options: ConfirmOptions | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  show(options: ConfirmOptions) {
    this.options = options;
    this.confirmShow = true;
  }

  close() {
    if (typeof this.options?.onCancel !== 'undefined') {
      this.options?.onCancel();
    }
    this.confirmShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      this.options = undefined;
    }, 500);
  }

  confirm() {
    this.confirmShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      if (typeof this.options?.onConfirmed !== 'undefined') {
        this.options?.onConfirmed();
      }
      this.options = undefined;
    }, 50);
  }
}

class Modal {
  /**
   * @Confirm
   */
  modalShow = false;

  options: ModalOptions | undefined;

  contentModel: any | undefined;

  constructor() {
    makeObservable(this, {
      options: observable,
      modalShow: observable,
      show: action,
      update: action,
      close: action,
      confirm: action,
      updateModel: action,
    });
  }

  show(options: ModalOptions) {
    this.options = options;

    this.modalShow = true;

    const t = document.querySelector('body');
    if (t) {
      t.style.overflow = 'hidden';
    }
  }

  update(options: ModalOptions) {
    this.options = options;
  }

  updateModel(model: any) {
    this.contentModel = model;
  }

  close() {
    if (typeof this.options?.onClose !== 'undefined') {
      this.options?.onClose();
    }
    this.contentModel = undefined;
    this.modalShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    const t = document.querySelector('body');
    if (t) {
      t.style.overflow = 'initial';
    }
    setTimeout(() => {
      this.options = undefined;
    }, 500);
  }

  confirm(result?: any) {
    this.modalShow = false;
    this.contentModel = undefined;

    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      if (typeof this.options?.onConfirmed !== 'undefined') {
        this.options?.onConfirmed(result);
      }
      this.close();
      this.options = undefined;
    }, 50);
  }
}

class Drawer {
  /**
   * @Confirm
   */
  drawerShow = false;

  options: DrawerOptions | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  show(options: DrawerOptions) {
    this.options = options;
    this.drawerShow = true;
  }

  close() {
    if (typeof this.options?.onCancel !== 'undefined') {
      this.options?.onCancel();
    }
    this.drawerShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      this.options = undefined;
    }, 500);
  }

  confirm(result?: any) {
    this.drawerShow = false;
    // 모달이 사라지기전에 데이터가 사라져 전환이 어색해지므로 setTimeout으로 호출
    setTimeout(() => {
      if (typeof this.options?.onConfirmed !== 'undefined') {
        this.options?.onConfirmed(result);
      }

      this.options = undefined;
    }, 50);
  }
}

class Progress {
  /**
   * @Confirm
   */
  progressShow = false;

  options: ProgressOptions | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  show(options?: ProgressOptions) {
    this.options = options;
    this.progressShow = true;
  }

  close() {
    // if (typeof this.options?.onCancel !== 'undefined') {
    //   this.options?.onCancel();
    // }
    this.progressShow = false;
    this.options = undefined;
  }
}

export default UIStore;
