import { makeObservable, flow } from 'mobx';
import { Admin as AdminServices } from 'adminServices/Admin';
import { ADMIN_REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from 'stores/ServiceStoreBase';
import { setTokens } from 'libs/helper/localStorageHelper';

class AdminStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      login: flow,
    });
  }

  api: AdminServices<unknown> = new AdminServices({
    baseURL: ADMIN_REST_API_URL,
  });

  loginError: any;
  result: any;

  *login(email: string, password: string) {
    const { data, error } = yield this.api.login({
      adminEmailId: email,
      password: password,
    });
    if (data !== null) {
      setTokens(data);
      this.result = true;
    } else {
      this.loginError = error;
      this.result = false;
      return { result: false, error };
    }
  }
}

export default AdminStore;
