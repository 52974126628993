import { CSSProperties, ReactElement } from 'react';

enum COLUMN_TYPES {
  AUTOID,
  LINK,
  MODAL,
  CUSTOM,
  CHECKBOX,
  UNDEFINED,
  DATETIME,
  CURRENCY,
}
interface HeaderType {
  /** @description key 는 Item의 필드와 매핑된다. */
  key?: string;
  title: string;
  type?: COLUMN_TYPES;
  options?: { linkUrl?: string; linkStyle?: CSSProperties; dateFormat?: string };
  template?: ReactElement;
  label?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  linkStyle?: CSSProperties;
}

interface ViewHeaderType extends HeaderType {
  editable?: boolean;
  mergeRow?: Array<ViewHeaderType>;
}

export { COLUMN_TYPES, HeaderType, ViewHeaderType };
