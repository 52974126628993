import { makeObservable, flow, observable, action } from 'mobx';
import { Order as OrderService } from 'services/Order';

// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from './ServiceStoreBase';
import { Item } from 'adminServices/Item';

class MarketStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      items: observable,
      getItems: flow,
    });
  }

  api: Item<unknown> = new Item({
    baseURL: REST_API_URL,
  });

  items: any;

  *getItems(query?: any) {
    const { response } = yield this.api.getItems({ ...query });
    this.items = response.data;
    return super.handleByStatus(response);
  }

  *getItem(id: number) {
    const { response } = yield this.api.getItem(id);
    return response.data;
  }
}

export default MarketStore;
