import { COLUMN_TYPES } from 'controls/table/ColumnTypes';
import React, { CSSProperties } from 'react';

const AnswerConvertor = (props: any) => {
  return <div>{props.item?.isAnswer === true ? '답변' : '미답변'}</div>;
};

const CheckingConvertor = (props: any) => {
  return <div>{props.item?.statusType === 'COMPLETED' ? '처리완료' : '확인중'}</div>;
};

const QNATypeConvertor = (props: any) => {
  return <div>{props.item?.qnaType === 'BUG' ? '버그' : '이용문의'}</div>;
};

const CategoryTypeConvertor = (props: any) => {
  return (
    <>
      {props.item?.categoryType === 'APP' && <div>앱설정</div>}
      {props.item?.categoryType === 'MARKET' && <div>장터</div>}
      {props.item?.categoryType === 'BARTER' && <div>물물교환</div>}
      {props.item?.categoryType === 'COMMUNITY' && <div>번영회</div>}
      {props.item?.categoryType === 'TALK' && <div>톡</div>}
      {props.item?.categoryType === 'PROPERTY' && <div>부동산</div>}
    </>
  );
};

const thStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  color: '#8B8F9A',
  fontWeight: 500,
  textAlign: 'center',
};

const tdStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  textAlign: 'center',
  maxWidth: 400,
};

const tdLeftStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  textAlign: 'left',
  maxWidth: 400,
};

class FaqHeaders {
  getFaqHeaders() {
    return [
      {
        title: '',
        type: COLUMN_TYPES.CHECKBOX,
        style: { ...thStyle, width: 50 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '질문',
        key: 'title',
        style: { ...thStyle },
        contentStyle: { ...tdLeftStyle },
      },
      {
        title: '',
        key: 'id',
        label: '수정',
        type: COLUMN_TYPES.LINK,
        options: {
          linkUrl: `/faq/{0}/modify`,
          linkStyle: { color: '#144da8', textUnderlinePosition: 'under' },
        },
        style: { ...thStyle, width: 50 },
        contentStyle: { ...tdStyle },
      },
    ];
  }

  getQnaHeaders() {
    return [
      {
        title: 'No',
        key: 'id',
        style: { ...thStyle, width: 50 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '1차유형',
        key: 'categoryType',
        type: COLUMN_TYPES.CUSTOM,
        template: <CategoryTypeConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '2차유형',
        key: 'qnaType',
        type: COLUMN_TYPES.CUSTOM,
        template: <QNATypeConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '제목',
        key: 'title',
        type: COLUMN_TYPES.LINK,
        options: {
          linkUrl: `/qna/{0}/answer`,
          linkStyle: { color: '#144da8', textUnderlinePosition: 'under' },
        },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '작성자',
        key: 'questioner.name',
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '등록일',
        key: 'questionCreateDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '답변',
        key: 'isAnswer',
        type: COLUMN_TYPES.CUSTOM,
        template: <AnswerConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '처리여부',
        key: 'statusType',
        type: COLUMN_TYPES.CUSTOM,
        template: <CheckingConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '',
        key: 'id',
        type: COLUMN_TYPES.LINK,
        label: '답변',
        options: {
          linkUrl: `/qna/{0}/answer`,
          linkStyle: { color: '#144da8', textUnderlinePosition: 'under' },
        },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
    ];
  }
}

export { FaqHeaders };
