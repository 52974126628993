/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { Select, MenuItem, Button, Checkbox } from '@mui/material';
import { Input } from 'components/commons';
import { useNavigate } from 'react-router-dom';
import { CommunityDto, CommunityBoardDto } from 'services/data-contracts';
import { PostDto } from 'adminServices/data-contracts';
import { Column, Row } from 'components/commons/layouts';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Editor from 'components/commons/webEditor/Editor';
import ModelHelper from 'libs/helper/modelHelper';
import styled from 'styled-components';

const PostForm = observer(({ communityId, postId }: { communityId: number; postId?: string }) => {
  const { communityStore, communityPostStore, uiStore } = useStore();
  const navigate = useNavigate();
  const [selectedBoard, setSelectedBoard] = useState<CommunityBoardDto>();
  const [post, setPost] = useState<PostDto>();
  const modelHelper = new ModelHelper<CommunityDto>(post, communityPostStore);

  const handleCreatePost = async () => {
    const result = await modelHelper.create();
    goBack(result);
  };

  const handleUpdatePost = async () => {
    const result = await modelHelper.update(Number(postId));
    goBack(result);
  };

  const handleDeletePost = () => {
    uiStore.confirm.show({
      title: '정말로 삭제하시겠습니까?',
      onConfirmed: async () => {
        const result = await modelHelper.delete(Number(postId));
        goBack(result);
      },
    });
  };

  const goBack = (result: any) => {
    if (result) {
      navigate(`/communities/${communityId}/posts`);
    }
  };

  const init = async () => {
    if (typeof communityId !== 'undefined') {
      await communityStore.getCommunityBoards(communityId!);
      setPost({ ...post, community: { id: communityId }, isHtml: true });
    }

    if (typeof postId !== 'undefined') {
      await communityPostStore.getItem(Number(postId));
      if (communityPostStore.postError !== null) {
        toast(communityPostStore.postError.errorMessage, { type: 'error' });
        navigate('/communities/posts');
      }
    }
  };

  useEffect(() => {
    if (typeof communityPostStore.post !== 'undefined') {
      setPost(communityPostStore.post);
      setSelectedBoard(communityPostStore.post?.board as CommunityBoardDto);
    }
  }, [communityPostStore.post]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Column title={'카테고리'}>
          <SelectBoxStyle>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={selectedBoard?.name}
              onChange={(e: any, board: any) => {
                setSelectedBoard((prev) => {
                  return { ...prev, id: board.props.value };
                });
                setPost({ ...post, board: { id: board.props.value } });
              }}
            >
              {communityStore.boards?.map((board: CommunityBoardDto) => (
                <MenuItem value={board.id}>{board.name}</MenuItem>
              ))}
            </Select>
          </SelectBoxStyle>
        </Column>
        <Column title={'HTML사용'}>
          <CheckBoxContainerStyle>
            <div>HTML 사용</div>
            <Checkbox checked={true} />
          </CheckBoxContainerStyle>
        </Column>
        <Column title={'Push사용'}>
          <CheckBoxContainerStyle>
            <div>Push 사용</div>
            <Checkbox
              checked={false}
              // onChange={(e) => {
              //   setPost({ ...post, isNotify: e.target.checked });
              // }}
            />
          </CheckBoxContainerStyle>
        </Column>
      </Row>
      <EditorContainerStyle>
        <Input
          style={style}
          containerStyle={containerStyle}
          required
          label="제목"
          id="title"
          defaultValue={post?.title}
          onChangeValue={(value: string) => {
            setPost({ ...post, title: value });
          }}
        />
        <Editor
          style={{ height: 500 }}
          defaultValue={post?.contents}
          onEditorChange={(value: string) => {
            setPost({ ...post, contents: value });
          }}
        />
      </EditorContainerStyle>
      <Row style={rowStyle}>
        <Column>
          <Button
            size="large"
            variant="contained"
            onClick={typeof postId === 'undefined' ? handleCreatePost : handleUpdatePost}
            startIcon={<EditIcon />}
          >
            {typeof postId !== 'undefined' ? '수정하기' : '작성하기'}
          </Button>
        </Column>
        <Column>
          <Button
            size="large"
            variant="outlined"
            onClick={handleDeletePost}
            startIcon={<DeleteForeverIcon />}
          >
            삭제하기
          </Button>
        </Column>
      </Row>
    </>
  );
});

const containerStyle = { marginBottom: 20 };

const style = { borderRadius: '6px' };

const rowStyle = { justifyContent: 'center', marginTop: 30, paddingBottom: 30 };

const SelectBoxStyle = styled.div`
  > div > div {
    padding: 10px;
  }
`;

const EditorContainerStyle = styled.div`
  padding: 20px;
`;

const CheckBoxContainerStyle = styled.div`
  display: flex;
`;

export default PostForm;
