import { flow, makeObservable, observable } from 'mobx';
import { CommunityPost as CommunityPostServices } from 'adminServices/CommunityPost';
import { ADMIN_REST_API_URL } from 'stores/AppStore';
import { CommunityBoardDto, CommunityDto, PostDto } from 'adminServices/data-contracts';
import ServiceStoreBase from 'stores/ServiceStoreBase';

class CommunityPostStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      communities: observable,
      posts: observable,
      post: observable,
      boards: observable,
      postError: observable,
      filteredPost: observable,
      updatePostPin: flow,
      getPosts: flow,
    });
  }

  api: CommunityPostServices<unknown> = new CommunityPostServices({
    baseURL: ADMIN_REST_API_URL,
  });

  communities: Array<CommunityDto>;

  posts: Array<PostDto>;

  post: PostDto;

  postError: any;

  boards: Array<CommunityBoardDto>;

  pinResponse: any;

  filteredPost: Array<PostDto>;

  pagination: any;

  *getItem(postId: number) {
    const { response, data, error } = yield this.api.getPost(postId);
    if (response.status === 200) {
      this.post = data;
    } else {
      this.postError = error.data;
    }
  }

  *getPosts(query?: {
    searchKeyword?: string;
    categoryId?: number;
    regionCode?: string;
    page?: number;
    size?: number;
    sort?: string[];
  }) {
    const { response, data, error, headers } = yield this.api.getPosts({ ...query, size: 10 });
    if (response.status === 200) {
      this.filteredPost = data;
      this.pagination = JSON.parse(headers['x-pagination']);
    } else {
      this.postError = error.data;
    }
  }

  *updatePostPin(postId: number) {
    const { response } = yield this.api.updatePostPin(postId);
    this.pinResponse = response;
  }

  *createItem(post: PostDto) {
    const { response } = yield this.api.createPost(post);
    return super.handleByStatus(response, { successMessage: '정상적으로 생성하였습니다.' });
  }

  *updateItem(postId: number, post: PostDto) {
    const { response } = yield this.api.updatePost(postId, post);
    return super.handleByStatus(response, { successMessage: '정상적으로 수정하였습니다.' });
  }

  *deleteItem(postId: number) {
    const { response } = yield this.api.deletePost(postId);
    return super.handleByStatus(response, { successMessage: '정상적으로 삭제하였습니다.' });
  }
}

export default CommunityPostStore;
