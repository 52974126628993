import React, { useState, useEffect, ReactElement, CSSProperties } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { format } from 'react-string-format';
import { COLUMN_TYPES, HeaderType } from './ColumnTypes';
import moment from 'moment';
// import './Table.scss';
import NumberFormat from 'react-number-format';

type Props<T> = {
  headers: Array<HeaderType>;
  items?: Array<T>;
  tableStyle?: CSSProperties;
  onClick?: (item: T) => void;
};

function Table<T>({ headers, items, tableStyle, onClick }: Props<T>) {
  const getValueByPath = (item: T, path?: string) => {
    return path?.split('.').reduce((res: any, prop: string) => res[prop], item);
  };

  /**
   * @description Table의 Header를 생성한다.
   * @returns
   */
  const generateHeaders = () => {
    return (
      <tr>
        {headers?.map((header, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <th key={`th_${i}`} style={header.style}>
            {header.title}
          </th>
        ))}
      </tr>
    );
  };

  //   const handleCheckAll = (bool) => {
  //     setAllChecked(bool);
  //   };

  /**
   * @description Tr을 생성한다.
   * @returns
   */
  const Tr = ({ children }: { children: ReactElement[] }) => {
    const [hover, setHover] = useState(false);
    return (
      <tr
        className={`${hover ? '--hover' : ''}`}
        onMouseEnter={() => setHover(!hover)}
        onMouseLeave={() => setHover(!hover)}
      >
        {children}
      </tr>
    );
  };

  const generateRows = () => {
    if (!Array.isArray(items)) {
      return;
    }
    return items.map((item: T & { id: number }, i: number) => (
      <Tr key={`tr_${item.id}`}>{generateColumns(item, i)}</Tr>
    ));
  };

  /**
   * @description Row의 컬럼셋을 생성한다.
   * @param {*} item
   * @param {*} rowNo
   * @returns
   */
  const generateColumns = (item: T & { id: number }, rowNo: number) => {
    return headers.map((header, i) =>
      // 컬럼타입이 autoId인경우 컬럼을 자동생성한다.
      typeof header.type !== 'undefined' && header.type === COLUMN_TYPES.AUTOID ? (
        <td style={{ ...header.contentStyle }} key={`td_${rowNo + i}`}>
          {rowNo + 1}
        </td>
      ) : (
        // <td key={rowNo + i} style={header.contentStyle}>
        <td style={{ ...header.contentStyle }} key={`td_${rowNo + i}`}>
          {bindContent(item, header)}
        </td>
      ),
    );
  };

  /**
   * @description 미리설정된 타입에 따라 값을 반환한다.
   * @param {*} item
   * @param {*} header
   * @returns
   */
  const bindContent = (item: T & { id?: number }, header: HeaderType) => {
    // 타입이 없으면 기본값만 리턴한다.
    if (typeof header.type === 'undefined') return getValueByPath(item, header?.key);
    switch (header.type) {
      // 커스텀타입
      case COLUMN_TYPES.CUSTOM:
        return bindCustomType(header.template, getValueByPath(item, header?.key), item);
      // 체크박스
      case COLUMN_TYPES.CHECKBOX:
        return <Checkbox />;
      // 모달창
      case COLUMN_TYPES.MODAL:
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleClickModal(header, item);
            }}
          >
            {getValueByPath(item, header.key)}
          </div>
        );
      // 링크(라우트)
      case COLUMN_TYPES.LINK:
        return (
          <>
            {typeof item.id !== 'undefined' && (
              <Link
                style={header.options?.linkStyle}
                to={`${
                  typeof header.options?.linkUrl !== 'undefined' &&
                  format(header.options?.linkUrl, item.id?.toString())
                }`}
              >
                {typeof header.label !== 'undefined' ? (
                  <>{header.label}</>
                ) : (
                  <> {getValueByPath(item, header.key)}</>
                )}
              </Link>
            )}
          </>
        );

      case COLUMN_TYPES.DATETIME:
        return (
          <>
            {typeof getValueByPath(item, header.key) !== 'undefined' && (
              <>
                {typeof header.options?.dateFormat !== 'undefined' ? (
                  <>{moment(getValueByPath(item, header.key)).format(header.options.dateFormat)}</>
                ) : (
                  <>{moment(getValueByPath(item, header.key)).format('YYYY-MM-DD')}</>
                )}
              </>
            )}
          </>
        );
      case COLUMN_TYPES.CURRENCY:
        return (
          <>
            {typeof getValueByPath(item, header.key) !== 'undefined' && (
              <>
                <NumberFormat
                  displayType="text"
                  value={getValueByPath(item, header.key)}
                  thousandSeparator
                  suffix="원"
                />
              </>
            )}
          </>
        );

      // 기본값 출력
      default:
        return getValueByPath(item, header.key);
    }
  };

  const bindCustomType = (info: any, value: any, item: any) => {
    return React.cloneElement(info, { item });
  };

  /**
   * @description Type이 Modal일경우, 해당 Element를 클릭할 경우
   * @description  header에서 선언된 Callback을 실행한다.
   * @param {*} header
   */
  const handleClickModal = (header: any, item: any) => {
    onClick?.(item);
    // const option = header.type.option;
    // typeof option !== 'undefined' &&
    //   typeof option.callback === 'function' &&
    //   option.callback(header, item);
  };

  /**
   * @description 값을 초기화한다.
   */
  useEffect(() => {
    //setHeaders(_.orderBy(headers, ['seq'], ['asc']));
  }, [headers]);

  return (
    <table className="salad-control__table" style={tableStyle}>
      <thead>{generateHeaders()}</thead>
      {items?.length === 0 ? (
        <tbody>
          <tr className="salad-control__table__no-data">
            <td
              colSpan={headers?.length}
              align="center"
              style={{ height: 40, border: '1px solid #eeeeee' }}
            >
              표시할 데이터가 없습니다.
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>{generateRows()}</tbody>
      )}
    </table>
  );
}

export default Table;
