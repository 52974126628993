import { COLUMN_TYPES } from 'controls/table/ColumnTypes';
import React, { CSSProperties } from 'react';

const StatusConvertor = (props: any) => {
  return <div>{props.item?.status === 'COMPLETED' ? '처리완료' : '확인중'} </div>;
};

const SettlementStatusConvertor = (props: any) => {
  return (
    <>
      {props.item?.settlement.status === 'HOLD' && <div>정산대기</div>}
      {props.item?.settlement.status === 'DONE' && <div>정산완료</div>}
      {props.item?.settlement.status === 'IN_PROGRESS' && (
        <div style={{ color: 'red' }}>잔액부족</div>
      )}
    </>
  );
};

const thStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  color: '#8B8F9A',
  fontWeight: 500,
  textAlign: 'center',
};

const tdStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  textAlign: 'center',
  maxWidth: 400,
};

class SettlementHeaders {
  getSettlementHeaders() {
    return [
      {
        title: 'No',
        type: COLUMN_TYPES.AUTOID,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '거래번호',
        key: 'orderNumber',
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      { title: '은행코드', style: { ...thStyle }, contentStyle: { ...tdStyle } },
      { title: '계좌번호', style: { ...thStyle }, contentStyle: { ...tdStyle } },
      {
        title: '예금주',
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '정산상태',
        key: 'settlement.status',
        type: COLUMN_TYPES.CUSTOM,
        template: <SettlementStatusConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      { title: '전문번호', style: { ...thStyle }, contentStyle: { ...tdStyle } },
      {
        title: '응답코드',
        type: COLUMN_TYPES.CUSTOM,
        template: <StatusConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '발생일',
        key: 'settlement.createDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '정산일',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '재정산일',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
    ];
  }
}

export { SettlementHeaders };
