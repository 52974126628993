import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { getTokens } from 'libs/helper/localStorageHelper';
import { Gnb } from 'features/commonFeatures/navigationBar/containers';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import styled, { css } from 'styled-components';
import mainRoutes from 'routes/mainRoutes';
import Lnb from 'features/commonFeatures/navigationBar/Lnb';

const LayoutWrapper = observer(() => {
  const pathname = window.location.pathname;
  const { userStore } = useStore();
  const [menu, setMenu] = useState<string>('');

  const init = async () => {
    await userStore.getMyInfo();
  };

  const handleMenu = (currentMenu: string) => {
    setMenu(currentMenu);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {pathname !== '/login' && <Lnb handleMenu={handleMenu} />}
      <Gnb handleMenu={handleMenu} />
      <RouteContainerStyle pathname={pathname}>
        <Routes>
          {mainRoutes.map((route, index: number) => {
            return (
              <Route
                key={`${route.title}_${index}`}
                path={route.path}
                element={
                  <Auth isPrivate={route.authenticated}>
                    <>
                      <route.element menu={menu} />
                    </>
                  </Auth>
                }
              />
            );
          })}
        </Routes>
      </RouteContainerStyle>
    </>
  );
});

const Auth = ({ isPrivate, children }: { isPrivate?: boolean; children: JSX.Element }) => {
  const token = getTokens().accessToken !== null;

  if (isPrivate !== false) {
    if (token) {
      return children;
    } else {
      return children;
      // return <Navigate to="/login" />;
    }
  } else {
    return children;
  }
};

const RouteContainerStyle = styled.div<{ pathname?: string }>`
  height: calc(100% - 100px);
  ${(props) =>
    props.pathname === '/login'
      ? css``
      : css`
          position: absolute;
          left: 240px;
          width: calc(100vw - 240px);
        `}
  background-color: #fafafa;
`;

export default LayoutWrapper;
