import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CommunityList from '../containers/CommunityList';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

const ListPage = observer(() => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderTitleStyle title="번영회관리" />
      <ContainerStyle>
        <HeaderStyle>
          <BtnStyle
            onClick={() => {
              navigate('/community/create');
            }}
          >
            등록하기
          </BtnStyle>
        </HeaderStyle>
        <CommunityList />
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

const HeaderStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BtnStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  border: 1px solid #144da8;
  border-radius: 25px;
  color: white;
  background-color: #144da8;
  cursor: pointer;
  margin-bottom: 20px;
`;

export default ListPage;
