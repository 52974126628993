import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { Table } from 'controls/table';
import { FaqCategoryDto, FaqDto } from 'services/data-contracts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaqHeaders } from '../settings/definition';
import styled from 'styled-components';
import PaginationComponent from 'components/commons/PaginationComponent';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

const ListPage = observer(() => {
  const navigate = useNavigate();
  const pageNo = useRef<number>(0);
  const faqHeaders: FaqHeaders = new FaqHeaders();
  const [searchParams] = useSearchParams();
  const { faqStore } = useStore();
  const [filterOption, setFilterOption] = useState<number>();

  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    navigate(`/service/faq?page=${pageNumber - 1}`);
  };

  const init = () => {
    pageNo.current = searchParams.get('page') === null ? 0 : Number(searchParams.get('page'));
    if (filterOption === 0 || typeof filterOption === 'undefined') {
      faqStore.getFaqs({ page: pageNo.current });
    } else {
      faqStore.getFaqs({ categoryId: filterOption, page: pageNo.current });
    }
  };

  useEffect(() => {
    faqStore.getCategories();
  }, []);

  useEffect(() => {
    init();
    return () => {
      faqStore.clearFaqs();
    };
  }, [searchParams, filterOption]);

  return (
    <>
      <HeaderTitleStyle title="자주묻는글" />
      <ContainerStyle>
        <GrayBtnContainerStyle>
          <GrayBtnStyle
            onClick={() => {
              navigate('/faq/create');
            }}
          >
            등록하기
          </GrayBtnStyle>
        </GrayBtnContainerStyle>
        <HeaderStyle>
          <BtnContainerStyle>
            <BtnStyle
              defaultChecked={true}
              id="all"
              onClick={() => {
                setFilterOption(0);
              }}
            />
            <BtnLabelStyle htmlFor="all">전체</BtnLabelStyle>
            {typeof faqStore.faqCategory !== 'undefined' && (
              <>
                {faqStore.faqCategory?.map((item: FaqCategoryDto) => (
                  <>
                    <BtnStyle id={`${item.id}`} />
                    <BtnLabelStyle
                      htmlFor={`${item.id}`}
                      onClick={() => {
                        setFilterOption(item.id);
                      }}
                    >
                      {item.name}
                    </BtnLabelStyle>
                  </>
                ))}
              </>
            )}
          </BtnContainerStyle>
        </HeaderStyle>
        <Table<FaqDto>
          headers={faqHeaders.getFaqHeaders()}
          items={faqStore.faqList}
          tableStyle={tableStyle}
        />
        {faqStore.pagination?.totalPages > 0 && (
          <PaginationComponent
            pagination={faqStore.pagination}
            handleClickPageNumber={handleClickPageNumber}
          />
        )}
      </ContainerStyle>
    </>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  marginBottom: 20,
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

const HeaderStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80%;
`;

const BtnContainerStyle = styled.div`
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const BtnStyle = styled.input.attrs({ type: 'radio', name: 'category' })`
  appearance: none;
  &:checked + label {
    background: #144da8;
    color: white;
  }
`;

const BtnLabelStyle = styled.label`
  color: #999999;
  border-radius: 25px;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GrayBtnContainerStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const GrayBtnStyle = styled.div`
  padding: 10px 20px;
  background: #5d687a;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  text-align: center;
  margin: 0 5px 20px 5px;
`;

export default ListPage;
