import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { Button, Pagination } from '@mui/material';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import { MemberHeaders } from 'features/memberManagement/settings/definition';

import { Table } from 'controls/table';
import { ItemDto } from 'services/data-contracts';
import NumberFormat from 'react-number-format';

const MarketItemsPage = observer(() => {
  const { itemStore, uiStore } = useStore();
  const memberHeaders: MemberHeaders = new MemberHeaders();

  const handleChange = (e: any, value: number) => {
    itemStore.getItems({ page: value - 1 });
  };

  const ModalChildren = ({ item }: { item: any }) => {
    return (
      <div style={{ padding: 40 }}>
        <div style={{ fontSize: 20, fontWeight: 600, marginBottom: 30 }}>{item?.name}</div>
        <div style={{ fontSize: 14, fontWeight: 400 }}>판매자 : {item?.store?.name}</div>
        <div style={{ fontSize: 14, fontWeight: 400, margin: '20px 0' }}>
          판매가격 :{' '}
          <NumberFormat displayType="text" value={item?.price} thousandSeparator suffix="원" />
        </div>
        <div style={{ fontSize: 14, fontWeight: 400, margin: '20px 0' }}>
          판매 상태 : {item?.status}
        </div>
        <div style={{ fontSize: 14, fontWeight: 400, margin: '20px 0' }}>
          <>
            {item?.images?.map((image: any) => (
              <>
                <img src={image?.publicUrl} style={{ width: '100%' }} alt="" />
              </>
            ))}
          </>
        </div>
        <div>
          <Button
            onClick={() => {
              uiStore.modal.close();
            }}
          >
            닫기
          </Button>
        </div>
      </div>
    );
  };

  const handleClickModal = async (item: any) => {
    const result = await itemStore.getItem(item.id as number);
    uiStore.modal.show({ children: <ModalChildren item={result} /> });
  };

  useEffect(() => {
    itemStore.getItems();
  }, []);

  return (
    <div style={{ padding: 20, overflow: 'scroll', height: '100%' }}>
      <div style={{ padding: '20px 0' }}>
        <Pagination count={20} onChange={handleChange} />
      </div>
      <Table<ItemDto>
        onClick={(item: any) => {
          handleClickModal(item);
        }}
        headers={memberHeaders.getItemStyle()}
        items={itemStore.items}
        tableStyle={tableStyle}
      />
      <Pagination count={20} onChange={handleChange} />
    </div>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  fontSize: 14,
  marginBottom: 20,
  width: '100%',
};

export default MarketItemsPage;
