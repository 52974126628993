/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TokenDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Admin<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 로그아웃
   *
   * @tags admin
   * @name Logout
   * @summary 로그아웃
   * @request POST:/v1/logout
   * @secure
   */
  logout = (query: { adminId: number }, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/logout`,
      method: 'POST',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 로그인
   *
   * @tags admin
   * @name Login
   * @summary 로그인
   * @request POST:/v1/login
   * @secure
   */
  login = (query: { adminEmailId: string; password: string }, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/login`,
      method: 'POST',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 로그인 토큰 발급(for test)
   *
   * @tags admin
   * @name GetAdminToken
   * @summary 로그인 토큰 발급(for test)
   * @request POST:/v1/admins/{adminEmailId}/token
   * @secure
   */
  getAdminToken = (adminEmailId: string, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/admins/${adminEmailId}/token`,
      method: 'POST',
      secure: true,
      ...params,
    });
}
