import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';
import ChartContainer from '../Container/ChartContainer';

type ConuntType = {
  name: string;
  img: string;
  number: number;
};

const MemberPage = observer(() => {
  const { userStatisticsStore } = useStore();

  const countMenu: Array<ConuntType> = [
    {
      name: '가입자수',
      img: '/images/userPlus.svg',
      number: userStatisticsStore.userNumber?.totalUserCount,
    },
    {
      name: '탈퇴계정',
      img: '/images/userMinus.svg',
      number: userStatisticsStore.userNumber?.resignUserCount,
    },
    {
      name: '휴면계정',
      img: '/images/sleep.svg',
      number: userStatisticsStore.userNumber?.inActiveUserCount,
    },
  ];

  useEffect(() => {
    userStatisticsStore.getJoinUser();
  }, []);

  return (
    <>
      <HeaderTitleStyle title="차트" />
      <ContainerStyle>
        <SectionStyle>
          {countMenu.map((item: ConuntType) => (
            <>
              <BoxContainerStyle>
                <ImgStyle src={item.img} />
                <div>
                  <TextStyle>{item.name}</TextStyle>
                  <NumberStyle>{item.number}</NumberStyle>
                </div>
              </BoxContainerStyle>
            </>
          ))}
        </SectionStyle>
        <SectionStyle>
          <ChartContainer type="month" />
          <ChartContainer type="year" />
        </SectionStyle>
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  padding-left: 60px;
  max-width: 1100px;
`;

const BoxContainerStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  width: 30%;
  margin: 0 10px;
  padding: 20px;
  &:first-child {
    margin-left: 0;
  }
`;

const TextStyle = styled.div`
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 5px;
`;

const SectionStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const NumberStyle = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #144da8;
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  font-weight: 700;
`;

const ImgStyle = styled.img.attrs({ alt: '' })`
  width: 60px;
  height: 60px;
  margin-right: 20px;
`;

export default MemberPage;
