import { RoutePropInterface } from 'types/CommonTypes';
import * as Pages from 'pages';
import MarketItemsPage from 'features/items/MarketItemsPage';
import PropertyItemsPage from 'features/items/PropertyItemsPage';

const mainRoutes: RoutePropInterface[] = [
  {
    path: '/',
    title: 'livelink',
    element: Pages.HomePage,
  },
  {
    path: '/communities/posts',
    title: 'posts',
    element: Pages.PostManagerPage,
  },
  {
    path: '/communities/:communityid/posts',
    title: 'posts',
    element: Pages.PostManagementPage.PostListPage,
  },
  {
    path: '/communities/posts/form',
    title: 'articles',
    element: Pages.PostFormPage,
  },
  {
    path: '/communities/:communityid/posts/:id/form',
    title: 'articles',
    element: Pages.PostFormPage,
  },
  {
    path: '/communities/:id/posts/create',
    title: 'articles',
    element: Pages.PostCreatePage,
  },
  {
    path: '/community',
    title: 'community',
    element: Pages.CommunityManagementPage.ListPage,
  },
  {
    path: '/community/:id/:type',
    title: 'community',
    element: Pages.CommunityManagementPage.ModifyPage,
  },
  {
    path: '/community/create',
    title: 'community',
    element: Pages.CommunityManagementPage.ModifyPage,
  },
  {
    path: '/settlement/list',
    title: 'settlement',
    element: Pages.SettlementPage.ListPage,
  },
  {
    path: '/service/faq',
    title: 'faq list',
    element: Pages.CustomerServicePage.ListPage,
  },
  {
    path: '/faq/create',
    title: 'faq create',
    element: Pages.CustomerServicePage.ModifyPage,
  },
  {
    path: '/faq/:id/:type',
    title: 'faq modify',
    element: Pages.CustomerServicePage.ModifyPage,
  },
  {
    path: '/service/qna',
    title: 'qna',
    element: Pages.CustomerServicePage.QnaListPage,
  },
  {
    path: '/qna/:id/answer',
    title: 'qna answer',
    element: Pages.CustomerServicePage.QnaAnswerPage,
  },
  {
    path: '/member/normal',
    title: 'member',
    element: Pages.MemberManagementPage.ListPage,
  },
  {
    path: '/banner',
    title: 'banner',
    element: Pages.BannerManagementPage.ListPage,
  },
  {
    path: '/banner/create',
    title: 'banner',
    element: Pages.BannerManagementPage.CreatePage,
  },
  {
    path: '/banner/:id/detail',
    title: 'banner',
    element: Pages.BannerManagementPage.DetailPage,
  },
  {
    path: '/login',
    title: '로그인',
    authenticated: false,
    element: Pages.AdminLoginPage,
  },
  {
    path: '/link',
    title: '링크 목록 조회',
    authenticated: false,
    element: Pages.LinkManagementPage.ListPage,
  },
  {
    path: '/link/category',
    title: '링크 카테고리',
    authenticated: false,
    element: Pages.LinkManagementPage.CategoryPage,
  },
  {
    path: '/member',
    title: '회원 관리 차트',
    authenticated: false,
    element: Pages.MemberManagementPage.MemberPage,
  },
  {
    path: '/dashBoard',
    title: '대시보드',
    authenticated: false,
    element: Pages.BoardPage.BoardPage,
  },
  {
    path: '/items/market',
    title: '대시보드',
    authenticated: false,
    element: MarketItemsPage,
  },
  {
    path: '/items/property',
    title: '대시보드',
    authenticated: false,
    element: PropertyItemsPage,
  },
];

export default mainRoutes;
