import { COLUMN_TYPES } from 'controls/table/ColumnTypes';
import React, { CSSProperties } from 'react';

interface HeaderType {
  title: string;
  type: COLUMN_TYPES;
  key: string;
  style: React.CSSProperties;
  contentStyle: React.CSSProperties;
}

const thStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  color: '#8B8F9A',
  fontWeight: 500,
  textAlign: 'center',
};

const tdStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  textAlign: 'center',
  maxWidth: 400,
};

type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';

const MemberTypeConvertor = (props: any) => {
  return <div>사업자</div>;
};

const BusinessAuthConvertor = (props: any) => {
  return <div>미인증</div>;
};

const StatusConvertor = (props: any) => {
  return <div>정지</div>;
};

const BlackListConvertor = (props: any) => {
  return <div>비대상</div>;
};

const BlockConvertor = (props: any) => {
  return <div>-</div>;
};

class MemberHeaders {
  getMemberHeaders() {
    return [
      {
        title: '',
        type: COLUMN_TYPES.CHECKBOX,
        style: { ...thStyle, width: 30 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '이름',
        key: 'name',
        style: { ...thStyle, width: 100 },
        contentStyle: { ...tdStyle },
      },
      { title: '닉네임', key: '', style: { ...thStyle }, contentStyle: { ...tdStyle } },
      { title: '상점명', key: '', style: { ...thStyle }, contentStyle: { ...tdStyle } },
      {
        title: '회원구분',
        type: COLUMN_TYPES.CUSTOM,
        template: <MemberTypeConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '사업자구분(인증)',
        type: COLUMN_TYPES.CUSTOM,
        template: <BusinessAuthConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '상태(3개월)',
        type: COLUMN_TYPES.CUSTOM,
        template: <StatusConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '가입일',
        key: '',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '최근접속일',
        key: '',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY.MM.DD HH:mm' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      { title: '(누적)신고건', key: '', style: { ...thStyle }, contentStyle: { ...tdStyle } },
      {
        title: '블랙리스트 대상여부',
        type: COLUMN_TYPES.CUSTOM,
        template: <BlackListConvertor />,
        style: { ...thStyle, width: 70 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '제재여부',
        type: COLUMN_TYPES.CUSTOM,
        template: <BlockConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
    ];
  }

  getItemStyle() {
    return [
      {
        title: '',
        type: COLUMN_TYPES.CHECKBOX,
        style: { ...thStyle, width: 30 },
        contentStyle: { ...thStyle },
      },
      {
        type: COLUMN_TYPES.MODAL,
        title: '이름',
        key: 'name',
        style: { ...thStyle, width: '50%' },
        contentStyle: { thStyle, textAlign: 'left' as TextAlign },
      },
      {
        type: COLUMN_TYPES.CURRENCY,
        title: '판매가격',
        key: 'price',
        style: { ...thStyle, width: '100px' },
        contentStyle: { thStyle, textAlign: 'left' as TextAlign },
      },
      {
        title: '판매상태',
        key: 'status',
        style: { ...thStyle, width: '100px' },
        contentStyle: { thStyle },
      },
      {
        title: '상점명',
        key: 'store.name',
        style: { ...thStyle, width: '100px' },
        contentStyle: { thStyle },
      },
      {
        type: COLUMN_TYPES.DATETIME,
        title: '등록일',
        key: 'createDate',
        style: { ...thStyle, width: '100' },
        contentStyle: { thStyle },
      },
    ];
  }

  getPropertyColumns() {
    return [
      {
        title: '',
        type: COLUMN_TYPES.CHECKBOX,
        style: { ...thStyle, width: 30 },
        contentStyle: { ...thStyle },
      },
      {
        type: COLUMN_TYPES.MODAL,
        title: '이름',
        key: 'name',
        style: { ...thStyle, width: '50%' },
        contentStyle: { thStyle, textAlign: 'left' as TextAlign },
      },
      {
        type: COLUMN_TYPES.CURRENCY,
        title: '계약방식',
        key: 'contractType',
        style: { ...thStyle, width: '100px' },
        contentStyle: { thStyle, textAlign: 'left' as TextAlign },
      },
      {
        title: '판매상태',
        key: 'status',
        style: { ...thStyle, width: '100px' },
        contentStyle: { thStyle },
      },
      {
        title: '상점명',
        key: 'store.name',
        style: { ...thStyle, width: '100px' },
        contentStyle: { thStyle },
      },
      {
        type: COLUMN_TYPES.DATETIME,
        title: '등록일',
        key: 'createDate',
        style: { ...thStyle, width: '100' },
        contentStyle: { thStyle },
      },
    ];
  }
}

export { MemberHeaders };
