import { makeObservable, observable } from 'mobx';
import { CommunityPost as CommunityPostService } from 'services/CommunityPost';
import { CommunityBoardDto, CommunityDto, PostDto } from 'services/data-contracts';
import { REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from './ServiceStoreBase';

class CommunityPostStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      communities: observable,
      posts: observable,
      post: observable,
      boards: observable,
      postError: observable,
    });
  }

  api: CommunityPostService<unknown> = new CommunityPostService({
    baseURL: REST_API_URL,
  });

  communities: Array<CommunityDto>;

  posts: Array<PostDto>;

  post: PostDto;

  postError: any;

  boards: Array<CommunityBoardDto>;

  *getItem(postId: number) {
    const { response, data, error } = yield this.api.getPost(postId);
    if (response.status === 200) {
      this.post = data;
    } else {
      this.postError = error.data;
    }
  }

  *createItem(post: PostDto) {
    const { response } = yield this.api.createPost(post);
    return super.handleByStatus(response, { successMessage: '정상적으로 생성하였습니다.' });
  }

  *updateItem(postId: number, post: PostDto) {
    const { response } = yield this.api.updatePost(postId, post);
    return super.handleByStatus(response, { successMessage: '정상적으로 수정하였습니다.' });
  }

  *deleteItem(postId: number) {
    const { response } = yield this.api.deletePost(postId);
    return super.handleByStatus(response, { successMessage: '정상적으로 삭제하였습니다.' });
  }
}

export default CommunityPostStore;
