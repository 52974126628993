import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';

const Column = ({
  title,
  containerStyle,
  childrenStyle,
  children,
}: {
  title?: JSX.Element[] | string;
  containerStyle?: CSSProperties;
  childrenStyle?: CSSProperties;
  children?: JSX.Element | JSX.Element[] | boolean;
}) => {
  const styles = { ...containerStyle };
  const childStyles = { ...childrenStyle };
  return (
    <ContainerStyle style={styles}>
      {typeof title !== 'undefined' && <TitleStyle>{title}</TitleStyle>}
      {typeof children !== 'undefined' && <ChildStyle style={childStyles}>{children}</ChildStyle>}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  margin: 0 10px;
`;

const TitleStyle = styled.div`
  margin-bottom: 10px;
  height: 100%;
  height: 20px;
  align-content: flex-start;
  vertical-align: top;
`;

const ChildStyle = styled.div`
  text-align: center;
`;

export default Column;
