import { makeObservable, flow, observable } from 'mobx';
import { Faq as FaqServices } from 'adminServices/Faq';
import { ADMIN_REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from 'stores/ServiceStoreBase';
import { FaqDto } from 'adminServices/data-contracts';

class FaqStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      faqList: observable,
      getFaqs: flow,
      getFaq: flow,
      getCategories: flow,
      createFaq: flow,
      updateFaq: flow,
      deleteFaq: flow,
      // clearFaqs: flow,
    });
  }

  api: FaqServices<unknown> = new FaqServices({
    baseURL: ADMIN_REST_API_URL,
  });

  faqList: any;
  faqDetail: any;
  faqCategory: any;
  pagination: any;
  updateStatus: any;
  deleteStatus: any;

  *getFaqs(query?: { page?: number; categoryId?: number }) {
    const { response, headers } = yield this.api.getFaqs({ ...query, size: 7 });
    this.faqList = response.data;
    this.pagination = JSON.parse(headers['x-pagination']);
    return super.handleByStatus(response);
  }

  *getFaq(id: number) {
    const { response } = yield this.api.getFaq(id);
    this.faqDetail = response.data;
    return super.handleByStatus(response);
  }

  *getCategories() {
    const { response } = yield this.api.getCategories1();
    this.faqCategory = response.data;
    return super.handleByStatus(response);
  }

  *createFaq(data: FaqDto) {
    const { response } = yield this.api.createFaq(data);
    return super.handleByStatus(response);
  }

  *updateFaq(id: number, data: FaqDto) {
    const { response } = yield this.api.updateFaq(id, data);
    this.updateStatus = response.status;
    return super.handleByStatus(response);
  }

  *deleteFaq(id: number) {
    const { response } = yield this.api.deleteFaq(id);
    this.deleteStatus = response.status;
    return super.handleByStatus(response);
  }

  clearFaqs() {
    this.faqList = undefined;
  }
}

export default FaqStore;
