import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PostHeaders } from 'features/communityManagement/settings/definitions';
import { observer } from 'mobx-react';
import { Input } from 'components/commons';
import { PostDto } from 'adminServices/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { Modal } from '@mui/material';
import { Table } from 'controls/table';
import styled from 'styled-components';
import PaginationComponent from 'components/commons/PaginationComponent';
import PostTable from './PostTable';

type Props = {
  handleSelectPostId: (id: string) => void;
  handleModal: (open: boolean) => void;
};

const PostSearch = observer(({ handleSelectPostId, handleModal }: Props) => {
  const navigate = useNavigate();
  const { communityPostStore } = useStore();
  const [searchParams] = useSearchParams();
  const pageNo = useRef<number>(0);
  const postHeaders: PostHeaders = new PostHeaders();
  const [model, setModel] = useState<PostDto>();

  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    navigate(`/banner/create?page=${pageNumber - 1}`);
  };

  const handleSearch = async () => {
    await communityPostStore.getPosts({
      searchKeyword: model?.title,
      // categoryId: model?.community?.id,
    });
  };

  const handleClickSelect = (id: string) => {
    if (typeof id !== 'undefined') {
      handleSelectPostId(id);
    }
  };

  const getList = async () => {
    pageNo.current = searchParams.get('page') === null ? 0 : Number(searchParams.get('page'));
    if (Number.isNaN(pageNo.current)) {
      pageNo.current = 0;
    }
    await communityPostStore.getPosts({ page: pageNo.current });
  };

  useEffect(() => {
    getList();
  }, [searchParams]);

  return (
    <>
      <ContainerStyle>
        <HeaderStyle>
          번영회 게시글 검색
          <ImgStyle
            src="/images/whiteClose.svg"
            onClick={() => {
              handleModal(false);
            }}
          />
        </HeaderStyle>
        <InputContainerStyle>
          <Input
            label="제목"
            onChangeValue={(value: string) => {
              setModel({ ...model, title: value });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <ImgStyle src="/images/searchPost.svg" onClick={handleSearch} />
        </InputContainerStyle>
        <ItemContainerStyle>
          <TextStyle>검색결과</TextStyle>
          <TabelContainerStyle>
            <Table<PostDto>
              items={communityPostStore.filteredPost}
              headers={postHeaders.getBannerPostHeaders()}
              tableStyle={tableStyle}
            />
          </TabelContainerStyle>
          <PostTable />
          {typeof communityPostStore.pagination !== 'undefined' && (
            <div style={{ width: 350, margin: '0 auto', paddingBottom: 30 }}>
              <PaginationComponent
                pagination={communityPostStore.pagination}
                handleClickPageNumber={handleClickPageNumber}
              />
            </div>
          )}
        </ItemContainerStyle>
      </ContainerStyle>
    </>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  fontSize: 14,
  marginBottom: 20,
};

const linkStyle: CSSProperties = {
  color: '#144da8',
  textDecoration: 'underline',
  textUnderlinePosition: 'under',
  cursor: 'pointer',
};

const TabelContainerStyle = styled.div`
  > table {
    margin: 0 auto;
    width: 90%;
  }
`;

const HeaderStyle = styled.div`
  background-color: #144da8;
  padding: 15px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContainerStyle = styled.div`
  background-color: white;
  width: 80%;
  margin: auto;
  position: relative;
  top: 10%;
`;

const ImgStyle = styled.img.attrs({ alt: '' })`
  cursor: pointer;
`;

const TableStyle = styled.table`
  --border: 1px solid #dadada;
  border: var(--border);
  border-collapse: collapse;
  margin: auto;
  width: 90%;
  > thead > td,
  > tbody > td {
    border: var(--border);
  }
`;

const BtnStyle = styled.div`
  cursor: pointer;
`;

const InputContainerStyle = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 20px;
  padding: 20px 0;
`;

const ItemContainerStyle = styled.div`
  > div:first-child {
    margin-bottom: 10px;
  }
`;

const TextStyle = styled.div`
  padding: 0 30px;
`;

export default PostSearch;
