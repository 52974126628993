import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import CommunityList from '../containers/CommunityList';
import ModelHelper from 'libs/helper/modelHelper';
import { useStore } from 'stores/StoreHelper';
import { CommunityDto } from 'services/data-contracts';
import { Input } from 'components/commons';

const AddPage = observer(() => {
  const navigate = useNavigate();
  const { communityStore } = useStore();
  const [newCommunity, setNewCommunity] = useState<CommunityDto>();
  const modelHelper = new ModelHelper<CommunityDto>(newCommunity, communityStore);

  const handleClickAdd = async () => {
    const result = await modelHelper.create();
    if (result) {
      navigate('/community');
    }
  };

  return (
    <>
      <ContainerStyle>
        <HeaderStyle>
          <BtnStyle
            onClick={() => {
              handleClickAdd();
            }}
          >
            번영회 등록
          </BtnStyle>
        </HeaderStyle>
        <Input
          required
          label="번영회 이름"
          id="name"
          onChangeValue={(value: string) => {
            setNewCommunity({ ...newCommunity, name: value });
          }}
        />
        <Input
          label="번영회 설명"
          id="description"
          onChangeValue={(value: string) => {
            setNewCommunity({ ...newCommunity, description: value });
          }}
        />
        <InputContainerStyle>
          <LableStyle>비공개</LableStyle>
          <InputStyle onClick={(e) => {}} />
        </InputContainerStyle>
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;

const HeaderStyle = styled.div`
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-end;
`;

const BtnStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  height: 20px;
  border: 1px solid #144da8;
  border-radius: 20px;
  color: #144da8;
  cursor: pointer;
  margin-bottom: 20px;
`;

const InputContainerStyle = styled.div``;

const InputStyle = styled.input.attrs({ type: 'checkbox' })``;

const LableStyle = styled.label``;

export default AddPage;
