import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { View, ModifyForm } from 'controls/view';
import { CommunityDto } from 'services/data-contracts';
import { Row, Column } from 'components/commons/layouts';
import { Button } from '@mui/material';
// import { ViewHeaders } from 'features/communityManagement/settings/definitions';
import ModelHelper from 'libs/helper/modelHelper';

const DetailPage = observer(() => {
  const { communityStore } = useStore();
  const { id } = useParams();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [searchParams] = useSearchParams();
  const [community, setCommunity] = useState<CommunityDto>();
  const [communityId, setCommunityId] = useState<number>(0);
  const [pageMode, setPageMode] = useState<'view' | 'create' | 'modify'>();
  const modelHelper = new ModelHelper<CommunityDto>(community, communityStore);

  const handleClickEdit = () => {
    setPageMode('modify');
    navigate(`/community/${id}/modify`);
  };

  const handleClickDelete = () => {};

  const handleChangeValue = (values: CommunityDto) => {};

  const init = async () => {
    if (typeof id !== 'undefined') {
      await communityStore.getCommunityById(Number(id));
      setCommunity(communityStore.community);
    }
  };

  useEffect(() => {
    const mode = searchParams.get('mode');
    if (mode === null) {
      setPageMode('view');
    }
    init();

    return () => {
      communityStore.unMount();
    };
  }, [id]);

  useEffect(() => {
    setCommunityId(Number(id));
  }, [pathname]);

  return (
    <>
      <ContainerStyle>
        <>
          {/* {pageMode === 'view' ? (
            <View<CommunityDto> item={communityStore.community} headers={ViewHeaders} />
          ) : (
            <ModifyForm<CommunityDto>
              item={communityStore.community}
              headers={ViewHeaders}
              onChangeValue={handleChangeValue}
            />
          )} */}
        </>
        <Row style={{ position: 'absolute', bottom: 0 }}>
          <Column>
            <Button onClick={handleClickEdit}>수정</Button>
          </Column>
          <Column>
            <Button onClick={handleClickDelete}>삭제</Button>
          </Column>
        </Row>
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  height: 100vh;
`;

export default DetailPage;
