/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IdDto, ItemDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Item<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 상품 상세 조회
   *
   * @tags item
   * @name GetItem
   * @summary 상품 상세 조회
   * @request GET:/v1/market/items/{itemId}
   * @secure
   */
  getItem = (itemId: number, params: RequestParams = {}) =>
    this.request<ItemDto, any>({
      path: `/v1/market/items/${itemId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 수정
   *
   * @tags item
   * @name UpdateItem
   * @summary 상품 수정
   * @request PUT:/v1/market/items/{itemId}
   * @secure
   */
  updateItem = (itemId: number, data: ItemDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/items/${itemId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 상품 삭제
   *
   * @tags item
   * @name DeleteItem
   * @summary 상품 삭제
   * @request DELETE:/v1/market/items/{itemId}
   * @secure
   */
  deleteItem = (itemId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/items/${itemId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 상품 카테고리 수정
   *
   * @tags item
   * @name UpdateItemCategory
   * @summary 상품 카테고리 수정
   * @request PUT:/v1/market/items/{itemId}/{categoryId}/{id}
   * @secure
   */
  updateItemCategory = (
    itemId: number,
    categoryId: number,
    id: string,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/market/items/${itemId}/${categoryId}/${id}`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 물물교환 조회시 itemType:"BARTER"<br>[인기순] sort: ["interestCount,desc","sortDate,desc"]<br>[등록순] sort: ["sortDate,desc"]<br>[관심] interestCount<br>[댓글] marketChatRoomCount
   *
   * @tags item
   * @name GetItems
   * @summary 상품 리스트 조회
   * @request GET:/v1/market/items
   * @secure
   */
  getItems = (
    query?: {
      isCategoryNull?: boolean;
      searchKeyword?: string;
      categoryId?: number;
      condition?: 'NEW' | 'USED';
      startDate?: string;
      endDate?: string;
      minPrice?: number;
      maxPrice?: number;
      transactionType?: ('DIRECT' | 'DELIVERY' | 'DIRECT_AND_DELIVERY')[];
      includeShippingFee?: boolean;
      useOffer?: boolean;
      useSafePayment?: boolean;
      regionCode?: string;
      status?: ('ON_SALE' | 'RESERVED' | 'SOLD')[];
      isRefundable?: boolean;
      latitude?: number;
      longitude?: number;
      radius?: number;
      itemType?: string;
      barterType?: 'GOODS' | 'FOOD';
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ItemDto[], any>({
      path: `/v1/market/items`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
