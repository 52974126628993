/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IdDto, PostDto, PostReplyDto, ReportDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class CommunityPost<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 게시글 조회
   *
   * @tags community-post
   * @name GetPost
   * @summary 게시글 조회
   * @request GET:/v1/community-posts/{postId}
   * @secure
   */
  getPost = (postId: number, query?: { replyId?: number }, params: RequestParams = {}) =>
    this.request<PostDto, any>({
      path: `/v1/community-posts/${postId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 수정
   *
   * @tags community-post
   * @name UpdatePost
   * @summary 게시글 수정
   * @request PUT:/v1/community-posts/{postId}
   * @secure
   */
  updatePost = (postId: number, data: PostDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts/${postId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 삭제
   *
   * @tags community-post
   * @name DeletePost
   * @summary 게시글 삭제
   * @request DELETE:/v1/community-posts/{postId}
   * @secure
   */
  deletePost = (postId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/community-posts/${postId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 댓글 수정
   *
   * @tags community-post
   * @name UpdatePostReply
   * @summary 댓글 수정
   * @request PUT:/v1/community-posts/{postId}/replies/{replyId}
   * @secure
   */
  updatePostReply = (
    postId: number,
    replyId: number,
    data: PostReplyDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts/${postId}/replies/${replyId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 댓글 삭제
   *
   * @tags community-post
   * @name DeletePostReply
   * @summary 댓글 삭제
   * @request DELETE:/v1/community-posts/{postId}/replies/{replyId}
   * @secure
   */
  deletePostReply = (postId: number, replyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/community-posts/${postId}/replies/${replyId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 게시글 리스트 조회
   *
   * @tags community-post
   * @name GetPosts
   * @summary 게시글 리스트 조회
   * @request GET:/v1/community-posts
   * @secure
   */
  getPosts = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      regionCode?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/community-posts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 생성
   *
   * @tags community-post
   * @name CreatePost
   * @summary 게시글 생성
   * @request POST:/v1/community-posts
   * @secure
   */
  createPost = (data: PostDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 신고
   *
   * @tags community-post
   * @name ReportPost
   * @summary 게시글 신고
   * @request POST:/v1/community-posts/{postId}/report
   * @secure
   */
  reportPost = (postId: number, data: ReportDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts/${postId}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 댓글 리스트 조회
   *
   * @tags community-post
   * @name GetPostReplies
   * @summary 게시글 댓글 리스트 조회
   * @request GET:/v1/community-posts/{postId}/replies
   * @secure
   */
  getPostReplies = (
    postId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostReplyDto[], any>({
      path: `/v1/community-posts/${postId}/replies`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 댓글 생성
   *
   * @tags community-post
   * @name CreatePostReply
   * @summary 댓글 생성
   * @request POST:/v1/community-posts/{postId}/replies
   * @secure
   */
  createPostReply = (postId: number, data: PostReplyDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts/${postId}/replies`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 댓글 신고
   *
   * @tags community-post
   * @name ReportPostReply
   * @summary 게시글 댓글 신고
   * @request POST:/v1/community-posts/{postId}/replies/{replyId}/report
   * @secure
   */
  reportPostReply = (
    postId: number,
    replyId: number,
    data: ReportDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts/${postId}/replies/${replyId}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 댓글 좋아요
   *
   * @tags community-post
   * @name LikePostReply
   * @summary 댓글 좋아요
   * @request POST:/v1/community-posts/{postId}/replies/{replyId}/like/{likeFlag}
   * @secure
   */
  likePostReply = (
    postId: number,
    replyId: number,
    likeFlag: boolean,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/community-posts/${postId}/replies/${replyId}/like/${likeFlag}`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 게시글 좋아요
   *
   * @tags community-post
   * @name LikePost
   * @summary 게시글 좋아요
   * @request POST:/v1/community-posts/{postId}/like/{likeFlag}
   * @secure
   */
  likePost = (postId: number, likeFlag: boolean, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/community-posts/${postId}/like/${likeFlag}`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 게시글 숨기기
   *
   * @tags community-post
   * @name HidePost
   * @summary 게시글 숨기기
   * @request POST:/v1/community-posts/{postId}/hide
   * @secure
   */
  hidePost = (postId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/community-posts/${postId}/hide`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 새글 피드 조회
   *
   * @tags community-post
   * @name GetNewPosts
   * @summary 새글 피드 조회
   * @request GET:/v1/community-posts/new
   * @secure
   */
  getNewPosts = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/community-posts/new`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 게시글 조회
   *
   * @tags community-post
   * @name GetMyPosts
   * @summary 내 게시글 조회
   * @request GET:/v1/community-posts/mine
   * @secure
   */
  getMyPosts = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/community-posts/mine`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 좋아요한 게시글 조회
   *
   * @tags community-post
   * @name GetLikePosts
   * @summary 좋아요한 게시글 조회
   * @request GET:/v1/community-posts/like
   * @secure
   */
  getLikePosts = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/community-posts/like`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 핫이슈 게시글 조회
   *
   * @tags community-post
   * @name GetHotIssuePosts
   * @summary 핫이슈 게시글 조회
   * @request GET:/v1/community-posts/hot-issue
   * @secure
   */
  getHotIssuePosts = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/community-posts/hot-issue`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
