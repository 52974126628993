import AuthStore from 'stores/AuthStore';
import FileStore from 'stores/FileStore';
import UserStore from 'stores/UserStore';
import FormValidationStore from './FormValidationStore';
import UIStore from './UIStore';
import OrderStore from './OrderStore';
import ParameterStore from './ParameterStore';
import AdminStore from 'adminStores/AdminStore';
import QnaStore from 'adminStores/QnaStore';
import FaqStore from 'adminStores/FaqStore';
import CommunityPostStore from 'adminStores/CommunityPostStore';
import CommunityStore from 'adminStores/CommunityStore';
import BannerStore from 'adminStores/BannerStore';
import LinkStore from 'adminStores/LinkStore';
import UserStatisticsStore from 'adminStores/UserStatisticsStore';
import { Item } from 'adminServices/Item';
import ItemStore from './MarketStore';
import PropertyStore from './PropertyStore';

const RootStore = {
  authStore: new AuthStore(),
  fileStore: new FileStore(),
  userStore: new UserStore(),
  formValidationStore: new FormValidationStore(),
  uiStore: new UIStore(),
  orderStore: new OrderStore(),
  parameterStore: new ParameterStore(),
  adminStore: new AdminStore(),
  qnaStore: new QnaStore(),
  faqStore: new FaqStore(),
  communityStore: new CommunityStore(),
  communityPostStore: new CommunityPostStore(),
  bannerStore: new BannerStore(),
  linkStore: new LinkStore(),
  userStatisticsStore: new UserStatisticsStore(),
  itemStore: new ItemStore(),
  propertyStore: new PropertyStore(),
};

export default RootStore;
