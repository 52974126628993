/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from './http-client';

export class UserStatistics<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 가입자, 탈퇴, 휴면 유저 수 조회
   *
   * @tags User Statistics
   * @name GetJoinUser
   * @summary 가입자, 탈퇴, 휴면 유저 수 조회
   * @request GET:/v1/user-statistics
   * @secure
   */
  getJoinUser = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/user-statistics`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 월간 가입자 수
   *
   * @tags User Statistics
   * @name GetUserMonthly
   * @summary 월간 가입자 수
   * @request GET:/v1/user-statistics/registered-monthly
   * @secure
   */
  getUserMonthly = (query: { yearMonth: string }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/user-statistics/registered-monthly`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 년간 가입자 수
   *
   * @tags User Statistics
   * @name GetUserYear
   * @summary 년간 가입자 수
   * @request GET:/v1/user-statistics/registered-annual
   * @secure
   */
  getUserYear = (query: { year: string }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/user-statistics/registered-annual`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
