import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import styled from 'styled-components';
import CategoryEditor from '../Containers/CategoryEditor';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

const CategoryPage = observer(() => {
  const { linkStore } = useStore();
  const [model, setModel] = useState<any>();

  const init = async () => {
    await linkStore.getLinkCategories();
  };

  const handleCategory = (newModel: any) => {
    setModel({ ...model, newModel });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <HeaderTitleStyle title="카테고리" />
      <ContainerStyle>
        <CategoryEditor type="Edit" handleCategory={handleCategory} />
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div``;

export default CategoryPage;
