import React, { CSSProperties, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { Table } from 'controls/table';
import { UserDto } from 'services/data-contracts';
import { MemberHeaders } from '../settings/definition';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PaginationComponent from 'components/commons/PaginationComponent';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

type FilterType = {
  title: string;
};

const FilterList: Array<FilterType> = [
  { title: '탈퇴' },
  { title: '사업자인증' },
  { title: '정지(제재)' },
  { title: '휴면' },
  { title: '테스트' },
  { title: '관리자' },
  { title: '전체' },
];

const ListPage = observer(() => {
  const navigate = useNavigate();
  const pageNo = useRef<number>(0);
  const memberHeaders: MemberHeaders = new MemberHeaders();
  const [searchParams] = useSearchParams();
  const { userStore, uiStore } = useStore();

  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    navigate(`/member?page=${pageNumber - 1}`);
  };

  const handleClickFilter = () => {
    // 필터링
  };

  const handleDeleteUser = () => {
    uiStore.confirm.show({
      title: '정말로 삭제하시겠습니까?',
    });
  };

  const init = () => {
    pageNo.current = searchParams.get('page') === null ? 0 : Number(searchParams.get('page'));
    userStore.getUsers({ page: pageNo.current });
  };

  useEffect(() => {
    init();
    return () => {
      userStore.clearUserList();
    };
  }, [searchParams]);

  return (
    <>
      <HeaderTitleStyle title="회원통합관리" />
      <ContainerStyle>
        <HeaderStyle>
          <div>
            <BtnStyle color="#144da8" backgroundColor="white" border>
              회원이름으로 검색하기
            </BtnStyle>
            <BtnStyle backgroundColor="#88A3CE">다운로드(xls)</BtnStyle>
            <BtnStyle onClick={handleDeleteUser}>삭제하기</BtnStyle>
          </div>
        </HeaderStyle>
        <HeaderStyle>
          <div>
            {FilterList.map((item: FilterType) => (
              <>
                <CheckboxStyle name="filter" onClick={handleClickFilter} />
                <LabelStyle>{item.title}</LabelStyle>
              </>
            ))}
          </div>
        </HeaderStyle>
        <Table<UserDto>
          headers={memberHeaders.getMemberHeaders()}
          items={userStore.allUserList}
          tableStyle={tableStyle}
        />
        {userStore.pagination?.totalPages > 0 && (
          <PaginationComponent
            pagination={userStore.pagination}
            handleClickPageNumber={handleClickPageNumber}
          />
        )}
      </ContainerStyle>
    </>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  fontSize: 14,
  marginBottom: 20,
};

const ContainerStyle = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

const HeaderStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  > div {
    display: flex;
    align-items: center;
  }
`;

const CheckboxStyle = styled.input.attrs({ type: 'checkbox' })`
  margin-left: 10px;
`;

const LabelStyle = styled.label`
  margin-right: 10px;
  color: black;
  font-size: 14px;
`;

const BtnStyle = styled.div<{ color?: string; backgroundColor?: string; border?: boolean }>`
  cursor: pointer;
  border-radius: 25px;
  padding: 10px 20px;
  margin: 0 5px;
  color: ${(props) => props.color || 'white'};
  background-color: ${(props) => props.backgroundColor || '#5D687A'};
  font-size: 14px;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.color};
    `}
`;

export default ListPage;
