import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { LinkCategoryDto } from 'adminServices/data-contracts';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { Modal } from '@mui/material';
import { Input } from 'components/commons';
import styled, { css } from 'styled-components';
import CategoryItemContainer from './CategoryItemCotainer';

type CategoryType = {
  id: number;
  name: string;
  childCategory?: Array<CategoryType>;
};

type Props = {
  handleCategoryModal?: (open: boolean) => void;
  handleCategory?: (newMode: any) => void;
  type?: 'Edit' | 'Create';
};

const CategoryEditor = observer(({ handleCategory, handleCategoryModal, type }: Props) => {
  const { linkStore } = useStore();

  const inputRef = useRef<HTMLInputElement>(null);
  const [id, setId] = useState<number>();
  const [model, setModel] = useState<any>();
  const [editId, setEditId] = useState<number>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [categoryValue, setCategoryValue] = useState<string>('');
  const [mainCategory, setMainCategory] = useState<string>('');
  const [childCategory, setChildCategory] = useState<Array<CategoryType>>();
  const [newChildCategory, setNewChildCategory] = useState<CategoryType>();
  const [categoryItem, setCategoryItem] = useState<Array<LinkCategoryDto>>();

  const handleClickAddCategory = () => {
    // 카테고리 추가
  };

  const handleEditCategory = () => {
    // 카테고리 수정
  };

  const handleDelete = async () => {
    // 카테고리 참조 링크 , 참조 서브 카테고리 조회 모두 false여야 삭제 가능
    if (typeof id !== 'undefined') {
      await linkStore.existLinkByLinkCategory(id);
      if (linkStore.existLink) {
        toast('삭제가 불가합니다.', { type: 'error' });
      } else {
        await linkStore.deleteLinkCategory(id);
        if (linkStore.deleteCategoryResult.status === 200) {
          toast('삭제가 완료되었습니다.', { type: 'success' });
          setTimeout(() => {
            window.location.href = '/link';
          }, 900);
        }
      }
    }
  };

  const handleMainCategory = (category: string) => {
    setMainCategory(category);
  };

  const init = async () => {
    await linkStore.getLinkCategories();
    if (typeof linkStore.categoryList !== 'undefined') {
      setCategoryItem(linkStore.categoryList);
    }
  };

  useEffect(() => {
    setChildCategory(
      linkStore?.categoryList?.find((item: LinkCategoryDto) => item.id === 1)?.subCategory,
    );
    if (typeof model?.linkCategory !== 'undefined') {
      //
    }
  }, [model?.linkCategory]);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [editMode]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal open={openModal}>
        <DeleteModalCotnainerStyle>
          <ModalTitleStyle>삭제하시겠습니까?</ModalTitleStyle>
          <div style={{ color: '#666666' }}>삭제 후에는 복원할 수 없습니다.</div>
          <div>
            <ModalBtnStyle
              backgroundColor="#F6F6F6"
              color="black"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              취소
            </ModalBtnStyle>
            <ModalBtnStyle onClick={handleDelete}>삭제</ModalBtnStyle>
          </div>
        </DeleteModalCotnainerStyle>
      </Modal>
      <ContainerStyle>
        <RowStyle flexDirection="column" style={{ height: '100%' }}>
          <CategoryContainerStyle>
            <CategorySectionStyle border={true}>
              <div>대분류</div>
              {typeof linkStore.categoryList !== 'undefined' && (
                <CategoryItemContainer
                  item={categoryItem}
                  type="Edit"
                  handleMainCategory={handleMainCategory}
                />
              )}
            </CategorySectionStyle>
            <ImgStyle src="/images/arrow-right.svg" />
            <CategorySectionStyle>
              <div>소분류</div>
              <div>
                <CategoryInputContainerStyle>
                  <Input
                    style={{ ...categoryInputStyle, width: '350px' }}
                    onChangeValue={(value: string) => {
                      setCategoryValue(value);
                      setNewChildCategory({ ...newChildCategory, id: model?.id, name: value });
                    }}
                    value={categoryValue}
                    placeholder="카테고리명"
                  />
                  <InputBtnStyle onClick={handleClickAddCategory}>추가</InputBtnStyle>
                </CategoryInputContainerStyle>
              </div>
              {mainCategory !== '' ? (
                <>
                  {typeof linkStore.categoryList !== 'undefined' &&
                    linkStore?.categoryList
                      ?.find((item: any) => item.name === mainCategory)
                      ?.subCategory?.map((item: any) => (
                        <>
                          <Category2Style
                            id={item.name}
                            onClick={() => {
                              setModel({ ...model, childCategory: item.name });
                            }}
                          />
                          <CategoryLabelStyle htmlFor={item.name}>
                            {/* {item.name} */}
                            {editMode && item.id === editId ? (
                              <>
                                <EditInputStyle
                                  ref={inputRef}
                                  defaultValue={item.name}
                                  onChange={(e: any) => {
                                    //
                                  }}
                                  onClick={() => {
                                    //
                                  }}
                                />
                              </>
                            ) : (
                              <>{item.name}</>
                            )}
                            <div>
                              <InputStyle id={item.name + 'category'} />
                              <LabelStyle
                                htmlFor={item.name + 'category'}
                                onClick={() => {
                                  //
                                }}
                              >
                                <>
                                  <div
                                    onClick={() => {
                                      setEditMode(true);
                                      setEditId(item.id);
                                    }}
                                  >
                                    이름 수정
                                  </div>
                                  <div
                                    onClick={() => {
                                      // handleDeleteCategory(item.id);
                                      setId(item.id);
                                      setOpenModal(true);
                                    }}
                                  >
                                    삭제
                                  </div>
                                </>
                              </LabelStyle>
                            </div>
                          </CategoryLabelStyle>
                        </>
                      ))}
                </>
              ) : (
                <TextStyle color="#999999" style={{ textAlign: 'center', marginTop: 45 }}>
                  대분류를 먼저 선택하세요
                </TextStyle>
              )}

              {typeof handleCategoryModal !== 'undefined' && (
                <div
                  onClick={() => {
                    handleCategoryModal(false);
                  }}
                >
                  완료
                </div>
              )}
            </CategorySectionStyle>
          </CategoryContainerStyle>
        </RowStyle>
      </ContainerStyle>
    </>
  );
});

const categoryInputStyle: CSSProperties = {
  height: 30,
  fontSize: 14,
  background: 'white',
  border: '1px solid #eeeeee',
  borderRadius: 30,
  margin: '5px 20px 0 0',
};

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

const ContainerStyle = styled.div``;

const RowStyle = styled.div<{ flexDirection?: string; height?: string }>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  height: ${(props) => props.height || '100px'};
`;

const CategoryContainerStyle = styled.div`
  display: flex;
  padding: 20px;
  width: 92%;
  margin: 20px auto 0 60px;
  border: 1px solid #eeeeee;
  background-color: white;
  border-radius: 10px;
  max-width: 900px;
  min-height: 400px;
  > div {
    margin: 10px 0;
  }
`;

const CategorySectionStyle = styled.div<{ border?: boolean }>`
  ${flexColumn}
  width: 50%;
  > div {
    ${flexColumn}
  }
  > div:first-child {
    margin: 0 0 20px 20px;
  }
  ${(props) =>
    props.border &&
    css`
      border-right: 1px solid #eeeeee;
    `}
`;

const CategoryBtnStyle = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  &:checked + label {
    background-color: #3e64ea;
    color: white;
    > img {
      filter: unset;
    }
    > div > input {
      filter: unset;
    }
  }
`;

const CategoryLabelStyle = styled.label`
  cursor: pointer;
  background-color: #f6f6f6;
  color: #666666;
  padding: 10px 10px 10px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 20px;
  max-width: 350px;
  margin: 5px auto;
  > img {
    filter: invert(49%) sepia(1%) saturate(1426%) hue-rotate(29deg) brightness(95%) contrast(83%);
  }
`;

const CategoryInputContainerStyle = styled.div`
  ${flexColumn}
  align-items: end;
  height: 50px;
  width: 90%;
  margin: 0 auto 0 30px;
`;

const Category2Style = styled(CategoryBtnStyle).attrs({ name: 'filter2' })``;

const ImgStyle = styled.img.attrs({ alt: '' })`
  position: relative;
  right: 15px;
`;

const InputBtnStyle = styled.div`
  cursor: pointer;
  background-color: #144da8;
  padding: 5px 10px;
  color: white;
  height: 20px;
  border-radius: 20px;
  width: 50px;
  text-align: center;
  position: relative;
  top: -39px;
  right: 30px;
`;

const InputStyle = styled.input.attrs({ type: 'checkbox', name: 'more' })`
  width: 15px;
  height: 20px;
  background-size: 25px;
  background-position: center;
  background-image: url('/images/more-vertical.svg');
  background-repeat: no-repeat;
  appearance: none;
  cursor: pointer;
  margin: 0 10px auto 0;
  filter: invert(49%) sepia(1%) saturate(1426%) hue-rotate(29deg) brightness(95%) contrast(83%);
  & + label {
    display: none;
  }
  &:checked {
    margin-left: 100px;
  }
  &:checked + label {
    display: block;
  }
`;

const LabelStyle = styled.label`
  position: relative;
  bottom: 25px;
  margin-top: 25px;
  background: white;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 0 10px;
  color: black;
  > div {
    padding: 10px 15px;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid #dadada;
  }
  > div:last-child {
    border-bottom: none;
  }
`;

const TextStyle = styled.div<{ fontSize?: string; color?: string; fontWeight?: number }>`
  font-size: ${(props) => props.fontSize || '16px'};
  color: ${(props) => props.color || 'black'};
  font-weight: ${(props) => props.fontWeight || 500};
`;

const DeleteModalCotnainerStyle = styled.div`
  width: 300px;
  background: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25% auto;
  outline: none;
  > div:last-child {
    display: flex;
    width: 100%;
    margin-top: 30px;
    > div:first-child {
      border-bottom-left-radius: 15px;
    }
    > div:last-child {
      border-bottom-right-radius: 15px;
    }
  }
`;

const ModalTitleStyle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 30px 0 0;
`;

const BtnStyle = styled.div<{ backgroundColor?: string; color?: string }>`
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#144da8'};
  color: ${(props) => props.color || 'white'};
  max-width: 50px;
  text-align: center;
  border-radius: 20px;
  padding: 10px 25px;
  margin: 20px 10px;
`;

const ModalBtnStyle = styled(BtnStyle)`
  width: 50%;
  border-radius: 0;
  margin: 0;
  max-width: unset;
  padding: 15px 20px;
`;

const EditInputStyle = styled.input`
  border: none;
  font-size: 16px;
`;

export default CategoryEditor;
