import { makeObservable, flow, observable, action } from 'mobx';
import { Community as CommunityService } from 'services/Community';
import { CommunityManagement as CommunityManagementService } from 'services/CommunityManagement';
import {
  CommunityBoardDto,
  CommunityDto,
  CommunityMemberDto,
  PostDto,
} from 'services/data-contracts';
// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from './ServiceStoreBase';

class CommunityStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      community: observable,
      communities: observable,
      communityMembers: observable,
      posts: observable,
      boards: observable,
      clearPosts: action,
      getPosts: flow,
      getCommunityMembers: flow,
      getCommunities: flow,
      getCommunityBoards: flow,
      getCommunityById: flow,
      createCommunityBoard: flow,
      updateCommunityBoard: flow,
      deleteCommunityBoard: flow,
      checkDuplicateCommunityName: flow,
      unMount: action,
    });
  }

  api: CommunityService<unknown> = new CommunityService({
    baseURL: REST_API_URL,
  });

  managementApi: CommunityManagementService<unknown> = new CommunityManagementService({
    baseURL: REST_API_URL,
  });

  community: CommunityDto | undefined;

  communities: Array<CommunityDto> | undefined;

  communityMembers: Array<CommunityMemberDto> | undefined;

  posts: Array<PostDto> | undefined;

  boards: Array<CommunityBoardDto> | undefined;

  postPagination: any;

  category: any;

  checkDupResult: any;

  *getCommunities() {
    const { response } = yield this.api.getCommunities();
    this.communities = response.data;
    return super.handleByStatus(response);
  }

  *getCommunityBoards(communityId: number) {
    const { response } = yield this.api.getCommunityBoards(communityId);
    this.boards = response.data;
    return super.handleByStatus(response);
  }

  *getCommunityById(communityId: number) {
    const { response } = yield this.api.getCommunity(communityId);
    this.community = response.data;
    return super.handleByStatus(response);
  }

  *getCommunityCategories() {
    const { response } = yield this.api.getCommunityCategories();
    this.category = response.data;
    return super.handleByStatus(response);
  }

  *getCommunityMembers(communityId: number) {
    const { response } = yield this.api.getCommunityMembers(communityId);
    this.communityMembers = response.data;
    return super.handleByStatus(response);
  }

  *getPosts(communityId: number, query?: { page?: number }) {
    const { response, headers } = yield this.api.getCommunityPosts(communityId, {
      ...query,
      size: 10,
    });
    this.posts = response.data;
    this.postPagination = JSON.parse(headers['x-pagination']);
    return super.handleByStatus(response);
  }

  *checkDuplicateCommunityName(name: string) {
    const { response } = yield this.api.checkDuplicateCommunityName({ name });
    this.checkDupResult = response.data.isValid;
  }

  *createItem(community: CommunityDto) {
    const { response } = yield this.api.createCommunity(community);
    return super.handleByStatus(response, { successMessage: '정상적으로 생성하였습니다.' });
  }

  *updateItem(communityId: number, community: CommunityDto) {
    const { response } = yield this.managementApi.updateCommunityProfile(communityId, community);
    return super.handleByStatus(response, { successMessage: '정상적으로 수정하였습니다.' });
  }

  *createCommunityBoard(communityId: number, data: CommunityBoardDto) {
    const { response } = yield this.managementApi.createCommunityBoard(communityId, data);
    return super.handleByStatus(response, { successMessage: '정상적으로 생성하였습니다.' });
  }

  *updateCommunityBoard(communityId: number, boardId: number, data: CommunityBoardDto) {
    const { response } = yield this.managementApi.updateCommunityBoard(communityId, boardId, data);
    return super.handleByStatus(response, { successMessage: '정상적으로 수정되었습니다.' });
  }

  *deleteCommunityBoard(communityId: number, boardId: number) {
    const { response } = yield this.managementApi.deleteCommunityBoard(communityId, boardId);
    return super.handleByStatus(response, { successMessage: '정상적으로 삭제되었습니다.' });
  }

  // *deleteItem(communityId: number) {
  //   // const { response } = yield this.api.deletePost(communityId);
  //   // return super.handleByStatus(response, { successMessage: '정상적으로 삭제하였습니다.' });
  // }

  *addCommunityAdmin(communityId: number, member: CommunityMemberDto) {
    const { response } = yield this.managementApi.addCommunityAdmin(communityId, member);
    return super.handleByStatus(response, { successMessage: '정상적으로 등록되었습니다.' });
  }

  clearPosts() {
    this.posts = undefined;
  }

  unMount() {
    this.community = undefined;
    this.communities = undefined;
    this.communityMembers = undefined;
    this.posts = undefined;
    this.boards = undefined;
  }
}

export default CommunityStore;
