import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import PostForm from 'features/postManagement/PostForm';

const PostCreatePage = observer(() => {
  const { id } = useParams();

  return (
    <>
      <ContainerStyle>
        {typeof id !== 'undefined' && <PostForm communityId={Number(id)} />}
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 20px;
`;

export default PostCreatePage;
