import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { GnbMenu, MenuProps } from 'features/commonFeatures/MenuDefinitions';
import styled from 'styled-components';
import _ from 'lodash';

type Props = {
  handleMenu: (currentMenu: string) => void;
};

const Gnb = observer(({ handleMenu }: Props) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const me = localStorage.getItem('accessToken');
  const { authStore, userStore } = useStore();
  const [menus, setMenusByUserRome] = useState<Array<MenuProps>>();
  const [auth, setAuth] = useState<boolean>(false);

  const checkMe = async () => {
    if (me === null) {
      navigate('/login');
    } else {
      setAuth(true);
    }
  };

  useEffect(() => {
    const currentMenu = GnbMenu.find((item) => item.navigate === window.location.pathname);
    if (typeof currentMenu?.title !== 'undefined') {
      handleMenu(`${currentMenu.title}`);
    }
  }, []);

  useEffect(() => {
    checkMe();
  }, [me]);

  useEffect(() => {
    if (typeof userStore.userInfo !== 'undefined') {
      if (userStore.userInfo?.isAdmin === false) {
        const normalUserMenu = GnbMenu.filter((menu: MenuProps) => menu.adminOnly !== true);
        setMenusByUserRome(normalUserMenu);
      } else {
        setMenusByUserRome(GnbMenu);
      }
    }
  }, [userStore.userInfo]);

  return (
    <ContainerStyle>
      <ContainerInnerStyle>
        <GnbLayoutStyle>
          {auth === true && (
            <>
              {menus?.map((item: MenuProps) => (
                <>
                  <>
                    <GnbItemStyle
                      className="gnbMenu"
                      id={item.id}
                      readOnly
                      checked={pathname.includes(item.navigate)}
                      onClick={() => {
                        navigate(item.navigate);
                        handleMenu(`${item.title}`);
                      }}
                    />
                    <GnbItemLabelStyle
                      htmlFor={item.id}
                      onClick={() => {
                        navigate(item.navigate);
                        handleMenu(`${item.title}`);
                      }}
                    >
                      {item.title}
                    </GnbItemLabelStyle>
                  </>
                </>
              ))}
            </>
          )}
        </GnbLayoutStyle>
        <GnbLayoutStyle>
          <GnbItemLabelStyle>
            {auth === true ? '로그아웃' : '로그인'}
            <GnbItemStyle
              id="login"
              onClick={() => {
                if (auth === true) {
                  authStore.logout();
                } else {
                  navigate('/login');
                }
              }}
            />
          </GnbItemLabelStyle>
        </GnbLayoutStyle>
      </ContainerInnerStyle>
    </ContainerStyle>
  );
});

const ContainerStyle = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 240px;
  width: calc(100% - 17%);
`;

const ContainerInnerStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const GnbLayoutStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin-top: auto;
  /* margin-left: 10px; */
  align-items: center;
`;

const GnbItemStyle = styled.input.attrs({
  type: 'radio',
  name: 'gnbMenu',
})`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
  &:checked + label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 1;
    height: 76px;
    border-bottom: 2px solid black;
  }
`;

const GnbItemLabelStyle = styled.label`
  cursor: pointer;
  margin: 0 20px 0 0;
  opacity: 0.2;
`;

const LogoStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 230px;
`;

export default Gnb;
