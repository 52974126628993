import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import PostForm from 'features/postManagement/PostForm';

const PostFormPage = observer(() => {
  const { communityStore } = useStore();
  const { communityid, id } = useParams();

  useEffect(() => {
    communityStore.getCommunities();
  }, []);

  return (
    <>
      <ContainerStyle>
        {typeof communityStore.communities !== 'undefined' && (
          <PostForm communityId={Number(communityid)} postId={id} />
        )}
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 20px;
`;

export default PostFormPage;
