import { makeObservable, flow, observable } from 'mobx';
import { UserStatistics as UserStatisticsServices } from 'adminServices/UserStatistics';
import { ADMIN_REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from 'stores/ServiceStoreBase';

class UserStatisticsStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      getJoinUser: flow,
      getUserMonthly: flow,
      getUserYear: flow,
      userNumber: observable,
      monthlyUser: observable,
      yearUser: observable,
    });
  }

  api: UserStatisticsServices<unknown> = new UserStatisticsServices({
    baseURL: ADMIN_REST_API_URL,
  });

  userNumber: any;

  monthlyUser: any;

  monthlyError: any;

  yearUser: any;

  yearError: any;

  *getJoinUser() {
    const { data } = yield this.api.getJoinUser();
    this.userNumber = data;
  }

  *getUserMonthly(query: { yearMonth: string }) {
    const { data, error } = yield this.api.getUserMonthly(query);
    this.monthlyError = error;
    this.monthlyUser = data;
  }

  *getUserYear(query: { year: string }) {
    const { data, error } = yield this.api.getUserYear(query);
    this.yearError = error;
    this.yearUser = data;
  }
}

export default UserStatisticsStore;
