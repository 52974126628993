import React, { useEffect, useState } from 'react';
import { LinkCategoryDto } from 'adminServices/data-contracts';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import styled, { css } from 'styled-components';

type CategoryNameType = {
  main?: string;
  sub?: string;
};

type Props = {
  handleCategory: (newModel: any) => void;
  handleCategoryModal?: (open: boolean) => void;
  handleCategoryName: (categoryName: any) => void;
  type: 'modify' | 'create';
  categoryItem?: CategoryNameType;
};

const CategoryListContainer = observer(
  ({ handleCategoryModal, handleCategoryName, handleCategory, categoryItem, type }: Props) => {
    const { linkStore } = useStore();
    const [subCategory, setSubCategory] = useState<string>('');
    const [mainCategory, setMainCategory] = useState<string>('');

    useEffect(() => {
      handleCategoryName({ main: mainCategory, sub: subCategory });
    }, [mainCategory, subCategory]);

    useEffect(() => {
      if (type === 'modify') {
        if (typeof categoryItem?.main !== 'undefined') {
          setMainCategory(categoryItem?.main);
        }
        if (typeof categoryItem?.sub !== 'undefined') {
          setSubCategory(categoryItem?.sub);
        }
        handleCategoryName({ main: mainCategory, sub: subCategory });
      } else if (type === 'create') {
        handleCategoryName({ main: '', sub: '' });
      }
    }, [type]);

    return (
      <ContainerStyle>
        <RowStyle flexDirection="column" style={{ height: '100%' }}>
          <CategoryContainerStyle>
            <CategorySectionStyle border={true}>
              <HeaderTextStyle>대분류</HeaderTextStyle>
              <div style={{ marginTop: 30 }}>
                {typeof linkStore.categoryList !== 'undefined' && (
                  <>
                    {linkStore?.categoryList.map((item: LinkCategoryDto) => (
                      <>
                        <Category1Style
                          readOnly
                          key={item.name + '-'}
                          id={item.name}
                          checked={item.name === mainCategory}
                        />
                        <CategoryLabelStyle
                          htmlFor={item.name + '-'}
                          onClick={() => {
                            if (typeof item.name !== 'undefined') {
                              setMainCategory(item.name);
                            }
                            setSubCategory('');
                          }}
                        >
                          {typeof item.publicUrl !== 'undefined' ? (
                            <ThumbnailImgStyle src={item.publicUrl} />
                          ) : (
                            <ImgBoxStyle />
                          )}
                          <TextStyle left={true}>{item.name}</TextStyle>
                          <img src="/images/categoryArrow.svg" />
                        </CategoryLabelStyle>
                      </>
                    ))}
                  </>
                )}
              </div>
            </CategorySectionStyle>
            <ImgStyle src="/images/arrow-right.svg" />
            <CategorySectionStyle>
              <HeaderTextStyle>소분류</HeaderTextStyle>
              <div style={{ marginTop: 30 }}>
                {typeof linkStore.categoryList !== 'undefined' ? (
                  <>
                    {linkStore.categoryList
                      .find((item: LinkCategoryDto) => item.name === mainCategory)
                      ?.subCategory?.map((item: LinkCategoryDto) => (
                        <>
                          <Category2Style
                            readOnly
                            key={item.name + '-'}
                            id={item.name}
                            checked={item.name === subCategory}
                          />
                          <SubCategoryLabelStyle
                            htmlFor={item.name + '-'}
                            onClick={() => {
                              if (type === 'create') {
                                handleCategory({ linkCategoryId: item.id });
                              } else if (type === 'modify') {
                                handleCategory({ subCategoryId: item.id });
                              }
                              if (typeof item.name !== 'undefined') {
                                setSubCategory(item.name);
                              }
                            }}
                          >
                            {item.name}
                          </SubCategoryLabelStyle>
                        </>
                      ))}
                  </>
                ) : (
                  <TextStyle>대분류를 먼저 선택하세요</TextStyle>
                )}
              </div>
              {typeof handleCategoryModal !== 'undefined' && (
                <BtnContainerStyle>
                  <BtnStyle
                    backgroundColor="#4b4b4b"
                    onClick={() => {
                      handleCategoryModal(false);
                    }}
                  >
                    취소
                  </BtnStyle>
                  <BtnStyle
                    onClick={() => {
                      if (subCategory !== '') {
                        handleCategoryModal(false);
                      } else {
                        toast('소분류를 선택해주세요.', { type: 'error' });
                      }
                    }}
                  >
                    완료
                  </BtnStyle>
                </BtnContainerStyle>
              )}
            </CategorySectionStyle>
          </CategoryContainerStyle>
        </RowStyle>
      </ContainerStyle>
    );
  },
);

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

const RowStyle = styled.div<{ flexDirection?: string; height?: string }>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'row !important'};
  height: ${(props) => props.height || '100px'};
`;

const ContainerStyle = styled.div`
  height: 100%;
  margin: auto;
`;

const CategoryContainerStyle = styled.div`
  display: flex;
  padding: 20px;
  width: 85%;
  margin: auto;
  border: 1px solid #eeeeee;
  background-color: white;
  border-radius: 10px;
  max-width: 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  max-width: 800px;
  max-height: 400px;
  > div {
    margin: 10px 0;
  }
`;

const CategorySectionStyle = styled.div<{ border?: boolean }>`
  ${flexColumn}
  overflow: scroll;
  padding-bottom: 30px;
  width: 50%;
  > div {
    ${flexColumn}
  }
  ${(props) =>
    props.border &&
    css`
      border-right: 1px solid #eeeeee;
    `}
`;

const CategoryBtnStyle = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  &:checked + label {
    background-color: #3e64ea;
    color: white;
    > img {
      filter: unset;
    }
    > div > input {
      filter: unset;
    }
  }
`;

const CategoryLabelStyle = styled.label`
  cursor: pointer;
  background-color: #f6f6f6;
  color: #666666;
  padding: 10px 10px 10px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 20px;
  margin: 5px 0;
  > img:first-child {
    border-radius: 50%;
    position: relative;
    bottom: 2px;
    right: 10px;
  }
  > img:last-child {
    filter: invert(49%) sepia(1%) saturate(1426%) hue-rotate(29deg) brightness(95%) contrast(83%);
  }
`;

const SubCategoryLabelStyle = styled(CategoryLabelStyle)`
  width: 90%;
  justify-content: center;
`;

const Category1Style = styled(CategoryBtnStyle).attrs({ name: 'mainFilter' })``;

const Category2Style = styled(CategoryBtnStyle).attrs({ name: 'subFilter' })``;

const ImgStyle = styled.img.attrs({ alt: '' })`
  position: relative;
  right: 15px;
  margin: auto;
  width: 30px;
  height: 30px;
`;

const BtnStyle = styled.div<{ backgroundColor?: string }>`
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#144da8'};
  color: white;
  width: 50px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 0 10px;
  max-height: 25px;
`;

const BtnContainerStyle = styled.div`
  justify-content: end;
  margin-top: 50px;
  display: flex;
  flex-direction: row !important;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const HeaderTextStyle = styled.div`
  position: fixed;
  background-color: white;
  padding-bottom: 10px;
  width: 400px;
  z-index: 10;
`;

const TextStyle = styled.div<{ left?: boolean }>`
  color: #999999;
  padding: 20px 20px 70px;
  ${(props) =>
    props.left &&
    css`
      padding: 0;
      position: relative;
      left: -12.5;
    `}
`;

const ThumbnailImgStyle = styled.img`
  width: 25px;
  height: 25px;
`;

const ImgBoxStyle = styled.div`
  width: 25px;
  height: 25px;
`;

export default CategoryListContainer;
