import React, { CSSProperties, useEffect, useState } from 'react';
import { PostHeaders } from '../settings/definitions';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { CommunityDto } from 'services/data-contracts';
import { Table } from 'controls/table';

const CommunityList = observer(() => {
  const pathname = window.location.pathname;
  const { communityStore } = useStore();
  const postHeaders: PostHeaders = new PostHeaders();
  const [header, setHeader] = useState<any>(postHeaders.getCommunityHeaders());

  const init = async () => {
    await communityStore.getCommunities();
  };

  useEffect(() => {
    init();
    if (pathname === '/communities/posts') {
      setHeader(postHeaders.getPostManageHeaders());
    } else if (pathname === '/community') {
      setHeader(postHeaders.getCommunityHeaders());
    }
  }, []);

  return (
    <>
      <Table<CommunityDto>
        headers={header}
        items={communityStore.communities}
        tableStyle={tableStyle}
      />
    </>
  );
});

const tableStyle: CSSProperties = {
  textAlign: 'center',
  border: '1px solid #ededed',
  borderRadius: 5,
  borderCollapse: 'collapse',
  backgroundColor: 'white',
};

export default CommunityList;
