import React, { CSSProperties, useState } from 'react';
import { observer } from 'mobx-react';
import { Input } from 'components/commons';
import { BannerDto, FileDto } from 'adminServices/data-contracts';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Modal, TextField } from '@mui/material';
import styled, { css } from 'styled-components';
import moment from 'moment';
import PostSearch from '../containers/PostSearch';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

type BannerType = {
  type: 'ADVERTISEMENT' | 'EVENT' | 'PROMOTION' | 'NEWS' | 'NOTICE';
  name: string;
};

type BannerCategoryType = {
  type: 'COMMUNITY' | 'MARKET' | 'PROPERTY' | 'TALK';
  name: string;
};

const BannerTypes: Array<BannerType> = [
  { type: 'ADVERTISEMENT', name: '광고' },
  { type: 'EVENT', name: '이벤트' },
  { type: 'PROMOTION', name: '홍보' },
  { type: 'NEWS', name: '소식' },
  { type: 'NOTICE', name: '공지' },
];

const BannerCategory: Array<BannerCategoryType> = [
  { type: 'COMMUNITY', name: '번영회' },
  { type: 'MARKET', name: '장터' },
  { type: 'PROPERTY', name: '부동산' },
  { type: 'TALK', name: '톡' },
];

const CreatePage = observer(() => {
  const navigate = useNavigate();
  const { bannerStore, fileStore } = useStore();
  const [model, setModel] = useState<BannerDto>();
  const [bannerImg, setBannerImg] = useState<FileDto>();
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleClickCreate = async () => {
    if (typeof model !== 'undefined') {
      await bannerStore.createBanner(model);
      navigate('/banner');
    }
  };

  const handleClickUploadImg = async (img: any) => {
    await fileStore.uploadImage(img);
    setModel({ ...model, file: { id: fileStore.fileData[0].id } });
    setBannerImg(fileStore.fileData[0]);
  };

  const handleModal = (open: boolean) => {
    setOpenModal(open);
  };

  const handleClickSearchPost = () => {
    setOpenModal(true);
  };

  const handleSelectPostId = (id: string) => {
    setModel({ ...model, link: id, linkType: 'POST_ID' });
  };

  return (
    <>
      <Modal open={openModal}>
        <ModalContainerStyle>
          <PostSearch handleSelectPostId={handleSelectPostId} handleModal={handleModal} />
        </ModalContainerStyle>
      </Modal>
      <HeaderTitleStyle title="배너등록" />
      <ContainerStyle>
        <InputContainerStyle>
          <div>
            <SpanTextStyle>*</SpanTextStyle>
            이름
          </div>
          <Input
            style={inputStyle}
            autoFocus
            id="name"
            defaultValue={model?.name}
            onChangeValue={(value: string) => {
              setModel({ ...model, name: value });
            }}
          />
        </InputContainerStyle>
        <SectionStyle>
          <TypeContianerStyle style={{ marginRight: 15 }}>
            <TextStyle>종류</TextStyle>
            <BtnContainerStyle>
              {BannerTypes.map((item: BannerType) => (
                <>
                  <RadioBtnStyle
                    name="bannerType"
                    id={item.type}
                    onClick={() => {
                      setModel({ ...model, bannerType: item.type });
                    }}
                  />
                  <RadioLabelStyle htmlFor={item.type}>{item.name}</RadioLabelStyle>
                </>
              ))}
            </BtnContainerStyle>
          </TypeContianerStyle>
          <TypeContianerStyle>
            <TextStyle>카테고리</TextStyle>
            <BtnContainerStyle>
              {BannerCategory.map((item: BannerCategoryType) => (
                <>
                  <RadioBtnStyle
                    name="bannerCategory"
                    id={item.type}
                    onClick={() => {
                      setModel({ ...model, bannerCategory: item.type });
                    }}
                  />
                  <RadioLabelStyle htmlFor={item.type}>{item.name}</RadioLabelStyle>
                </>
              ))}
            </BtnContainerStyle>
          </TypeContianerStyle>
        </SectionStyle>
        <SectionStyle>
          <TitleContainerStyle>
            <TextStyle>
              <SpanTextStyle>*</SpanTextStyle>시작일
            </TextStyle>
            <GrayContainerStyle>
              <CalendarImgStyle
                onClick={() => {
                  setShowStartCalendar(!showStartCalendar);
                }}
              />
              {model?.startDate}
            </GrayContainerStyle>
            {showStartCalendar && (
              <CalendarContainerStyle>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={model?.startDate}
                    disablePast
                    onChange={(newValue: Date) => {
                      setModel({
                        ...model,
                        startDate: moment(newValue).format('YYYY-MM-DD 00:00:00'),
                      });
                      setShowStartCalendar(false);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </CalendarContainerStyle>
            )}
          </TitleContainerStyle>
          <TitleContainerStyle>
            <TextStyle>
              <SpanTextStyle>*</SpanTextStyle>종료일
            </TextStyle>
            <GrayContainerStyle>
              <CalendarImgStyle
                onClick={() => {
                  setShowEndCalendar(!showEndCalendar);
                }}
              />
              {model?.endDate}
            </GrayContainerStyle>
            {showEndCalendar && (
              <CalendarContainerStyle>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={model?.endDate}
                    disablePast
                    onChange={(newValue: Date) => {
                      setModel({
                        ...model,
                        endDate: moment(newValue).format('YYYY-MM-DD 00:00:00'),
                      });
                      setShowEndCalendar(false);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </CalendarContainerStyle>
            )}
          </TitleContainerStyle>
        </SectionStyle>
        <SectionStyle>
          <TypeContianerStyle style={{ width: '50%', marginRight: 15 }}>
            <TextStyle>
              <SpanTextStyle>*</SpanTextStyle>활성여부
            </TextStyle>
            <BtnContainerStyle>
              <RadioBtnStyle
                id="on"
                name="status"
                onClick={(e) => {
                  setModel({ ...model, isStatus: true });
                }}
              />
              <RadioLabelStyle htmlFor="on">ON</RadioLabelStyle>
              <RadioBtnStyle
                id="off"
                name="status"
                onClick={() => {
                  setModel({ ...model, isStatus: false });
                }}
              />
              <RadioLabelStyle htmlFor="off">OFF</RadioLabelStyle>
            </BtnContainerStyle>
          </TypeContianerStyle>
          <ImgUploadContainerStyle style={{ width: '50%' }}>
            <InputContainerStyle>
              <div>
                <SpanTextStyle>*</SpanTextStyle>업로드
              </div>
              <div style={{ display: 'flex' }}>
                <Input
                  style={{ ...inputStyle, width: '100%' }}
                  readOnly
                  defaultValue={bannerImg?.fileName}
                />
                <InputStyle
                  type="file"
                  onChange={(e) => {
                    if (e.target.files !== null) {
                      handleClickUploadImg(e.target.files[0]);
                    }
                  }}
                />
                <LabelStyle>
                  <img src="/images/search.svg" />
                </LabelStyle>
              </div>
            </InputContainerStyle>
          </ImgUploadContainerStyle>
        </SectionStyle>
        <SectionStyle>
          <InputContainerStyle style={{ marginRight: 15 }}>
            <div>링크(URL)</div>
            <Input
              style={{ ...inputStyle }}
              placeholder="https://"
              id="link"
              defaultValue={model?.link}
              onChangeValue={(value: string) => {
                setModel({ ...model, link: value, linkType: 'URL' });
              }}
            />
          </InputContainerStyle>
          <InputContainerStyle>
            <div>링크(번영회게시글)</div>
            <div style={{ display: 'flex' }}>
              <Input
                style={inputStyle}
                id="link"
                // defaultValue={model?.link}
                onChangeValue={(value: string) => {
                  // setModel({ ...model, link: value, linkType: 'POST_ID' });
                }}
              />
              <SearchIconStyle src="/images/searchPost.svg" onClick={handleClickSearchPost} />
            </div>
          </InputContainerStyle>
        </SectionStyle>
        <InputContainerStyle>
          <div>상세정보</div>
          <Input
            style={inputStyle}
            placeholder="배너관련 내용 / 메모내용 등"
            id="remarks"
            defaultValue={model?.remarks}
            onChangeValue={(value: string) => {
              setModel({ ...model, remarks: value });
            }}
          />
        </InputContainerStyle>
        <div style={{ display: 'flex', marginTop: 100, justifyContent: 'center' }}>
          <BtnStyle
            backgroundColor="#4B4B4B"
            onClick={() => {
              navigate('/banner');
            }}
          >
            취소
          </BtnStyle>
          <BtnStyle onClick={handleClickCreate}>등록</BtnStyle>
        </div>
      </ContainerStyle>
    </>
  );
});

const inputStyle: CSSProperties = {
  height: 40,
  fontSize: 14,
  backgroundColor: '#f3f3f3',
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

const SectionStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextStyle = styled.div`
  font-size: 14px;
  display: flex;
  margin: 5px 0;
`;

const TypeContianerStyle = styled.div`
  width: 100%;
`;

const RadioBtnStyle = styled.input.attrs({ type: 'radio' })``;

const RadioLabelStyle = styled.label`
  margin-left: 5px;
`;

const BtnStyle = styled.div<{ color?: string; backgroundColor?: string; border?: boolean }>`
  cursor: pointer;
  border-radius: 25px;
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.color || 'white'};
  background-color: ${(props) => props.backgroundColor || '#144da8'};
  display: flex;
  align-items: center;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.color};
    `};
  > div {
    margin-left: 10px;
    color: black;
    font-weight: 600;
  }
`;

const InputStyle = styled.input.attrs({ type: 'file', id: 'file' })`
  display: none;
`;

const LabelStyle = styled.label.attrs({ htmlFor: 'file' })`
  display: flex;
  > img {
    cursor: pointer;
    margin-left: 10px;
  }
`;

const BtnContainerStyle = styled.div`
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  height: 30px;
  display: flex;
  align-items: center;
`;

const ImgUploadContainerStyle = styled.div`
  display: flex;
  align-items: end;
`;

const TitleContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  > div:nth-child(2) {
    display: flex;
    align-items: center;
    > img {
      margin: 0 10px;
    }
  }
  &:first-child {
    margin-right: 15px;
  }
`;

const CalendarImgStyle = styled.img.attrs({ alt: '', src: '/images/calendar.svg' })`
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const CalendarContainerStyle = styled.div`
  position: absolute;
  top: 325px;
`;

const SearchIconStyle = styled.img.attrs({ alt: '' })`
  cursor: pointer;
  margin-left: 10px;
`;

const ModalContainerStyle = styled.div`
  height: 100%;
  width: 100%;
  > img {
    position: relative;
    top: -30%;
    left: 87%;
    width: 50px;
    cursor: pointer;
  }
`;

const SpanTextStyle = styled.div`
  color: #cc2121;
`;

const InputContainerStyle = styled.div`
  > div:first-child {
    display: flex;
    font-size: 14px;
    margin: 5px 0;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GrayContainerStyle = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  height: 50px;
`;

export default CreatePage;
