/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CardPromotionDto,
  DeliveryAddressDto,
  IdDto,
  OrderDeliveryInfoDto,
  OrderDto,
  ReviewDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Order<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 배송지 수정
   *
   * @tags order
   * @name UpdateDeliveryAddress
   * @summary 배송지 수정
   * @request PUT:/v1/market/delivery-addresses/{deliveryAddressId}
   * @secure
   */
  updateDeliveryAddress = (
    deliveryAddressId: number,
    data: DeliveryAddressDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/market/delivery-addresses/${deliveryAddressId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 배송지 삭제
   *
   * @tags order
   * @name DeleteDeliveryAddress
   * @summary 배송지 삭제
   * @request DELETE:/v1/market/delivery-addresses/{deliveryAddressId}
   * @secure
   */
  deleteDeliveryAddress = (deliveryAddressId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/delivery-addresses/${deliveryAddressId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 후기 작성하기
   *
   * @tags order
   * @name CreateReview
   * @summary 후기 작성하기
   * @request POST:/v1/market/orders/{orderId}/review
   * @secure
   */
  createReview = (orderId: number, data: ReviewDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/orders/${orderId}/review`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 취소 요청
   *
   * @tags order
   * @name RequestCancelOrder
   * @summary 취소 요청
   * @request POST:/v1/market/orders/{orderId}/request-cancel
   * @secure
   */
  requestCancelOrder = (orderId: number, data: OrderDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/orders/${orderId}/request-cancel`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 송장번호 입력
   *
   * @tags order
   * @name AddOrderDeliveryInvoice
   * @summary 송장번호 입력
   * @request POST:/v1/market/orders/{orderId}/delivery-invoice
   * @secure
   */
  addOrderDeliveryInvoice = (
    orderId: number,
    data: OrderDeliveryInfoDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/market/orders/${orderId}/delivery-invoice`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 거래 완료
   *
   * @tags order
   * @name CompleteOrder
   * @summary 거래 완료
   * @request POST:/v1/market/orders/{orderId}/complete
   * @secure
   */
  completeOrder = (orderId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/orders/${orderId}/complete`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 거래 취소
   *
   * @tags order
   * @name CancelOrder
   * @summary 거래 취소
   * @request POST:/v1/market/orders/{orderId}/cancel
   * @secure
   */
  cancelOrder = (orderId: number, data: OrderDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/market/orders/${orderId}/cancel`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 배송지 리스트 조회
   *
   * @tags order
   * @name GetDeliveryAddresses
   * @summary 배송지 리스트 조회
   * @request GET:/v1/market/delivery-addresses
   * @secure
   */
  getDeliveryAddresses = (params: RequestParams = {}) =>
    this.request<DeliveryAddressDto[], any>({
      path: `/v1/market/delivery-addresses`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 배송지 등록
   *
   * @tags order
   * @name AddDeliveryAddress
   * @summary 배송지 등록
   * @request POST:/v1/market/delivery-addresses
   * @secure
   */
  addDeliveryAddress = (data: DeliveryAddressDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/market/delivery-addresses`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 토스 결제 요청 성공 콜백
   *
   * @tags order
   * @name TossPaymentsSuccess
   * @summary 토스 결제 요청 성공 콜백
   * @request GET:/v1/toss-payments/callback/success
   * @secure
   */
  tossPaymentsSuccess = (
    query: { paymentKey: string; orderId: string; amount: number },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/toss-payments/callback/success`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 토스 결제 요청 실패 콜백
   *
   * @tags order
   * @name TossPaymentsFail
   * @summary 토스 결제 요청 실패 콜백
   * @request GET:/v1/toss-payments/callback/fail
   * @secure
   */
  tossPaymentsFail = (
    query: { code: string; message: string; orderId: string },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/toss-payments/callback/fail`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description 결제 결과
   *
   * @tags order
   * @name PaymentResult
   * @summary 결제 결과
   * @request GET:/v1/payments/{result}
   * @secure
   */
  paymentResult = (result: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/payments/${result}`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * @description 카드 혜택 조회
   *
   * @tags order
   * @name GetCardPromotion
   * @summary 카드 혜택 조회
   * @request GET:/v1/market/payments/card-promotion
   * @secure
   */
  getCardPromotion = (params: RequestParams = {}) =>
    this.request<CardPromotionDto, any>({
      path: `/v1/market/payments/card-promotion`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 거래내역 상세 조회
   *
   * @tags order
   * @name GetOrder
   * @summary 거래내역 상세 조회
   * @request GET:/v1/market/orders/{orderId}
   * @secure
   */
  getOrder = (orderId: number, params: RequestParams = {}) =>
    this.request<OrderDto, any>({
      path: `/v1/market/orders/${orderId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 정산내역 리스트 조회
   *
   * @tags order
   * @name GetSettlements
   * @summary 정산내역 리스트 조회
   * @request GET:/v1/market/orders/settlements
   * @secure
   */
  getSettlements = (
    query?: {
      status?: 'HOLD' | 'WAITING' | 'IN_PROGRESS' | 'DONE';
      startDate?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderDto[], any>({
      path: `/v1/market/orders/settlements`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 판매내역 리스트 조회
   *
   * @tags order
   * @name GetSalesOrders
   * @summary 판매내역 리스트 조회
   * @request GET:/v1/market/orders/sales
   * @secure
   */
  getSalesOrders = (
    query?: {
      status?:
        | 'RESERVED'
        | 'CANCELED'
        | 'PAYING'
        | 'PAID'
        | 'CANCEL_REQUESTED'
        | 'REFUNDED'
        | 'COMPLETED';
      buyerId?: number;
      sellerId?: number;
      itemId?: number;
      startDate?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderDto[], any>({
      path: `/v1/market/orders/sales`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 구매내역 리스트 조회
   *
   * @tags order
   * @name GetPurchaseOrders
   * @summary 구매내역 리스트 조회
   * @request GET:/v1/market/orders/purchase
   * @secure
   */
  getPurchaseOrders = (
    query?: {
      status?:
        | 'RESERVED'
        | 'CANCELED'
        | 'PAYING'
        | 'PAID'
        | 'CANCEL_REQUESTED'
        | 'REFUNDED'
        | 'COMPLETED';
      buyerId?: number;
      sellerId?: number;
      itemId?: number;
      startDate?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderDto[], any>({
      path: `/v1/market/orders/purchase`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 기본 배송지 조회
   *
   * @tags order
   * @name GetPrimaryDeliveryAddress
   * @summary 기본 배송지 조회
   * @request GET:/v1/market/delivery-addresses/primary
   * @secure
   */
  getPrimaryDeliveryAddress = (params: RequestParams = {}) =>
    this.request<DeliveryAddressDto, any>({
      path: `/v1/market/delivery-addresses/primary`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
