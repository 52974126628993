/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  LinkCategoryDto,
  LinkCategoryRequestDto,
  LinkCategoryUpdateDto,
  LinkDto,
  LinkRequestDto,
  LinkUpdateDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Link<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 링크 조회
   *
   * @tags link
   * @name GetLinkById
   * @summary 링크 조회
   * @request GET:/v1/links/{linkId}
   * @secure
   */
  getLinkById = (linkId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/links/${linkId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 수정
   *
   * @tags link
   * @name UpdateLink
   * @summary 링크 수정
   * @request PUT:/v1/links/{linkId}
   * @secure
   */
  updateLink = (
    linkId: number,
    data: { file?: File; linkUpdateDto?: LinkUpdateDto },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/v1/links/${linkId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 삭제
   *
   * @tags link
   * @name DeleteLink
   * @summary 링크 삭제
   * @request DELETE:/v1/links/{linkId}
   * @secure
   */
  deleteLink = (linkId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/links/${linkId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 목록 조회
   *
   * @tags link
   * @name GetLinks
   * @summary 링크 목록 조회
   * @request GET:/v1/links
   * @secure
   */
  getLinks = (
    query?: {
      searchText?: string;
      page?: number;
      size?: number;
      mainCategoryId?: number;
      subCategoryId?: number;
      sortBy?: string;
      direction?: string;
      from?: string;
      to?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/v1/links`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 생성
   *
   * @tags link
   * @name CreateLink
   * @summary 링크 생성
   * @request POST:/v1/links
   * @secure
   */
  createLink = (
    data: { file?: File; linkRequestDto: LinkRequestDto },
    params: RequestParams = {},
  ) =>
    this.request<LinkDto, any>({
      path: `/v1/links`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 카테고리 목록 조회
   *
   * @tags link
   * @name GetLinkCategories
   * @summary 링크 카테고리 목록 조회
   * @request GET:/v1/link-categories
   * @secure
   */
  getLinkCategories = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/link-categories`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 카테고리 생성
   *
   * @tags link
   * @name CreateLinkCategory
   * @summary 링크 카테고리 생성
   * @request POST:/v1/link-categories
   * @secure
   */
  createLinkCategory = (data: LinkCategoryRequestDto, params: RequestParams = {}) =>
    this.request<LinkCategoryDto, any>({
      path: `/v1/link-categories`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 카테고리 이미지 등록/수정
   *
   * @tags link
   * @name UpdateLinkCategory
   * @summary 링크 카테고리 이미지 등록/수정
   * @request PATCH:/v1/link-categories/{linkCategoryId}/image
   * @secure
   */
  updateLinkCategory = (
    linkCategoryId: number,
    data: { file?: File },
    params: RequestParams = {},
  ) =>
    this.request<LinkCategoryDto, any>({
      path: `/v1/link-categories/${linkCategoryId}/image`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 카테고리명 수정
   *
   * @tags link
   * @name UpdateLinkCategoryName
   * @summary 링크 카테고리명 수정
   * @request PATCH:/v1/link-categories/{linkCategoryId}/categoryName
   * @secure
   */
  updateLinkCategoryName = (
    linkCategoryId: number,
    data: { linkCategoryUpdateDto: LinkCategoryUpdateDto },
    params: RequestParams = {},
  ) =>
    this.request<LinkCategoryDto, any>({
      path: `/v1/link-categories/${linkCategoryId}/categoryName`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 카테고리를 참조하는 서브 카테고리 조회
   *
   * @tags link
   * @name ExistSubCategoryByLinkCategory
   * @summary 카테고리를 참조하는 서브 카테고리 조회
   * @request GET:/v1/link-categories/{linkCategoryId}/subCategory
   * @secure
   */
  existSubCategoryByLinkCategory = (linkCategoryId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/link-categories/${linkCategoryId}/subCategory`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 카테고리를 참조하는 링크 조회
   *
   * @tags link
   * @name ExistLinkByLinkCategory
   * @summary 카테고리를 참조하는 링크 조회
   * @request GET:/v1/link-categories/{linkCategoryId}/link
   * @secure
   */
  existLinkByLinkCategory = (linkCategoryId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/link-categories/${linkCategoryId}/link`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 카테고리 삭제
   *
   * @tags link
   * @name DeleteLinkCategory
   * @summary 링크 카테고리 삭제
   * @request DELETE:/v1/link-categories/{linkCategoryId}
   * @secure
   */
  deleteLinkCategory = (linkCategoryId: number, params: RequestParams = {}) =>
    this.request<LinkCategoryDto, any>({
      path: `/v1/link-categories/${linkCategoryId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
}
