import React, { useEffect, useState, CSSProperties } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { CommunityBoardDto } from 'services/data-contracts';
import { Input } from 'components/commons';
import styled from 'styled-components';
import CategoryEditor from './CategoryEditor';

type Props = {
  id: number;
};

type BoardType = {
  boardType: 'CUSTOM' | 'NOTICE' | 'FREE';
};

const BoardTypeList: Array<BoardType> = [
  { boardType: 'CUSTOM' },
  { boardType: 'NOTICE' },
  { boardType: 'FREE' },
];

const CategoryList = observer(({ id }: Props) => {
  const navigate = useNavigate();
  const { communityStore } = useStore();
  const [boardData, setBoardData] = useState<CommunityBoardDto>();

  const init = async () => {
    if (typeof id !== 'undefined') {
      await communityStore.getCommunityBoards(id);
    }
  };

  const handleClickCreate = async () => {
    if (typeof boardData !== 'undefined') {
      const result = await communityStore.createCommunityBoard(id, boardData);
      if (result) {
        navigate(`/community/${id}/modify`);
        await communityStore.getCommunityBoards(id);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <ContainerStyle>
      <HeaderStyle>
        <InputBoxStyle>
          <InputStyle
            placeholder="게시판 추가"
            onChange={(e) => setBoardData({ ...boardData, name: e.target.value })}
          />
          <IconStyle onClick={handleClickCreate} />
        </InputBoxStyle>
      </HeaderStyle>
      <InputContainerStyle>
        {BoardTypeList.map((item: BoardType) => (
          <Input
            boxStyle={checkBoxStyle}
            style={{ width: 15, height: 15 }}
            labelStyle={{ marginTop: 7, order: 2 }}
            name="boardType"
            containerStyle={{ alignItems: 'flex-end', height: 'auto' }}
            type="radio"
            label={`${item.boardType}`}
            onClick={() => {
              setBoardData({ ...boardData, boardType: `${item.boardType}` });
            }}
          />
        ))}
      </InputContainerStyle>
      <CategoryEditorStyle>
        {communityStore.boards?.map((item: CommunityBoardDto) => (
          <CategoryEditor communityId={id} item={item} />
        ))}
      </CategoryEditorStyle>
    </ContainerStyle>
  );
});

const checkBoxStyle: CSSProperties = {
  flex: 'none !important',
  display: 'flex',
  alignItems: 'center',
};

const ContainerStyle = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  background-color: white;
  margin-bottom: 30px;
`;

const CategoryEditorStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
`;

const HeaderStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputContainerStyle = styled.div`
  display: flex;
  justify-content: center;
`;

const InputBoxStyle = styled.div`
  display: flex;
  border-radius: 20px;
  background-color: #88a3ce;
  height: 40px;
  align-items: center;
  margin-top: 20px;
`;

const InputStyle = styled.input.attrs({})`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: white;
  border-radius: 20px;
  height: 26px;
  width: 200px;
  padding: 4px 10px 4px;
  margin-left: 4px;
  &::placeholder {
    color: #666666;
  }
`;

const IconStyle = styled.img.attrs({ src: '/images/close-multiply.svg', alt: '' })`
  width: 50px;
  cursor: pointer;
  filter: brightness(1);
`;

export default CategoryList;
