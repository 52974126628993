import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  LinkCategoryDto,
  LinkCategoryRequestDto,
  LinkCategoryUpdateDto,
} from 'adminServices/data-contracts';
import { toast } from 'react-toastify';
import { Modal } from '@mui/material';
import { Input } from 'components/commons';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import styled, { css } from 'styled-components';

type Props = {
  // item: Array<LinkCategoryDto>;
  item: any;
  type: 'Edit' | 'Create';
  handleMainCategory: (category: string) => void;
};

const CategoryItemContainer = observer(({ item, type, handleMainCategory }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { linkStore } = useStore();
  const [id, setId] = useState<number>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [model, setModel] = useState<LinkCategoryDto>();
  const [newModel, setNewModel] = useState<LinkCategoryRequestDto>();
  const [updateModel, setUpdateModel] = useState<LinkCategoryUpdateDto>();
  const [mainEditMode, setMainEditMode] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);

  const handleEditParentCategory = () => {
    // 부모 카테고리 수정
  };

  const handleClickAddCategory = async () => {
    if (typeof newModel !== 'undefined') {
      await linkStore.createLinkCategory(newModel);
    }
    if (linkStore.createCategoryResult.status === 200) {
      toast('카테고리가 생성되었습니다.', { type: 'success' });
      setTimeout(() => {
        window.location.href = '/link/category';
      }, 500);
    } else {
      toast(linkStore.createCategoryResult.data.errorMessage, { type: 'error' });
    }
    //실패처리
  };

  const handleEditCategory = async () => {
    if (typeof updateModel !== 'undefined') {
      await linkStore.updateLinkCategoryName(editId, { linkCategoryUpdateDto: updateModel });
    }
    if (linkStore.updateCategoryNameResult.status === 200) {
      toast('카테고리 수정이 완료되었습니다.', { type: 'success' });
    }
  };

  const onlyOneCheck = (a: any) => {
    var obj = document.getElementsByName('main') as NodeListOf<HTMLInputElement>;
    for (var i = 0; i < obj.length; i++) {
      if (obj[i] != a) {
        obj[i].checked = false;
      }
    }
  };

  const handleDeleteCategory = async () => {
    if (typeof id !== 'undefined') {
      await linkStore.existLinkByLinkCategory(id);
      await linkStore.existSubCategoryByLinkCategory(id);
      if (linkStore.existLink || linkStore.existSubCategory) {
        toast('삭제가 불가합니다.', { type: 'error' });
      } else {
        await linkStore.deleteLinkCategory(id);
        if (linkStore.deleteCategoryResult.status === 200) {
          toast('삭제가 완료되었습니다.', { type: 'success' });
          setTimeout(() => {
            window.location.href = '/link';
          }, 900);
        }
      }
    }
  };

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [mainEditMode]);

  return (
    <>
      <Modal open={openModal}>
        <DeleteModalCotnainerStyle>
          <ModalTitleStyle>삭제하시겠습니까?</ModalTitleStyle>
          <div style={{ color: '#666666' }}>삭제 후에는 복원할 수 없습니다.</div>
          <div>
            <ModalBtnStyle
              backgroundColor="#F6F6F6"
              color="black"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              취소
            </ModalBtnStyle>
            <ModalBtnStyle onClick={handleDeleteCategory}>삭제</ModalBtnStyle>
          </div>
        </DeleteModalCotnainerStyle>
      </Modal>
      <CategoryInputContainerStyle>
        <Input
          style={{ ...categoryInputStyle, width: '330px' }}
          onChangeValue={(value: string) => {
            setNewModel({ ...newModel, name: value });
          }}
          placeholder="카테고리명"
        />
        <InputBtnStyle onClick={handleClickAddCategory}>추가</InputBtnStyle>
      </CategoryInputContainerStyle>
      {typeof item !== 'undefined' && (
        <>
          {item?.map((item: any) => (
            <InputContainerStyle>
              {type === 'Edit' ? (
                <>
                  {item?.id === editId ? (
                    <>
                      <BtnStyle
                        onClick={() => {
                          setMainEditMode(false);
                          setEditId(0);
                          handleEditCategory();
                        }}
                      >
                        완료
                      </BtnStyle>
                    </>
                  ) : (
                    <>
                      <InputMainCategoryStyle
                        id={item?.name + 'more'}
                        onClick={(e) => {
                          onlyOneCheck(e.target);
                        }}
                      />
                    </>
                  )}
                  <LabelMainStyle
                    htmlFor={item?.name + 'more'}
                    onClick={() => {
                      //
                    }}
                  >
                    <div>
                      <div
                        onClick={() => {
                          setMainEditMode(true);
                          setEditId(item?.id);
                        }}
                      >
                        이름 수정
                      </div>
                      <div
                        onClick={() => {
                          setMainEditMode(true);
                          setEditId(item?.id);
                        }}
                      >
                        이미지 등록/수정
                      </div>
                      <div
                        onClick={() => {
                          setOpenModal(true);
                          setId(item?.id);
                        }}
                      >
                        카테고리 삭제
                      </div>
                    </div>
                  </LabelMainStyle>
                </>
              ) : (
                <img src="/images/categoryArrow.svg" />
              )}
              <Category1Style
                id={item?.name}
                onClick={() => {
                  setMainEditMode(false);
                }}
              />
              <CategoryLabelStyle
                editMode={item?.id === editId}
                htmlFor={item?.name}
                onClick={() => {
                  handleMainCategory(item?.name);
                  setMainEditMode(false);
                }}
              >
                {mainEditMode && item?.id === editId ? (
                  <>
                    <EditInputStyle
                      ref={inputRef}
                      defaultValue={item?.name}
                      onChange={(e: any) => {
                        setUpdateModel({ name: e.target.value });
                      }}
                      onClick={() => {
                        //
                      }}
                    />
                  </>
                ) : (
                  <>{item?.name}</>
                )}
              </CategoryLabelStyle>
            </InputContainerStyle>
          ))}
        </>
      )}
    </>
  );
});

const categoryInputStyle: CSSProperties = {
  height: 30,
  fontSize: 14,
  background: 'white',
  border: '1px solid #eeeeee',
  borderRadius: 30,
  margin: '5px 20px 0 0',
};

const InputContainerStyle = styled.div`
  display: flex;
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
`;

const EditInputStyle = styled.input`
  border: none;
  font-size: 16px;
`;

const CategoryBtnStyle = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  &:checked + label {
    > img {
      filter: unset;
    }
    > div > input {
      filter: unset;
    }
  }
`;

const CategoryLabelStyle = styled.label<{ editMode?: boolean }>`
  cursor: pointer;
  background-color: #f6f6f6;
  color: #666666;
  padding: 10px 10px 10px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 20px;
  margin: 5px 0;
  > img {
    filter: invert(49%) sepia(1%) saturate(1426%) hue-rotate(29deg) brightness(95%) contrast(83%);
  }
  ${(props) =>
    props.editMode &&
    css`
      background: white;
      border: 1px solid #eeeeee;
    `}
`;

const Category1Style = styled(CategoryBtnStyle).attrs({ name: 'filter1' })``;

const inputStyle = css`
  width: 15px;
  height: 20px;
  background-size: 25px;
  background-position: center;
  background-image: url('/images/more-vertical.svg');
  background-repeat: no-repeat;
  appearance: none;
  cursor: pointer;
  filter: invert(49%) sepia(1%) saturate(1426%) hue-rotate(29deg) brightness(95%) contrast(83%);
  & + label {
    display: none;
  }
  &:checked + label {
    display: block;
  }
`;

const InputMainCategoryStyle = styled.input.attrs({ type: 'checkbox', name: 'main' })`
  ${inputStyle}
  position: relative;
  right: 40px;
`;

const LabelStyle = styled.label`
  position: relative;
  margin-top: 25px;
  border-radius: 15px;
  z-index: 100;
  color: black;
  > div:first-child {
    border-bottom: 1px solid #dadada;
  }
`;

const LabelMainStyle = styled(LabelStyle)`
  position: absolute;
  > div {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background: white;
    position: relative;
    right: -120px;
    top: 60px;
    border-radius: 15px;
    padding: 0 10px;
    > div {
      font-size: 14px;
      padding: 10px 15px;
      cursor: pointer;
      text-align: center;
      border-bottom: 1px solid #dadada;
    }
  }
`;

const BtnStyle = styled.div`
  cursor: pointer;
  background-color: #144da8;
  color: white;
  padding: 10px;
  border-radius: 20px;
  width: 50px;
  text-align: center;
`;

const CategoryInputContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  height: 50px;
  margin: 0 auto 10px auto;
`;

const InputBtnStyle = styled.div`
  cursor: pointer;
  background-color: #144da8;
  padding: 5px 10px;
  color: white;
  height: 20px;
  border-radius: 20px;
  width: 50px;
  text-align: center;
  position: relative;
  top: -39px;
  right: 30px;
`;

const DeleteModalCotnainerStyle = styled.div`
  width: 300px;
  background: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25% auto;
  outline: none;
  > div:last-child {
    display: flex;
    width: 100%;
    margin-top: 30px;
    > div:first-child {
      border-bottom-left-radius: 15px;
    }
    > div:last-child {
      border-bottom-right-radius: 15px;
    }
  }
`;

const ModalTitleStyle = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 30px 0 0;
`;

const ModalBtnStyle = styled.div<{ backgroundColor?: string; color?: string }>`
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor || '#144da8'};
  color: ${(props) => props.color || 'white'};
  max-width: 50px;
  text-align: center;
  border-radius: 20px;
  padding: 10px 25px;
  margin: 20px 10px;
  width: 50%;
  border-radius: 0;
  margin: 0;
  max-width: unset;
  padding: 15px 20px;
`;

export default CategoryItemContainer;
