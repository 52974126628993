import React, { CSSProperties, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { useNavigate } from 'react-router-dom';
import { Input } from 'components/commons';
import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  id: number;
};

const DetailPage = observer(({ id }: Props) => {
  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState<number>();
  const { bannerStore } = useStore();
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalImg, setModalImg] = useState<string>('');

  const handleClickDelete = () => {
    bannerStore.deleteBanner(Number(currentId));
    window.location.href = '/banner';
  };

  const init = () => {
    if (typeof currentId !== 'undefined') {
      bannerStore.getBanner(Number(currentId));
    }
  };

  useEffect(() => {
    init();
  }, [currentId]);

  return (
    <>
      <Modal
        open={openModal}
        disableRestoreFocus={false}
        disableEnforceFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContentStyle>
          <div>
            <CloseIcon
              onClick={() => {
                setOpenModal(false);
              }}
              style={closeBtnStyle}
            />
            <ImgStyle src={modalImg} maxWidth="500px" maxHeight="500px" />
          </div>
        </ModalContentStyle>
      </Modal>
      <Modal open={detailOpen}>
        <ModalContainerStyle>
          <HeaderStyle>배너정보</HeaderStyle>
          <ContainerStyle>
            <Input
              style={{ ...inputStyle, maxWidth: 630 }}
              label="제목"
              defaultValue={bannerStore.banner?.name}
            />
            <FlexDivStyle>
              <Input
                readOnly
                style={{ ...inputStyle }}
                label="카테고리"
                defaultValue={bannerStore.banner?.bannerCategory}
              />
              <Input
                readOnly
                style={{ ...inputStyle }}
                label="작성자"
                defaultValue={bannerStore.banner?.creator?.name}
              />
            </FlexDivStyle>
            <FlexDivStyle>
              <Input
                readOnly
                style={{ ...inputStyle }}
                label="시작일"
                defaultValue={bannerStore.banner?.startDate}
              />
              <Input
                readOnly
                style={{ ...inputStyle }}
                label="종료일"
                defaultValue={bannerStore.banner?.endDate}
              />
            </FlexDivStyle>
            {typeof bannerStore.banner?.file?.publicUrl !== 'undefined' && (
              <ImgStyle
                src={bannerStore.banner?.file?.publicUrl}
                onClick={() => {
                  setOpenModal(true);
                  if (typeof bannerStore.banner?.file?.publicUrl !== 'undefined') {
                    setModalImg(bannerStore.banner?.file?.publicUrl);
                  }
                }}
              />
            )}
            <Input
              readOnly
              style={{ ...inputStyle, maxWidth: 630 }}
              label="상세정보"
              defaultValue={bannerStore.banner?.remarks}
            />
            <BtnContainerStyle>
              <BtnStyle
                onClick={() => {
                  setDetailOpen(false);
                }}
              >
                취소
              </BtnStyle>
              <BtnStyle onClick={handleClickDelete}>삭제</BtnStyle>
              <BtnStyle backgroundColor="#144da8">선택</BtnStyle>
            </BtnContainerStyle>
          </ContainerStyle>
        </ModalContainerStyle>
      </Modal>
      <TextStyle
        onClick={() => {
          setDetailOpen(true);
          setCurrentId(id);
        }}
      >
        보기
      </TextStyle>
    </>
  );
});

const inputStyle: CSSProperties = {
  height: 40,
  fontSize: 14,
  width: '100%',
  maxWidth: 300,
};

const closeBtnStyle: CSSProperties = {
  cursor: 'pointer',
  fill: '#ffffff',
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px 30px 50px;
`;

const FlexDivStyle = styled.div`
  display: flex;
`;

const BtnContainerStyle = styled(FlexDivStyle)`
  margin: 30px auto 0;
`;

const TextStyle = styled.div`
  color: #144da8;
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
`;

const BtnStyle = styled.div<{ color?: string; backgroundColor?: string; border?: boolean }>`
  cursor: pointer;
  border-radius: 25px;
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.color || 'white'};
  background-color: ${(props) => props.backgroundColor || '#4B4B4B'};
  display: flex;
  align-items: center;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.color};
    `};
  > div {
    margin-left: 10px;
    color: black;
    font-weight: 600;
  }
`;

const ImgStyle = styled.img.attrs({ alt: '' })<{ maxWidth?: string; maxHeight?: string }>`
  max-width: ${(props) => props.maxWidth || '200px'};
  max-height: ${(props) => props.maxHeight || '200px'};
  padding: 10px 0;
  cursor: pointer;
`;

const ModalContentStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
  }
`;

const ModalContainerStyle = styled.div`
  background-color: white;
  max-width: 800px;
  margin: 10% auto;
  outline: none;
`;

const HeaderStyle = styled.div`
  background-color: #144da8;
  padding: 15px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default DetailPage;
