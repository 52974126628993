import React, { CSSProperties, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { CommunityDto } from 'services/data-contracts';
import { Column, Row } from 'components/commons/layouts';
import { Button } from '@mui/material';
import { Input } from 'components/commons';
import styled from 'styled-components';
import ModelHelper from 'libs/helper/modelHelper';
import CategoryList from 'features/postManagement/CategoryList';
import { toast } from 'react-toastify';

const ModifyPage = observer(() => {
  const { communityStore } = useStore();
  const { id, pageType } = useParams();
  const navigate = useNavigate();

  const [pageMode, setPageMode] = useState<'create' | 'modify'>();
  const [model, setModel] = useState<CommunityDto>();
  const [checked, setChecked] = useState<boolean>(false);

  const modelHelper = new ModelHelper<CommunityDto>(model, communityStore);

  const handleClickEdit = async () => {
    const result = await modelHelper.update(model?.id);
    goBack(result);
  };

  const handleClickCreate = async () => {
    if (typeof model?.name !== 'undefined') {
      await communityStore.checkDuplicateCommunityName(model.name);
      if (communityStore.checkDupResult === true) {
        const result = await modelHelper.create();
        goBack(result);
      } else {
        toast('중복된 커뮤니티명입니다.', { type: 'error' });
      }
    }
  };

  const goBack = (result: any) => {
    if (result) {
      navigate('/community');
    }
  };

  const handleClickAddAdmin = () => {
    //communityStore.addCommunityAdmin();
  };

  const init = async () => {
    if (typeof id !== 'undefined') {
      await communityStore.getCommunityById(Number(id));
      await communityStore.getCommunityMembers(Number(id));
    }
    setModel(communityStore.community);
    if (typeof model?.isPrivate !== 'undefined') {
      setChecked(model.isPrivate);
    }
  };

  useEffect(() => {
    if (typeof pageType !== 'undefined') {
      if (pageType === 'modify') {
        setPageMode('modify');
      } else {
        setPageMode('create');
      }
    }
    init();

    return () => {
      communityStore.unMount();
    };
  }, [id]);

  return (
    <>
      <ContainerStyle>
        <Row style={{ justifyContent: 'space-between' }}>
          <Column containerStyle={{ flex: 1 }} title="게시판 추가">
            {typeof id !== 'undefined' && <CategoryList id={Number(id)} />}
          </Column>
          <Column
            containerStyle={{ flex: 1 }}
            childrenStyle={{ background: '#fff', height: '130px' }}
            title="관리자 추가"
          >
            <Row>
              <Column>
                <Input />
              </Column>
              <Column>
                <Button onClick={handleClickAddAdmin}>관리자 등록</Button>
              </Column>
            </Row>
          </Column>
        </Row>
        <>
          <Input
            boxStyle={nameBoxStyle}
            style={nameInputStyle}
            autoFocus
            containerStyle={{ height: 60 }}
            id="name"
            placeholder="번영회 이름"
            defaultValue={model?.name}
            onChangeValue={(value: string) => {
              setModel({ ...model, name: value });
            }}
          />
          <Input
            boxStyle={{ flex: 'none !important', marginLeft: 20 }}
            style={{ fontSize: 16 }}
            containerStyle={{ border: '1px solid #dadada', height: 60 }}
            id="description"
            placeholder="번영회 설명"
            defaultValue={model?.description}
            onChangeValue={(value: string) => {
              setModel({ ...model, description: value });
            }}
          />
          <Input
            boxStyle={checkBoxStyle}
            style={{ width: 15 }}
            labelStyle={{ marginTop: 7, order: 2 }}
            containerStyle={{ alignItems: 'flex-end' }}
            type="checkbox"
            label="비공개"
            checked={checked}
            onClick={() => {
              setChecked(!checked);
              setModel({ ...model, isPrivate: !checked });
            }}
          />
        </>
        <Row style={{ position: 'relative', bottom: -50 }}>
          {typeof id !== 'undefined' ? (
            <>
              <Button onClick={handleClickEdit}>수정</Button>
              <Button>삭제</Button>
            </>
          ) : (
            <>
              <Button onClick={handleClickCreate}>등록</Button>
              <Button
                onClick={() => {
                  navigate('/community');
                }}
              >
                취소
              </Button>
            </>
          )}
        </Row>
        <div>
          Member List
          {/* {communityStore.communityMembers?.map((member: CommunityMemberDto) => (
            <div>{member.user?.name}</div>
          ))} */}
        </div>
      </ContainerStyle>
    </>
  );
});

const nameBoxStyle: CSSProperties = { flex: 'none !important', marginLeft: 5, border: 'none' };

const nameInputStyle: CSSProperties = { fontSize: 25, paddingLeft: 15 };

const checkBoxStyle: CSSProperties = {
  flex: 'none !important',
  marginLeft: 15,
  display: 'flex',
  justifyContent: 'flex-end',
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  width: 90%;
`;

export default ModifyPage;
