import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'controls/table';
import { LinkDto } from 'adminServices/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LinkHeaders } from '../settings/definition';
import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';
import LinkModifyContainer from '../Containers/LinkModifyContainer';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';
import PaginationComponent from 'components/commons/PaginationComponent';

type QueryTypes = {
  searchText?: string;
  page?: number;
  size?: number;
  mainCategoryId?: number;
  subCategoryId?: number;
  sortBy?: string;
  direction?: string;
  from?: string;
  to?: string;
};

const ListPage = observer(() => {
  const navigate = useNavigate();
  const { linkStore } = useStore();
  const [searchParams] = useSearchParams();
  const linkHeaders: LinkHeaders = new LinkHeaders();

  const pageNo = useRef<number>(0);
  const checkRef = useRef<HTMLInputElement>(null);

  const [listItem, setListItem] = useState<Array<LinkDto> | undefined>([]);
  const [query, setQuery] = useState<QueryTypes>();
  const [filter, setFilter] = useState<number>(28);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    navigate(`/link?page=${pageNumber - 1}`);
  };

  const handleModal = (open: boolean) => {
    setOpenModal(open);
  };

  const getListItem = async (query: QueryTypes) => {
    await linkStore.getLinks(query);
    setListItem(linkStore.linkList);
  };

  const handleSearch = async () => {
    setQuery({ ...query, searchText: searchText });
    if (typeof query !== 'undefined') {
      getListItem(query);
    }
    setListItem(linkStore.linkList);
  };

  const getLinkItem = async () => {
    pageNo.current = searchParams.get('page') === null ? 0 : Number(searchParams.get('page'));
    if (Number.isNaN(pageNo.current)) {
      pageNo.current = 0;
    }
    await linkStore.getLinks({ page: pageNo.current, mainCategoryId: filter });
    await linkStore.getLinkCategories();
    setListItem(linkStore.linkList);
  };

  useEffect(() => {
    pageNo.current = 0;
    getLinkItem();
    return () => {
      setListItem(undefined);
    };
  }, [searchParams, pageNo.current]);

  useEffect(() => {
    if (typeof query !== 'undefined') {
      getListItem(query);
    }
  }, [query]);

  useEffect(() => {
    setQuery({ mainCategoryId: 28 });
    return () => {
      setListItem(undefined);
    };
  }, []);

  return (
    <>
      <Modal open={openModal}>
        <LinkModifyContainer handleModal={handleModal} type="create" />
      </Modal>
      <RowStyle>
        <HeaderTitleStyle title="링크" />
        <BtnStyle
          backgroundColor="#5d687a"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          등록하기
        </BtnStyle>
      </RowStyle>
      <ContainerStyle>
        <SectionStyle>
          <HeaderStyle>
            <div>
              <CategoryContainerStyle>
                <CategoryTextStyle>대분류</CategoryTextStyle>
                <div>
                  {linkStore.categoryList?.map((item: any) => (
                    <CheckboxStyle>
                      <CategoryStyle
                        id={item.name}
                        key={item.name}
                        readOnly
                        defaultChecked={item.id === 28}
                        onClick={() => {
                          setFilter(item.id);
                          setQuery({ ...query, mainCategoryId: item.id, subCategoryId: undefined });
                          navigate(`/link?page=0`);
                          if (checkRef.current !== null) {
                            checkRef.current.checked = true;
                          }
                        }}
                      />
                      <LabelStyle htmlFor={item.name} />
                      <span>{item.name}</span>
                    </CheckboxStyle>
                  ))}
                </div>
              </CategoryContainerStyle>
              <CategoryContainerStyle>
                <CategoryTextStyle>소분류</CategoryTextStyle>
                <div>
                  <CheckboxStyle>
                    <ChildCategoryStyle
                      id="all"
                      ref={checkRef}
                      defaultChecked={true}
                      readOnly
                      onClick={() => {
                        setQuery({ ...query, subCategoryId: undefined });
                      }}
                    />
                    <LabelStyle htmlFor="all" />
                    <span>전체</span>
                  </CheckboxStyle>
                  {linkStore?.categoryList
                    ?.find((item: any) => item.id === filter)
                    ?.subCategory?.map((item: any) => (
                      <>
                        <CheckboxStyle key={item.id}>
                          <ChildCategoryStyle
                            id={item.name}
                            key={item.name}
                            onClick={() => {
                              setQuery({ ...query, subCategoryId: item.id });
                            }}
                          />
                          <LabelStyle htmlFor={item.name} />
                          <span>{item.name}</span>
                        </CheckboxStyle>
                      </>
                    ))}
                </div>
              </CategoryContainerStyle>
            </div>
            <SearchContainerStyle>
              <SearchBarStyle
                onChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              <SearchImgStyle onClick={handleSearch} />
            </SearchContainerStyle>
          </HeaderStyle>
        </SectionStyle>
        <Table<LinkDto>
          items={listItem}
          headers={linkHeaders.getLinkHeaders()}
          tableStyle={tableStyle}
        />
        {typeof linkStore.pagination !== 'undefined' && (
          <PaginationComponent
            pagination={linkStore.pagination}
            handleClickPageNumber={handleClickPageNumber}
          />
        )}
      </ContainerStyle>
    </>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  fontSize: 14,
  marginBottom: 20,
};

const radioStyle = css`
  accent-color: #144da8;
  margin-right: 10px;
`;

const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 60px 30px;
  max-width: 1200px;
`;

const RowStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
`;

const SectionStyle = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const CategoryContainerStyle = styled.div`
  display: flex;
  padding: 10px 0;
  margin-left: 20px;
  font-size: 14px;
  > div:last-child {
    display: flex;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const CheckboxStyle = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
    font-size: 14px;
  }
  &:last-child > span {
    margin-right: 0;
  }
`;

const CategoryStyle = styled.input.attrs({ type: 'radio', name: 'filter1' })`
  ${radioStyle}
`;

const ChildCategoryStyle = styled.input.attrs({ type: 'radio', name: 'filter2' })`
  ${radioStyle}
`;

const LabelStyle = styled.label``;

const BtnStyle = styled.div<{ backgroundColor?: string }>`
  cursor: pointer;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  background-color: ${(props) => props.backgroundColor || '#144da8'};
  margin: 10px 50px;
`;

const CategoryTextStyle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  min-width: 50px;
`;

const SearchContainerStyle = styled.div`
  display: flex;
  margin-right: 15px;
`;

const SearchBarStyle = styled.input.attrs({ placeholder: '링크제목, 설명 키워드' })`
  background: #e7e7e7;
  width: 250px;
  padding-left: 20px;
  height: 35px;
  border: none;
  border-radius: 25px;
  position: relative;
  left: 10px;
  &::placeholder {
    color: #999999;
  }
`;

const SearchImgStyle = styled.img.attrs({ src: '/images/searchBtn.svg' })`
  cursor: pointer;
  width: 40px;
  z-index: 10;
`;

const HeaderStyle = styled.div`
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    > div:first-child {
      border-bottom: 1px solid #eeeeee;
    }
  }
`;

export default ListPage;
