/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IdDto, PostDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class CommunityPost<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 게시글 조회
   *
   * @tags community-post
   * @name GetPost
   * @summary 게시글 조회
   * @request GET:/v1/community-posts/{postId}
   * @secure
   */
  getPost = (postId: number, query?: { replyId?: number }, params: RequestParams = {}) =>
    this.request<PostDto, any>({
      path: `/v1/community-posts/${postId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 수정
   *
   * @tags community-post
   * @name UpdatePost
   * @summary 게시글 수정
   * @request PUT:/v1/community-posts/{postId}
   * @secure
   */
  updatePost = (postId: number, data: PostDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts/${postId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 삭제
   *
   * @tags community-post
   * @name DeletePost
   * @summary 게시글 삭제
   * @request DELETE:/v1/community-posts/{postId}
   * @secure
   */
  deletePost = (postId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/community-posts/${postId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 게시글 고정/해제
   *
   * @tags community-post
   * @name UpdatePostPin
   * @summary 게시글 고정/해제
   * @request PUT:/v1/community-posts/{postId}/pin
   * @secure
   */
  updatePostPin = (postId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/community-posts/${postId}/pin`,
      method: 'PUT',
      secure: true,
      ...params,
    });
  /**
   * @description 게시글 리스트 조회
   *
   * @tags community-post
   * @name GetPosts
   * @summary 게시글 리스트 조회
   * @request GET:/v1/community-posts
   * @secure
   */
  getPosts = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      regionCode?: string;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<PostDto[], any>({
      path: `/v1/community-posts`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 게시글 생성
   *
   * @tags community-post
   * @name CreatePost
   * @summary 게시글 생성
   * @request POST:/v1/community-posts
   * @secure
   */
  createPost = (data: PostDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/community-posts`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
