import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PostList from 'features/postManagement/PostList';

const PostListPage = observer(() => {
  const { communityStore } = useStore();
  const { communityid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof communityStore.communities !== 'undefined') {
      communityStore.getPosts(Number(communityid));
    }
  }, []);

  return (
    <>
      <ContainerStyle>
        <HeaderStyle>
          <BtnStyle
            onClick={() => {
              navigate(`/communities/${communityid}/posts/create`, { replace: true });
            }}
          >
            새글쓰기
          </BtnStyle>
        </HeaderStyle>
        <>
          <PostList />
        </>
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  margin-bottom: 20px;
`;

const BtnStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  width: 65px;
  height: 20px;
  border: 1px solid #144da8;
  border-radius: 20px;
  color: #144da8;
  cursor: pointer;
  font-weight: 600;
`;

export default PostListPage;
