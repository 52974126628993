import UserStore from './UserStore';
import AuthStore from './AuthStore';
import FileStore from './FileStore';
import FormValidationStore from './FormValidationStore';
import UIStore from './UIStore';
import CommunityStore from './CommunityStore';
import CommunityPostStore from './CommunityPostStore';
import { Item } from 'adminServices/Item';
import PropertyStore from './PropertyStore';

class RootStore {
  userStore = new UserStore();

  authStore = new AuthStore();

  fileStore = new FileStore();

  formValidationStore = new FormValidationStore();

  uiStore = new UIStore();

  communityStore = new CommunityStore();

  communityPostStore = new CommunityPostStore();
  itemStore = new Item();
  propertyStore = new PropertyStore();
}
export default RootStore;
