import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { GnbMenu, MenuProps } from '../MenuDefinitions';
import styled, { css } from 'styled-components';

type Props = {
  handleMenu: (currentMenu: string) => void;
};

const Lnb_new = observer(({ handleMenu }: Props) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [newMenu, setNewMenu] = useState<MenuProps>();

  useEffect(() => {
    const currentMenu = GnbMenu.find((item) => pathname.includes(item.navigate));
    if (typeof currentMenu !== 'undefined') {
      setNewMenu(currentMenu);
    }
  }, [pathname]);

  return (
    <>
      <ContainerStyle>
        <LogoStyle
          onClick={() => {
            navigate('/');
            handleMenu('');
          }}
        >
          <img src="/images/gnb-logo.svg" />
        </LogoStyle>
        <MenuItemStyle>
          {newMenu?.subMenu?.map((item) => (
            <>
              <InputStyle
                className="lnbMenu"
                id={item.id}
                onClick={() => {
                  navigate(`${item.navigate}`);
                }}
                readOnly
                checked={item.navigate === pathname}
              />
              <LabelStyle htmlFor={item.id}>
                <div>
                  <IconStyle src={`/images/navigationBar/${item.title}.svg`} />
                  {item.title}
                </div>
                <CircleStyle />
              </LabelStyle>
            </>
          ))}
        </MenuItemStyle>
      </ContainerStyle>
    </>
  );
});

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #144da8;
  width: 240px;
  height: 100%;
  top: 0;
  bottom: 0;
`;

const MenuItemStyle = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 30px;
`;

const IconStyle = styled.img.attrs({ alt: '' })`
  margin-right: 10px;
`;

const InputStyle = styled.input.attrs({
  type: 'radio',
  name: 'menu',
})`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:checked + label {
    opacity: 1;
    > div:last-child {
      visibility: visible;
    }
  }
`;

const LabelStyle = styled.label`
  cursor: pointer;
  margin: 0 15px;
  padding: 10px 0;
  ${flexCenter}
  color: white;
  opacity: 0.5;
  width: 90%;
  justify-content: space-between;
  > div {
    ${flexCenter}
  }
`;

const CircleStyle = styled.div`
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 50%;
  visibility: hidden;
  margin-right: 15px;
`;

const LogoStyle = styled.div`
  background-color: #fafafa;
  width: 240px;
  left: 0;
  top: 0;
  height: 77px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export default Lnb_new;
