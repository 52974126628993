/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BannerDto, BannerRollingSpeedDto, IdDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Banner<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 배너 조회
   *
   * @tags banner
   * @name GetBanner
   * @summary 배너 조회
   * @request GET:/v1/banners/{bannerId}
   * @secure
   */
  getBanner = (bannerId: number, params: RequestParams = {}) =>
    this.request<BannerDto, any>({
      path: `/v1/banners/${bannerId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description bannerCategory (COMMUNITY:번영회, MARKET:장터, PROPERTY:부동산, TALK:톡, HOME:홈)<br>bannerType (ADVERTISEMENT:광고, EVENT:이벤트, PROMOTIO:홍보, NEWS:뉴스, NOTICE:공지)<br>linkType (URL:링크, POST_ID:링크글)
   *
   * @tags banner
   * @name UpdateBanner
   * @summary 배너 수정
   * @request PUT:/v1/banners/{bannerId}
   * @secure
   */
  updateBanner = (bannerId: number, data: BannerDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/banners/${bannerId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 배너 삭제
   *
   * @tags banner
   * @name DeleteBanner
   * @summary 배너 삭제
   * @request DELETE:/v1/banners/{bannerId}
   * @secure
   */
  deleteBanner = (bannerId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/banners/${bannerId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 배너 rolling 순서 및 속도 저장
   *
   * @tags banner
   * @name UpdateBannerRolling
   * @summary 배너 rolling 순서 및 속도 저장
   * @request PUT:/v1/banners/rolling
   * @secure
   */
  updateBannerRolling = (data: BannerDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/banners/rolling`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description bannerCategory (COMMUNITY:번영회, MARKET:장터, PROPERTY:부동산, TALK:톡, HOME:홈)<br>bannerType (ADVERTISEMENT:광고, EVENT:이벤트, PROMOTIO:홍보, NEWS:뉴스, NOTICE:공지)<br>linkType (URL: 링크, POST_ID: 링크글, SCREEN: 스크린)
   *
   * @tags banner
   * @name CreateBanner
   * @summary 배너 생성
   * @request POST:/v1/banners
   * @secure
   */
  createBanner = (data: BannerDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/banners`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 배너 Rolling Speed 조회
   *
   * @tags banner
   * @name GetBannerRollingSpeed
   * @summary 배너 Rolling Speed 조회
   * @request GET:/v1/banners/rollinigSpeed
   * @secure
   */
  getBannerRollingSpeed = (params: RequestParams = {}) =>
    this.request<BannerRollingSpeedDto, any>({
      path: `/v1/banners/rollinigSpeed`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 모든 배너 리스트 조회
   *
   * @tags banner
   * @name GetAllBanners
   * @summary 모든 배너 리스트 조회
   * @request GET:/v1/banners/all-banners
   * @secure
   */
  getAllBanners = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<BannerDto[], any>({
      path: `/v1/banners/all-banners`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 진행중인 배너 조회(해당날짜, 액티브)
   *
   * @tags banner
   * @name GetActiveBanners
   * @summary 진행중인 배너 조회
   * @request GET:/v1/banners/active-banners
   * @secure
   */
  getActiveBanners = (params: RequestParams = {}) =>
    this.request<BannerDto[], any>({
      path: `/v1/banners/active-banners`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
