/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommunityBlockedUserDto,
  CommunityBoardDto,
  CommunityDto,
  CommunityHandOverDto,
  CommunityInvitationDto,
  CommunityJoinRequestDto,
  CommunityManagementDto,
  CommunityMemberDto,
  CommunityRoleDto,
  CommunityRoleGroupDto,
  IdDto,
  ValidationDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class CommunityManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 커뮤니티 프로필 수정
   *
   * @tags community-management
   * @name UpdateCommunityProfile
   * @summary 커뮤니티 프로필 수정
   * @request PUT:/v1/communities/{communityId}/profile
   * @secure
   */
  updateCommunityProfile = (communityId: number, data: CommunityDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/profile`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 초대장 연장
   *
   * @tags community-management
   * @name UpdateInvitation
   * @summary 커뮤니티 초대장 연장
   * @request PUT:/v1/communities/{communityId}/invitations/{invitationId}
   * @secure
   */
  updateInvitation = (
    communityId: number,
    invitationId: number,
    data: CommunityInvitationDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/invitations/${invitationId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 초대장 삭제
   *
   * @tags community-management
   * @name DeleteInvitation
   * @summary 커뮤니티 초대장 삭제
   * @request DELETE:/v1/communities/{communityId}/invitations/{invitationId}
   * @secure
   */
  deleteInvitation = (communityId: number, invitationId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/invitations/${invitationId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 게시판 수정
   *
   * @tags community-management
   * @name UpdateCommunityBoard
   * @summary 커뮤니티 게시판 수정
   * @request PUT:/v1/communities/{communityId}/boards/{boardId}
   * @secure
   */
  updateCommunityBoard = (
    communityId: number,
    boardId: number,
    data: CommunityBoardDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/boards/${boardId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 게시판 삭제
   *
   * @tags community-management
   * @name DeleteCommunityBoard
   * @summary 커뮤니티 게시판 삭제
   * @request DELETE:/v1/communities/{communityId}/boards/{boardId}
   * @secure
   */
  deleteCommunityBoard = (communityId: number, boardId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/boards/${boardId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 관리자 권한 변경
   *
   * @tags community-management
   * @name UpdateCommunityAdminRoles
   * @summary 커뮤니티 관리자 권한 변경
   * @request PUT:/v1/communities/{communityId}/admins/{memberId}/roles
   * @secure
   */
  updateCommunityAdminRoles = (
    communityId: number,
    memberId: number,
    data: CommunityMemberDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/admins/${memberId}/roles`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 관리자 역할 변경
   *
   * @tags community-management
   * @name UpdateCommunityAdminPosition
   * @summary 커뮤니티 관리자 역할 변경
   * @request PUT:/v1/communities/{communityId}/admins/{memberId}/position
   * @secure
   */
  updateCommunityAdminPosition = (
    communityId: number,
    memberId: number,
    data: CommunityMemberDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/admins/${memberId}/position`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 공개 여부 설정
   *
   * @tags community-management
   * @name UpdateCommunityPrivateMode
   * @summary 커뮤니티 공개 여부 설정
   * @request POST:/v1/communities/{communityId}/private-mode
   * @secure
   */
  updateCommunityPrivateMode = (
    communityId: number,
    data: CommunityDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/private-mode`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 멤버 강퇴
   *
   * @tags community-management
   * @name KickCommunityMember
   * @summary 커뮤니티 멤버 강퇴
   * @request POST:/v1/communities/{communityId}/members/{memberId}/kick
   * @secure
   */
  kickCommunityMember = (communityId: number, memberId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/members/${memberId}/kick`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 멤버 추방
   *
   * @tags community-management
   * @name BanCommunityMember
   * @summary 커뮤니티 멤버 추방
   * @request POST:/v1/communities/{communityId}/members/{memberId}/ban
   * @secure
   */
  banCommunityMember = (communityId: number, memberId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/members/${memberId}/ban`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 가입 거절
   *
   * @tags community-management
   * @name RejectCommunityJoinRequest
   * @summary 커뮤니티 가입 거절
   * @request POST:/v1/communities/{communityId}/join-requests/{communityJoinRequestId}/reject
   * @secure
   */
  rejectCommunityJoinRequest = (
    communityId: number,
    communityJoinRequestId: number,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/join-requests/${communityJoinRequestId}/reject`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 가입 승인
   *
   * @tags community-management
   * @name AcceptCommunityJoin
   * @summary 커뮤니티 가입 승인
   * @request POST:/v1/communities/{communityId}/join-requests/{communityJoinRequestId}/accept
   * @secure
   */
  acceptCommunityJoin = (
    communityId: number,
    communityJoinRequestId: number,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/join-requests/${communityJoinRequestId}/accept`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 초대장 리스트 조회
   *
   * @tags community-management
   * @name GetInvitations
   * @summary 커뮤니티 초대장 리스트 조회
   * @request GET:/v1/communities/{communityId}/invitations
   * @secure
   */
  getInvitations = (
    communityId: number,
    query?: { leftDays?: number; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<CommunityInvitationDto[], any>({
      path: `/v1/communities/${communityId}/invitations`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 양도
   *
   * @tags community-management
   * @name HandOverCommunity
   * @summary 커뮤니티 양도
   * @request POST:/v1/communities/{communityId}/hand-over
   * @secure
   */
  handOverCommunity = (
    communityId: number,
    data: CommunityHandOverDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/hand-over`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 커뮤니티 게시판 추가
   *
   * @tags community-management
   * @name CreateCommunityBoard
   * @summary 커뮤니티 게시판 추가
   * @request POST:/v1/communities/{communityId}/boards
   * @secure
   */
  createCommunityBoard = (
    communityId: number,
    data: CommunityBoardDto,
    params: RequestParams = {},
  ) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/boards`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 차단 회원 차단 해제
   *
   * @tags community-management
   * @name CommunityUnblockUser
   * @summary 커뮤니티 차단 회원 차단 해제
   * @request POST:/v1/communities/{communityId}/blocked-users/{blockId}/unblock
   * @secure
   */
  communityUnblockUser = (communityId: number, blockId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/blocked-users/${blockId}/unblock`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * @description 커뮤니티 관리자 추가
   *
   * @tags community-management
   * @name AddCommunityAdmin
   * @summary 커뮤니티 관리자 추가
   * @request POST:/v1/communities/{communityId}/admins
   * @secure
   */
  addCommunityAdmin = (communityId: number, data: CommunityMemberDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/communities/${communityId}/admins`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 권한 리스트 조회
   *
   * @tags community-management
   * @name GetCommunityRoles
   * @summary 커뮤니티 권한 리스트 조회
   * @request GET:/v1/community-roles
   * @secure
   */
  getCommunityRoles = (params: RequestParams = {}) =>
    this.request<CommunityRoleDto[], any>({
      path: `/v1/community-roles`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 역할 리스트 조회
   *
   * @tags community-management
   * @name GetCommunityRoleGroups
   * @summary 커뮤니티 역할 리스트 조회
   * @request GET:/v1/community-role-groups
   * @secure
   */
  getCommunityRoleGroups = (params: RequestParams = {}) =>
    this.request<CommunityRoleGroupDto[], any>({
      path: `/v1/community-role-groups`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 관리 정보 조회
   *
   * @tags community-management
   * @name GetCommunityManagement
   * @summary 커뮤니티 관리 정보 조회
   * @request GET:/v1/communities/{communityId}/management
   * @secure
   */
  getCommunityManagement = (communityId: number, params: RequestParams = {}) =>
    this.request<CommunityManagementDto, any>({
      path: `/v1/communities/${communityId}/management`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 가입 신청 리스트 조회
   *
   * @tags community-management
   * @name GetCommunityJoinRequests
   * @summary 커뮤니티 가입 신청 리스트 조회
   * @request GET:/v1/communities/{communityId}/join-requests
   * @secure
   */
  getCommunityJoinRequests = (
    communityId: number,
    query?: {
      'user.name'?: string;
      status?: 'WAITING' | 'ACCEPTED' | 'REJECTED';
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<CommunityJoinRequestDto[], any>({
      path: `/v1/communities/${communityId}/join-requests`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 게시판 이름 중복체크
   *
   * @tags community-management
   * @name CheckDuplicateCommunityBoardName
   * @summary 커뮤니티 게시판 이름 중복체크
   * @request GET:/v1/communities/{communityId}/check-duplicate-board-name
   * @secure
   */
  checkDuplicateCommunityBoardName = (
    communityId: number,
    query: { name: string },
    params: RequestParams = {},
  ) =>
    this.request<ValidationDto, any>({
      path: `/v1/communities/${communityId}/check-duplicate-board-name`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 차단 회원 리스트 조회
   *
   * @tags community-management
   * @name GetCommunityBlockedUsers
   * @summary 커뮤니티 차단 회원 리스트 조회
   * @request GET:/v1/communities/{communityId}/blocked-users
   * @secure
   */
  getCommunityBlockedUsers = (
    communityId: number,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<CommunityBlockedUserDto[], any>({
      path: `/v1/communities/${communityId}/blocked-users`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 관리자 조회
   *
   * @tags community-management
   * @name AddCommunityAdmin1
   * @summary 커뮤니티 관리자 조회
   * @request GET:/v1/communities/{communityId}/admins/{memberId}
   * @secure
   */
  addCommunityAdmin1 = (communityId: number, memberId: number, params: RequestParams = {}) =>
    this.request<CommunityMemberDto, any>({
      path: `/v1/communities/${communityId}/admins/${memberId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 커뮤니티 관리자 해제
   *
   * @tags community-management
   * @name DeleteCommunityAdmin
   * @summary 커뮤니티 관리자 해제
   * @request DELETE:/v1/communities/{communityId}/admins/{memberId}
   * @secure
   */
  deleteCommunityAdmin = (communityId: number, memberId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/communities/${communityId}/admins/${memberId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
