import { makeAutoObservable } from 'mobx';
import { User as UserService } from 'services/User';
import { REST_API_URL } from 'stores/AppStore';

import _ from 'lodash';

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: UserService<unknown> = new UserService({
    baseURL: REST_API_URL,
  });

  userInfo: any;

  allUserList: any;

  pagination: any;

  logoutStatus: any;

  *getMyInfo() {
    const { data } = yield this.api.getMyInfo();
    this.userInfo = data;
  }

  *getUsers(query?: { page?: number }) {
    const { data, headers } = yield this.api.getUsers({
      ...query,
      size: 7,
    });
    this.allUserList = data;
    this.pagination = JSON.parse(headers['x-pagination']);
  }

  clearUserList() {
    this.allUserList = undefined;
  }

  // *logout() {
  //   const { status } = yield this.api.logout();
  //   this.logoutStatus = status;
  // }
}

export default UserStore;
