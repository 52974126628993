import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { Table } from 'controls/table';
import { QnaDto } from 'adminServices/data-contracts';
import { FaqHeaders } from '../settings/definition';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import PaginationComponent from 'components/commons/PaginationComponent';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

type qnaType = {
  type: 'QUESTION' | 'BUG';
  title: string;
};

type categoryType = {
  type: 'APP' | 'MARKET' | 'BARTER' | 'COMMUNITY' | 'TALK' | 'PROPERTY';
  title: string;
};

const filter1: Array<qnaType> = [
  { type: 'QUESTION', title: '이용문의' },
  { type: 'BUG', title: '버그' },
];

const filter2: Array<categoryType> = [
  { type: 'APP', title: '앱설정' },
  { type: 'MARKET', title: '장터' },
  { type: 'BARTER', title: '물물교환' },
  { type: 'COMMUNITY', title: '번영회' },
  { type: 'TALK', title: '톡' },
  { type: 'PROPERTY', title: '부동산' },
];

type filterDataType = {
  page?: number;
  isAnswer?: boolean;
  qnaType?: string;
  categoryType?: string;
};

const QnaListPage = observer(() => {
  const navigate = useNavigate();
  const pageNo = useRef<number>(0);
  const qnaHeaders: FaqHeaders = new FaqHeaders();
  const { qnaStore } = useStore();
  const [searchParams] = useSearchParams();
  const [filterData, setFilterData] = useState<filterDataType>();
  const [qnaType, setQnaType] = useState<Array<string>>([]);
  const [categoryType, setCategoryType] = useState<Array<string>>([]);

  const init = () => {
    pageNo.current = searchParams.get('page') === null ? 0 : Number(searchParams.get('page'));
    if (Number.isNaN(pageNo.current)) {
      pageNo.current = 0;
    }
    qnaStore.getQnas({ ...filterData, page: pageNo.current });
  };

  const handleClickNoAnswer = (e: any) => {
    navigate(`/qna?page=0`);
    if (e.target.checked === false) {
      setFilterData({ ...filterData, isAnswer: undefined });
    } else {
      setFilterData({ ...filterData, isAnswer: !e.target.checked });
    }
  };

  const handleClickPageNumber = (e: any, pageNumber: number) => {
    navigate(`/service/qna?page=${pageNumber - 1}`);
  };

  useEffect(() => {
    qnaStore.getAllQnas();
  }, []);

  useEffect(() => {
    init();
    return () => {
      qnaStore.clearQnas();
    };
  }, [searchParams, filterData]);

  useEffect(() => {
    navigate(`/service/qna?page=0`);
    setFilterData({ ...filterData, qnaType: qnaType.join(), categoryType: categoryType.join() });
  }, [qnaType, categoryType]);

  return (
    <>
      <HeaderTitleStyle title="문의글" />
      <ContainerStyle>
        <HeaderStyle>
          <BtnContainerStyle>
            <div>
              <TextStyle>1차 유형</TextStyle>
              {filter1.map((item: qnaType) => (
                <>
                  <BtnStyle
                    id="filter1"
                    name="filter1"
                    onClick={(e) => {
                      if (e.currentTarget.checked === false) {
                        const newQnaType = qnaType.filter((element) => element !== item.type);
                        setQnaType(newQnaType);
                      } else {
                        setQnaType([...qnaType, item.type]);
                      }
                    }}
                  />
                  <BtnLabelStyle>{item.title}</BtnLabelStyle>
                </>
              ))}
            </div>
            <BarStyle />
            <div>
              <TextStyle>2차 유형</TextStyle>
              {filter2.map((item: categoryType) => (
                <>
                  <BtnStyle
                    name="filter2"
                    onClick={(e) => {
                      if (e.currentTarget.checked === false) {
                        const newCategoryType = categoryType.filter(
                          (element) => element !== item.type,
                        );
                        setCategoryType(newCategoryType);
                      } else {
                        setCategoryType([...categoryType, item.type]);
                      }
                    }}
                  />
                  <BtnLabelStyle>{item.title}</BtnLabelStyle>
                </>
              ))}
            </div>
          </BtnContainerStyle>
        </HeaderStyle>
        <FlexDivStyle style={{ justifyContent: 'space-between' }}>
          <TextStyle>
            전체문의 {qnaStore.allQnaList?.length}건 / 미답변{' '}
            {qnaStore.allQnaList?.filter((list: QnaDto) => list.isAnswer === false).length}건
          </TextStyle>
          <FlexDivStyle>
            <BtnStyle
              onClick={(e) => {
                handleClickNoAnswer(e);
              }}
            />
            <BtnLabelStyle>미답변만 보기</BtnLabelStyle>
          </FlexDivStyle>
        </FlexDivStyle>
        <Table<QnaDto>
          headers={qnaHeaders.getQnaHeaders()}
          items={qnaStore.qnaList}
          tableStyle={tableStyle}
        />
        {qnaStore.pagination?.totalPages > 0 && (
          <PaginationComponent
            pagination={qnaStore.pagination}
            handleClickPageNumber={handleClickPageNumber}
          />
        )}
      </ContainerStyle>
    </>
  );
});

const tableStyle: CSSProperties = {
  backgroundColor: 'white',
  border: '1px solid #dadada',
  borderCollapse: 'collapse',
  borderRadius: 5,
  marginBottom: 20,
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

const HeaderStyle = styled.div`
  display: flex;
`;

const BtnContainerStyle = styled.div`
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
  max-width: 900px;
  > div {
    color: #868686;
    display: flex;
    align-items: center;
  }
`;

const BarStyle = styled.div`
  width: 1px;
  height: 20px;
  background-color: #dadada;
  margin: 0 10px;
`;

const BtnStyle = styled.input.attrs({ type: 'checkbox' })`
  border-color: #dadada;
  margin-left: 10px;
`;

const BtnLabelStyle = styled.label`
  margin-right: 10px;
  color: black;
`;

const TextStyle = styled.div`
  color: #868686;
  margin-right: 10px;
`;

const FlexDivStyle = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export default QnaListPage;
