import { makeObservable, flow, observable } from 'mobx';
import { Qna as QnaServices } from 'adminServices/Qna';
import { ADMIN_REST_API_URL } from 'stores/AppStore';
import { QnaDto } from 'adminServices/data-contracts';
import ServiceStoreBase from 'stores/ServiceStoreBase';

class QnaStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      qnaList: observable,
      getAllQnas: flow,
      getQnas: flow,
      getQna: flow,
      updateQna: flow,
      clearQnas: flow,
    });
  }

  api: QnaServices<unknown> = new QnaServices({
    baseURL: ADMIN_REST_API_URL,
  });

  allQnaList: any;

  qnaList: any;

  qnaDetail: QnaDto;

  updateStatus: boolean;

  pagination: any;

  *getAllQnas() {
    const { response } = yield this.api.getQnas();
    this.allQnaList = response.data;
    return super.handleByStatus(response);
  }

  *getQnas(query?: { page?: number; isAnswer?: boolean; qnaType?: any; categoryType?: any }) {
    const { response, headers } = yield this.api.getQnas({ ...query, size: 7 });
    this.qnaList = response.data;
    this.pagination = JSON.parse(headers['x-pagination']);
    return super.handleByStatus(response);
  }

  *getQna(id: number) {
    const { response } = yield this.api.getQna(id);
    this.qnaDetail = response.data;
    return super.handleByStatus(response);
  }

  *updateQna(id: number, data: QnaDto) {
    const { response } = yield this.api.updateQna(id, data);
    if (response.status === 200) {
      this.updateStatus = true;
    }
    return super.handleByStatus(response);
  }

  clearQnas() {
    this.qnaList = undefined;
  }
}

export default QnaStore;
