import { Modal } from '@mui/material';
import { COLUMN_TYPES } from 'controls/table/ColumnTypes';
import React, { CSSProperties, useState } from 'react';
import LinkModifyContainer from '../Containers/LinkModifyContainer';

const thStyle: CSSProperties = {
  border: '1px solid #ededed',
  color: '#8B8F9A',
  height: 40,
  fontWeight: 500,
  textAlign: 'center',
};

const tdStyle: CSSProperties = {
  border: '1px solid #ededed',
  padding: 10,
  textAlign: 'center',
  maxWidth: 400,
};

const ModifyModal = (props: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = (open: boolean) => {
    setOpenModal(open);
  };
  return (
    <>
      <Modal open={openModal}>
        <LinkModifyContainer id={props.item.id} handleModal={handleModal} type="modify" />
      </Modal>
      <div style={{ cursor: 'pointer' }}>
        <img
          src="/images/pencil.svg"
          alt=""
          onClick={() => {
            setOpenModal(true);
          }}
        />
      </div>
    </>
  );
};

const CategoryConvertor = (props: any) => {
  return <>{props.item?.mainCategory}</>;
};

const ChildCategoryConvertor = (props: any) => {
  return <>{props.item?.subCategory}</>;
};

class LinkHeaders {
  getLinkHeaders() {
    return [
      {
        title: '1차 유형',
        type: COLUMN_TYPES.CUSTOM,
        template: <CategoryConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '2차 유형',
        type: COLUMN_TYPES.CUSTOM,
        template: <ChildCategoryConvertor />,
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '제목',
        key: 'title',
        style: { ...thStyle, minWidth: 150 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '고정배치순서',
        key: 'seq',
        style: { ...thStyle, maxWidth: 80 },
        contentStyle: { ...tdStyle },
      },
      {
        title: '작성일',
        key: 'createDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY-MM-DD' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '작성자',
        key: 'creator',
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '수정일',
        key: 'modifyDate',
        type: COLUMN_TYPES.DATETIME,
        options: { dateFormat: 'YYYY-MM-DD' },
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },
      {
        title: '수정자',
        key: 'modifier',
        style: { ...thStyle },
        contentStyle: { ...tdStyle },
      },

      {
        title: '',
        key: 'id',
        type: COLUMN_TYPES.CUSTOM,
        template: <ModifyModal />,
        style: { ...thStyle, width: 60 },
        contentStyle: { ...tdStyle },
      },
    ];
  }
}

export { LinkHeaders };
