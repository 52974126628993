import { makeObservable, flow, observable } from 'mobx';
import { Link as LinkService } from 'adminServices/Link';
import { ADMIN_REST_API_URL } from 'stores/AppStore';
import {
  LinkCategoryRequestDto,
  LinkCategoryUpdateDto,
  LinkDto,
  LinkRequestDto,
  LinkUpdateDto,
} from 'adminServices/data-contracts';
import _ from 'lodash';

class LinkStore {
  constructor() {
    makeObservable(this, {
      linkList: observable,
      linkInfo: observable,
      categoryList: observable,
      createLinkResult: observable,
      deleteLinkResult: observable,
      updateLinkResult: observable,
      createCategoryResult: observable,
      deleteCategoryResult: observable,
      updateCategoryImgResult: observable,
      updateCategoryNameResult: observable,
      existLink: observable,
      existSubCategory: observable,
      getLinks: flow,
      getLinkById: flow,
      createLink: flow,
      deleteLink: flow,
      updateLink: flow,
      getLinkCategories: flow,
      updateLinkCategory: flow,
      createLinkCategory: flow,
      deleteLinkCategory: flow,
      updateLinkCategoryName: flow,
      existLinkByLinkCategory: flow,
      existSubCategoryByLinkCategory: flow,
    });
  }

  api: LinkService<unknown> = new LinkService({
    baseURL: ADMIN_REST_API_URL,
  });

  linkList: LinkDto[] | undefined;

  linkInfo: LinkDto;

  categoryList: any;

  createLinkResult: any;

  deleteLinkResult: any;

  updateLinkResult: any;

  createCategoryResult: any;

  deleteCategoryResult: any;

  updateCategoryImgResult: any;

  updateCategoryNameResult: any;

  existLink: any;

  existSubCategory: any;

  pagination: any;

  /** ============================================================================================== */

  *getLinks(query: {
    searchText?: string;
    page?: number;
    size?: number;
    mainCategoryId?: number;
    subCategoryId?: number;
    sortBy?: string;
    direction?: string;
    from?: string;
    to?: string;
  }) {
    const { data, headers } = yield this.api.getLinks({ ...query, size: 10 });
    this.linkList = data;
    this.pagination = JSON.parse(headers['x-pagination']);
  }

  *getLinkById(linkId: number) {
    const { data } = yield this.api.getLinkById(linkId);
    this.linkInfo = data;
  }

  *getLinkCategories() {
    const { data } = yield this.api.getLinkCategories();
    this.categoryList = data;
  }

  *createLink(data: { file?: File; linkRequestDto: LinkRequestDto }) {
    const { response } = yield this.api.createLink(data);
    this.createLinkResult = response;
  }

  *deleteLink(linkId: number) {
    const { response } = yield this.api.deleteLink(linkId);
    this.deleteLinkResult = response;
  }

  *updateLink(linkId: number, data: { file?: File; linkUpdateDto: LinkUpdateDto }) {
    const { response } = yield this.api.updateLink(linkId, data);
    this.updateLinkResult = response;
  }

  *createLinkCategory(data: LinkCategoryRequestDto) {
    const { response } = yield this.api.createLinkCategory(data);
    this.createCategoryResult = response;
  }

  *deleteLinkCategory(linkCategoryId: number) {
    const { response } = yield this.api.deleteLinkCategory(linkCategoryId);
    this.deleteCategoryResult = response;
  }

  *updateLinkCategory(linkCategoryId: number, data: { file?: File }) {
    const { response } = yield this.api.updateLinkCategory(linkCategoryId, data);
    this.updateCategoryImgResult = response;
  }

  *updateLinkCategoryName(
    linkCategoryId: number,
    data: { linkCategoryUpdateDto: LinkCategoryUpdateDto },
  ) {
    const { response } = yield this.api.updateLinkCategoryName(linkCategoryId, data);
    this.updateCategoryNameResult = response;
  }

  *existLinkByLinkCategory(linkCategoryId: number) {
    const { response } = yield this.api.existLinkByLinkCategory(linkCategoryId);
    this.existLink = response;
  }

  *existSubCategoryByLinkCategory(linkCategoryId: number) {
    const { response } = yield this.api.existSubCategoryByLinkCategory(linkCategoryId);
    this.existSubCategory = response;
  }
}

export default LinkStore;
