import { makeObservable, flow, observable, action } from 'mobx';
import { Order as OrderService } from 'services/Order';

// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from './ServiceStoreBase';

class OrderStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      getSettlements: flow,
    });
  }

  api: OrderService<unknown> = new OrderService({
    baseURL: REST_API_URL,
  });

  settlements: any;

  *getSettlements() {
    const { response } = yield this.api.getSettlements();
    this.settlements = response.data;
    return super.handleByStatus(response);
  }
}

export default OrderStore;
