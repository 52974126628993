import React, { useEffect, useState, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { CommunityBoardDto } from 'services/data-contracts';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Props = {
  item: CommunityBoardDto;
  communityId: number;
};

const CategoryEditor = observer(({ item, communityId }: Props) => {
  const { communityStore, uiStore } = useStore();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [boardData, setBoardData] = useState<CommunityBoardDto>(item);
  const [newBoardData, setNewBoardData] = useState<CommunityBoardDto>();
  const [inputValue, setInputValue] = useState<string>();

  const handleSetEditMode = () => {
    setEditMode(true);
    inputRef?.current?.focus();
  };

  const handleClickEdit = async () => {
    setEditMode(false);
    if (typeof newBoardData !== 'undefined' && typeof boardData.id !== 'undefined') {
      await communityStore.updateCommunityBoard(communityId, boardData?.id, newBoardData);
    }
  };

  const handleClickDelete = async () => {
    uiStore.confirm.show({
      title: '정말로 삭제하시겠습니까?',
      onConfirmed: async () => {
        if (typeof boardData.id !== 'undefined') {
          const result = await communityStore.deleteCommunityBoard(communityId, boardData?.id);
          if (result) {
            navigate(`/community/${communityId}/modify`);
            await communityStore.getCommunityBoards(communityId);
          }
        }
      },
    });
  };

  const init = async () => {
    setBoardData(item);
    setInputValue(boardData.name);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <ContainerStyle>
      <InputStyle
        editMode={editMode}
        ref={inputRef}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          setNewBoardData({ ...newBoardData, name: e.target.value });
        }}
      />
      <IconContainerStyle>
        <ImgStyle
          src={editMode ? '/images/edit_active.svg' : '/images/categoryEdit.svg'}
          onClick={editMode ? handleClickEdit : handleSetEditMode}
        />
        <BarStyle />
        <ImgStyle src="/images/close.svg" onClick={handleClickDelete} />
      </IconContainerStyle>
    </ContainerStyle>
  );
});

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContainerStyle = styled.div`
  ${flexCenter}
  justify-content: space-between;
  background-color: #fcfdff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  height: 40px;
  padding: 0 20px;
  margin: 5px 10px;
  min-width: 150px;
`;

const ImgStyle = styled.img.attrs({ alt: '' })`
  cursor: pointer;
`;

const BarStyle = styled.div`
  width: 1px;
  height: 20px;
  background-color: #d9d9d9;
  margin: 0 5px;
`;

const IconContainerStyle = styled.div`
  ${flexCenter}
`;

const InputStyle = styled.input.attrs({ name: 'board' })<{ editMode?: boolean }>`
  border: none;
  cursor: ${(props) => (props.editMode ? 'pointer' : 'none')};
  pointer-events: ${(props) => (props.editMode ? 'all' : 'none')};
  font-size: 14px;
`;

export default CategoryEditor;
