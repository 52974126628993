import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import CommunityList from 'features/communityManagement/containers/CommunityList';
import HeaderTitleStyle from 'components/commons/HeaderTitleStyle';

const PostManagerPage = observer(() => {
  return (
    <>
      <HeaderTitleStyle title="게시물관리" />
      <ContainerStyle>
        <CommunityList />
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 50px 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: 1200px;
`;

export default PostManagerPage;
