import { makeObservable, flow, observable, action } from 'mobx';
import { Order as OrderService } from 'services/Order';

// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from './ServiceStoreBase';
import { Property } from 'services/Property';

class PropertyStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      items: observable,
      getItems: flow,
      getProperty: flow,
    });
  }

  api: Property<unknown> = new Property({
    baseURL: REST_API_URL,
  });

  items: any;

  *getItems(query?: any) {
    const { response } = yield this.api.getProperties({ ...query });
    this.items = response.data;
    return super.handleByStatus(response);
  }

  *getProperty(propertyId: number) {
    const { response } = yield this.api.getProperty(propertyId);
    return response.data;
  }
}

export default PropertyStore;
