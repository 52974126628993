import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { observer } from 'mobx-react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

type Props = {
  type: 'month' | 'year';
  chartOption: any;
  chartData: any;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

ChartJS.defaults.set('plugins.datalabels', {
  display: true,
  color: 'black',
  anchor: 'end',
  borderWidth: 2,
});

const Chart = observer(({ chartData, chartOption }: Props) => {
  return (
    <>
      <Line data={chartData} options={chartOption} id="chart" />
    </>
  );
});

export default Chart;
