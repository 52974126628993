/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FaqCategoryDto, FaqDto, IdDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Faq<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description FAQ 상세 조회
   *
   * @tags faq
   * @name GetFaq
   * @summary FAQ 상세 조회
   * @request GET:/v1/faqs/{faqId}
   * @secure
   */
  getFaq = (faqId: number, params: RequestParams = {}) =>
    this.request<FaqDto, any>({
      path: `/v1/faqs/${faqId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description FAQ 수정
   *
   * @tags faq
   * @name UpdateFaq
   * @summary FAQ 수정
   * @request PUT:/v1/faqs/{faqId}
   * @secure
   */
  updateFaq = (faqId: number, data: FaqDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/faqs/${faqId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description FAQ 삭제
   *
   * @tags faq
   * @name DeleteFaq
   * @summary FAQ 삭제
   * @request DELETE:/v1/faqs/{faqId}
   * @secure
   */
  deleteFaq = (faqId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/faqs/${faqId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * @description FAQ 리스트 조회
   *
   * @tags faq
   * @name GetFaqs
   * @summary FAQ 리스트 조회
   * @request GET:/v1/faqs
   * @secure
   */
  getFaqs = (
    query?: {
      searchKeyword?: string;
      categoryId?: number;
      page?: number;
      size?: number;
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<FaqDto[], any>({
      path: `/v1/faqs`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description FAQ 생성
   *
   * @tags faq
   * @name CreateFaq
   * @summary FAQ 생성
   * @request POST:/v1/faqs
   * @secure
   */
  createFaq = (data: FaqDto, params: RequestParams = {}) =>
    this.request<IdDto, any>({
      path: `/v1/faqs`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description FAQ 카테고리 리스트 조회
   *
   * @tags faq
   * @name GetCategories1
   * @summary FAQ 카테고리 리스트 조회
   * @request GET:/v1/faq-categories
   * @secure
   */
  getCategories1 = (params: RequestParams = {}) =>
    this.request<FaqCategoryDto[], any>({
      path: `/v1/faq-categories`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
