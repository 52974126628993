import React, { CSSProperties, useEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaqCategoryDto, FaqDto } from 'services/data-contracts';
import { Row } from 'components/commons/layouts';
import { Input } from 'components/commons';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const ModifyPage = observer(() => {
  const { faqStore } = useStore();
  const { id } = useParams();
  const navigate = useNavigate();

  const [model, setModel] = useState<FaqDto>();
  const [showCategory, setShowCategory] = useState<boolean>(false);

  const handleClickEdit = async () => {
    if (typeof id !== 'undefined' && typeof model !== 'undefined') {
      await faqStore.updateFaq(Number(id), model);
      if (faqStore.updateStatus === 200) {
        toast('수정이 완료되었습니다.', { type: 'success' });
        navigate('/faq');
      }
    }
  };

  const handleClickCategory = (item: FaqCategoryDto) => {
    setShowCategory(false);
    setModel({ ...model, category: item });
  };

  const handleClickDelete = async () => {
    if (typeof id !== 'undefined') {
      await faqStore.deleteFaq(Number(id));
      if (faqStore.deleteStatus === 200) {
        toast('삭제가 완료되었습니다.', { type: 'success' });
        navigate('/faq');
      }
    }
  };

  const handleClickCreate = async () => {
    if (typeof model !== 'undefined') {
      const result = await faqStore.createFaq(model);
      if (result) {
        navigate('/faq');
      }
    }
  };

  const init = async () => {
    if (typeof id !== 'undefined') {
      await faqStore.getFaq(Number(id));
      await faqStore.getCategories();
      setModel(faqStore.faqDetail);
    }
  };

  useEffect(() => {
    init();
  }, [id]);

  return (
    <>
      <ContainerStyle>
        <>
          <InputContainerStyle>
            <Input
              style={inputStyle}
              autoFocus
              containerStyle={inputContainerStyle}
              id="title"
              label="제목"
              defaultValue={model?.title}
              onChangeValue={(value: string) => {
                setModel({ ...model, title: value });
              }}
            />
            <div style={{ width: 200 }}>
              <Input
                readOnly
                style={{ ...inputStyle, cursor: 'pointer' }}
                label="카테고리"
                defaultValue={model?.category?.name}
                containerStyle={inputContainerStyle}
                onClick={() => {
                  setShowCategory(!showCategory);
                }}
              />
              {showCategory && (
                <CategoryListStyle>
                  {typeof faqStore.faqCategory !== 'undefined' && (
                    <>
                      {faqStore.faqCategory?.map((item: FaqCategoryDto) => (
                        <ListItemStyle
                          onClick={() => {
                            handleClickCategory(item);
                          }}
                        >
                          {item.name}
                        </ListItemStyle>
                      ))}
                    </>
                  )}
                </CategoryListStyle>
              )}
            </div>
          </InputContainerStyle>
          <AnswerContainerStyle>
            <TitleStyle>답변</TitleStyle>
            <TextAreaStyle
              style={{ width: '86.5%' }}
              defaultValue={model?.contents}
              onChange={(e: any) => {
                setModel({ ...model, contents: e.target.value });
              }}
            />
          </AnswerContainerStyle>
        </>
        <Row style={{ position: 'absolute', top: 600, width: '90%' }}>
          {typeof id !== 'undefined' ? (
            <>
              <BtnStyle
                onClick={() => {
                  navigate('/faq');
                }}
              >
                취소
              </BtnStyle>
              <BtnStyle onClick={handleClickDelete}>삭제</BtnStyle>
              <BtnStyle backgroundColor="#144da8" onClick={handleClickEdit}>
                수정
              </BtnStyle>
            </>
          ) : (
            <>
              <BtnStyle
                onClick={() => {
                  navigate('/faq');
                }}
              >
                취소
              </BtnStyle>
              <BtnStyle backgroundColor="#144da8" onClick={handleClickCreate}>
                등록
              </BtnStyle>
            </>
          )}
        </Row>
      </ContainerStyle>
    </>
  );
});

const inputStyle: CSSProperties = {
  height: 40,
  fontSize: 14,
};

const grayBorderStyle = {
  border: '1px solid #dadada',
  borderRadius: 5,
};

const inputContainerStyle: CSSProperties = {
  ...grayBorderStyle,
  height: 'auto',
  margin: '0 10px',
};

const ContainerStyle = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  width: 90%;
`;

const AnswerContainerStyle = styled.div`
  position: absolute;
  width: 100%;
  top: 189px;
`;

const ListItemStyle = styled.div`
  &:active {
    color: #144da8;
  }
`;

const InputContainerStyle = styled.div`
  display: flex;
`;

const BtnStyle = styled.div<{ backgroundColor?: string }>`
  cursor: pointer;
  background: ${(props) => props.backgroundColor || '#4B4B4B'};
  border-radius: 25px;
  padding: 10px 30px;
  color: white;
  margin: 0 20px;
`;

const TitleStyle = styled.div`
  font-size: 14px;
  margin: 10px;
`;

const TextAreaStyle = styled.textarea`
  padding: 10px;
  height: 300px;
  margin: 0 10px;
  ${grayBorderStyle}
  resize: none;
  background-color: #fafafa;
`;

const CategoryListStyle = styled.div`
  position: relative;
  margin-top: 14px;
  left: 10px;
  top: -6px;
  width: 90%;
  background-color: #fafafa;
  z-index: 10;

  ${grayBorderStyle}
  > div {
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    border-bottom: 1px solid #dadada;
  }
  > div:last-child {
    border: none;
  }
`;

export default ModifyPage;
