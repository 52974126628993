import { makeObservable, flow, observable } from 'mobx';
import { Banner as BannerServices } from 'adminServices/Banner';
import { ADMIN_REST_API_URL } from 'stores/AppStore';
import ServiceStoreBase from 'stores/ServiceStoreBase';
import { BannerDto } from 'adminServices/data-contracts';

class BannerStore extends ServiceStoreBase {
  constructor() {
    super();
    makeObservable(this, {
      banner: observable,
      bannerRolling: observable,
      rollingSpeed: observable,
      allBanner: observable,
      activeBanner: observable,
      getBanner: flow,
      updateBanner: flow,
      deleteBanner: flow,
      updateBannerRolling: flow,
      createBanner: flow,
      getBannerRollingSpeed: flow,
      getAllBanners: flow,
      getActiveBanners: flow,
      clearBanners: flow,
    });
  }

  api: BannerServices<unknown> = new BannerServices({
    baseURL: ADMIN_REST_API_URL,
  });

  banner: any;
  bannerRolling: any;
  rollingSpeed: any;
  allBanner: any;
  pagination: any;
  activeBanner: any;

  *getBanner(bannerId: number) {
    const { data } = yield this.api.getBanner(bannerId);
    this.banner = data;
  }

  *updateBanner(bannerId: number, data: BannerDto) {
    const { response } = yield this.api.updateBanner(bannerId, data);
    return super.handleByStatus(response, { successMessage: '정상적으로 수정하였습니다.' });
  }

  *deleteBanner(bannerId: number) {
    const { response } = yield this.api.deleteBanner(bannerId);
    return super.handleByStatus(response, { successMessage: '정상적으로 삭제되었습니다.' });
  }

  *updateBannerRolling(bannerData: BannerDto) {
    const { response } = yield this.api.updateBannerRolling(bannerData);
    return super.handleByStatus(response, { successMessage: '정상적으로 수정되었습니다.' });
  }

  *createBanner(data: BannerDto) {
    const { response } = yield this.api.createBanner(data);
    return super.handleByStatus(response, { successMessage: '정상적으로 생성되었습니다.' });
  }

  *getBannerRollingSpeed() {
    const { data } = yield this.api.getBannerRollingSpeed();
    this.rollingSpeed = data.bannerRollingSpeed;
  }

  *getAllBanners(query?: { page?: number; sort?: string[] }) {
    const { data, headers } = yield this.api.getAllBanners({
      ...query,
      size: 4,
    });
    this.allBanner = data;
    this.pagination = JSON.parse(headers['x-pagination']);
  }

  *getActiveBanners() {
    const { data } = yield this.api.getActiveBanners();
    this.activeBanner = data;
  }

  clearBanners() {
    this.allBanner = undefined;
  }
}

export default BannerStore;
